import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { PFXUser } from 'src/models';
import * as CFApi from './CoreFlowRestApi';
const QUERY_PFX_NOTIFICATIONS = '/PFX_Notifications/data';

// note: the naming follows PatternFly's implementation for their notification drawer
/*
export enum PFXNotificationLevel {
  'info',
  'warning',
  'ok',
  'error'
}
*/

export interface PFXNotificationActionLink {
  href: string;
  title: string;
  external: boolean;
}

export interface PFXNotificationAction {
  links: PFXNotificationActionLink[];
}

export interface PFXNotification {
  id: number;
  seen: boolean;
  level: string; // PFXNotificationLevel;
  text: string;
  created_at: string;
  actions?: PFXNotificationAction;
  data: PFXNotificationData;
}

export interface PFXNotificationPanel {
  panelkey: string;
  panelName: string;
  notifications: PFXNotification[];
  isExpanded: boolean;
  showLoading?: boolean;
}

export enum PFX_NotificationState {
  NEW = 'NEW',
  READ = 'READ',
  DISMISSED = 'DISMISSED',
}

export interface PFXNotificationData {
  FormType: string;
  ParentId: string;

  AlertText: string;
  AlertId: string;
  AlertType: string;

  ProjectId: string;
  ProjectSlug: string;
  ProjectTitle: string;

  FormId: string;
  FormSlug: string;
  FormTitle: string;

  LastChanged: string;
  ItemLink: string;
  ItemLinkTitle: string;

  State: PFX_NotificationState;
}

/*
const p1Notifications: PFXNotification[] = [
  {
    id: 24,
    seen: false,
    level: 'error',
    text: 'Foreman Community Newsletter - January 2029',
    created_at: '2018-03-13T12:30:37.988Z',
    actions: {
      links: [
        {
          href:
            'http://theforeman.org/2018/01/foreman-community-newsletter-january-2018.html',
          title: 'Open',
          external: true
        },
        {
          href:
            'http://theforeman.org/2018/01/foreman-community-newsletter-january-2018.html',
          title: 'Open again',
          external: true
        }
      ]
    }
  }
];

const notificationData: PFXNotificationPanel[] = [
  {
    panelkey: '1',
    panelName: 'Project 1',
    notifications: p1Notifications,
    isExpanded: true
  },
  {
    panelkey: '2',
    panelName: 'Project 2',
    notifications: p2Notifications,
    isExpanded: false,
    showLoading: true
  },
  {
    panelkey: '3',
    panelName: 'Project 3',
    notifications: p3Notifications,
    isExpanded: false,
    showLoading: true
  }
];
*/

const notificationsApi = {
  updateNotificationStates(
    user: PFXUser,
    notification: PFXNotificationData,
    state: PFX_NotificationState
  ): Promise<CFApi.SetDataResult | null> {
    const RequestUrl = COREFLOW_API + QUERY_PFX_NOTIFICATIONS + '/States';

    // do we need to update or insert?
    const whereItem = {
      WebGuid: notification.ParentId,
      ItemId: notification.FormId,
      UserName: user.userName.toLowerCase(),
      MessageId: notification.AlertId,
    };

    const data = {
      where: Object.keys(whereItem)
        .map(
          (k) => k + "='" + (whereItem[k] as string).replace("'", "''") + "'"
        )
        .join(' AND '),
    };

    return jsonRequest(RequestUrl, null, data, 'GET')
      .then((result: CFApi.SetDataResult) => {
        if (result) {
          const reqUrl =
            RequestUrl +
            (result.Data && result.Data.length > 0
              ? '/' + (result.Data[0]._EditRowId as string)
              : '');

          const Fields = {
            ...(reqUrl.length >= RequestUrl.length
              ? {
                  // insert new
                  WebGuid: notification.ParentId,
                  ItemId: notification.FormId,
                  UserName: user.userName.toLowerCase(),
                  MessageId: notification.AlertId,
                }
              : {}),
            State: state,
          };

          return jsonRequest(reqUrl, null, {
            Parent: 'Dummy', // just to please endpoint, value doesn't matter
            Fields,
          })
            .then((result2: CFApi.SetDataResult) => {
              if (result2) {
                return result2;
              } else {
                console.error(
                  'notificationsApi.updateNotificationStates ERROR 1: Blank Result',
                  RequestUrl
                );
                return null;
              }
            })
            .catch((error: Error) => {
              console.error(
                'notificationsApi.updateNotificationStates ERROR 2: Error',
                RequestUrl,
                error
              );
              return null;
            });
        } else {
          console.error(
            'notificationsApi.updateNotificationStates ERROR 3: Blank Result',
            RequestUrl
          );
          return null;
        }
      })
      .catch((error: Error) => {
        console.error(
          'notificationsApi.updateNotificationStates ERROR 4: Error',
          RequestUrl,
          error
        );
        return null;
      });
    /*    
    var data = {
      Parent: 'NewItem', // just to please endpoint, value doesn't matter

      Fields: {
        WebGuid: notification.ParentId,
        ItemId: notification.FormId,
        UserName: user.AzureUser.userName.toLowerCase(),
        MessageId: notification.AlertId,
        State: state
      }
    };

    

    return jsonRequest(RequestUrl, null, data)
      .then((result: CFApi.SetDataResult) => {
        if (result) {
          return result;
        } else {
          console.error(
            'notificationsApi.updateNotificationStates ERROR: Blank Result',
            RequestUrl
          );
          return null;
        }
      })
      .catch((error: Error) => {
        console.error(
          'notificationsApi.updateNotificationStates ERROR: Error',
          RequestUrl,
          error
        );
        return null;
      });
*/
  },

  fetchNotifications(): Promise<void | PFXNotificationPanel[]> {
    return jsonRequest(
      COREFLOW_API + QUERY_PFX_NOTIFICATIONS,
      null,
      { mode: 'simple' },
      'GET'
    )
      .then((data: PFXNotificationData[]): PFXNotificationPanel[] => {
        return data
          .filter((item) => item.ProjectId && true)
          .map((panelItem, index1) => {
            const notifications = data
              .filter(
                (item) => item.FormSlug && item.ParentId === panelItem.ProjectId
              )
              .map((notificationItem, index2) => {
                const numDate = Number(notificationItem.LastChanged);
                const theDate = PFXHelpers.ticksToDate(numDate).toISOString();
                const notification = {
                  data: notificationItem,
                  id: index2,
                  seen: notificationItem.State === PFX_NotificationState.READ,
                  level: notificationItem.AlertType.toLowerCase().replace(
                    'reminder',
                    'info'
                  ),
                  text: PFXHelpers.htmlDecode(notificationItem.AlertText),
                  created_at: theDate,
                  /*
                    NOTE: Notifications.onNotificationClick hijacks clicks! That issue must be resolved if actions menu is used
                    actions: {
                      links: [
                        
                        {
                          href: '',
                          title: 'Go to project alerts',
                          external: false
                        },
                        
                        {
                          href: notificationItem.ItemLink,
                          title: PFXHelpers.htmlDecode(
                            notificationItem.ItemLinkTitle
                          ),
                          external: false
                        }
                        
                        ,
                        {
                          href:
                            'http://theforeman.org/2018/01/foreman-community-newsletter-january-2018.html',
                          title: 'Go to item (new tab/window)',
                          external: true
                        }
                        
                      ]
                    }
                    */
                };
                return notification;
              });

            return {
              panelkey: index1.toString(),
              panelName: PFXHelpers.htmlDecode(panelItem.ProjectTitle),
              notifications: notifications,
              isExpanded: false,
              showLoading: false,
            };
          });
      })
      .catch((error) => {
        console.error(
          'notificationsApi.fetchNotifications ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_NOTIFICATIONS },
          error
        );
      });
  },
};

export default notificationsApi;
