import { PFXUser } from 'src/models';

export interface AuthError {
  errorCode: string;
  errorDesc: string;
}

export abstract class Auth {
  constructor(
    public tenantId: string,
    public azureAadAppid: string,
    public redirectUri: string,
    public user: PFXUser,
    public resourceId: string // resourceMap: { [resourceId: string]: string }
  ) {}

  public abstract loginPopup(selectAccount?: boolean): Promise<string>;
  public abstract loginRedirect(selectAccount?: boolean): void;
  public abstract logOut(): void;

  public abstract isSignedIn(): Boolean;
  public abstract clearCache(): void;
  public abstract getUser(activeUser?: string): PFXUser;
  public abstract isCallback(hash: string): Boolean;
  public abstract handleWindowCallback(hash?: string): void;
  public abstract acquireToken(resourceId: string): Promise<string>;
  public abstract acquireTokenRedirect(resourceId: string): void;
}
export default Auth;
