import * as React from 'react';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import { PFXUser } from 'src/models';
import { PFXWeb } from 'src/api/webApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { AppType, ToolType, IFilter } from '../CoreFlow/CoreFlow';
import * as types from '../../redux/actionConstants';
import { PFXLocalizedTerms, PFXShared } from 'src/api/localizationApi';
import { IconButton } from '@fluentui/react/lib/Button';
import { PFXHelpers } from 'src/utils/PFXHelpers';
/* import { IconButton } from '@fluentui/react/lib/Button'; */

export interface PFXCallOut {
  target: any;
  composition: string;
  filter?: IFilter;
  settings?: PFXCallOutSettings;
}

export interface PFXCallOutSettings {
  [name: string]: string;
}

interface Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  lcid: number;
  callOut?: PFXCallOut;
  filter?: IFilter;
  terms: PFXLocalizedTerms;
}

class CoreFlowCallOut extends React.PureComponent<Props> {

  dismissCallout = (ev?: Event | React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ev && ev.target && PFXHelpers.disableLightDismiss(ev.target as HTMLElement)) {
      return;
    } else {
      this.close();
    }
  }

  close = () => {
    window.store.dispatch({
      type: types.CALLOUT_CHANGE,
      payload: { callOut: null }
    });
  }

  target = () => {
    // console.debug('callout Target Fetch', this.props.callOut.target);
    return this.props.callOut.target;
  }

  render() {
    const props = this.props;
    return props.callOut ? (
      <Callout
        {...props.callOut.settings}
        className={'pfx-composition-callout' + ' pfx-' + props.callOut.composition}
        target={this.target()}
        directionalHint={DirectionalHint.topAutoEdge}
        /* directionalHintFixed */

        /* preventDismissOnLostFocus={true} */
        styles={{ root: { animation: 'none' } }}
        /* doNotLayer
        /*
            calloutMaxWidth={400}
            calloutMaxHeight={500}
            directionalHintFixed
            */
        onDismiss={this.dismissCallout}
        setInitialFocus
        hidden={!props.callOut}
      >
        {!(props.callOut?.settings?.hideClose) && <IconButton
          iconProps={{ iconName: 'ChromeClose' }}
          autoFocus={true}
          title={props.terms[PFXShared.CLOSE]}
          ariaLabel={props.terms[PFXShared.CLOSE]}
          onClick={this.close}
          styles={{
            root: {
              position: 'absolute',
              right: '0px',
              zIndex: 1000
            },
            icon: {
              fontSize: 'smaller'
            }

          }}
        />}
        <div style={props.callOut.settings.finalHeight ? { minHeight: props.callOut.settings.finalHeight } : {}}>
          <CoreFlowAppBase
            showSpinner={true}
            contextWeb={props.contextWeb}
            contextUser={props.contextUser}
            lcid={props.lcid}
            appName={AppType.Composition}
            toolName={ToolType.Composition}
            toolId={props.callOut.composition}
            filter={{ ...props.filter, ...props.callOut.filter }}
            fullPageLayout={true}
            disableUnsavedCheck={true}
          />
        </div>
      </Callout>
    ) : null;
  }
}

export default CoreFlowCallOut;
