import { MiddlewareAPI, Action } from 'redux';
import * as PFXActionTypes from './actionConstants';
import { PFXAction } from './actionTypes';

import { Array, Controls } from 'fit-ui';
import {
  PFXSlugType,
  getSlugFromPathname,
  AppInterface,
  preparePath,
  PublicLogin,
  PublicLogout,
  PFXStorage,
  appDebugEnabled,
} from 'src/Root';

import { ThunkDispatch } from 'redux-thunk';
import websApi, { PFXWeb } from 'src/api/webApi';
import PFXMenuItem, { PFXMenuItemType } from 'src/models/PFXMenuItem';
import { PFXForm, formsApi } from 'src/api/formsApi';
import navigationApi from 'src/api/navigationApi';
import systemApi from 'src/api/systemApi';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { O365Api } from 'src/api/O365Api';
import eventApi from 'src/api/eventApi';
import { RoutedAppState } from './actionReducers';
import {
  cfx_web_ns,
  AquireToken,
  AutomationMessage,
} from 'src/components/CoreFlow/CoreFlow';
import {
  updateWebs,
  updateWeb,
  updateFormAlerts,
  updateProductStatus,
  clearAlert,
  updateMenus,
  updatePage,
  updateLocalization,
} from './actionCreators';
import onCookieTokenSetEvent from 'src/components/CoreFlow/onCookieTokenSetEvent';
import onFilterChange from 'src/components/CoreFlow/onFilterChange';
import { localizationApi } from 'src/api/localizationApi';

declare var cfx_web: typeof cfx_web_ns;

function buildCurrentPath(
  path: string,
  contextWeb: PFXWeb,
  menuItems: PFXMenuItem[],
  contextForm: PFXForm
): PFXMenuItem[] {
  const currentPath: PFXMenuItem[] = [];

  if (
    menuItems &&
    path &&
    path !== PublicLogin.to &&
    path !== PublicLogout.to
  ) {
    const slugPath = path.replace(path.split('/')[1], ':slug');

    const items = menuItems
      .filter(
        (item) =>
          item.to !== PublicLogin.to && contextWeb
            ? item.scope === contextWeb.type ||
              (item.scope === 'Global' &&
                (path.startsWith(item.to) ||
                  item.itemType === PFXMenuItemType.action)) ||
              (item.scope === 'User' && path.startsWith(item.to))
            : true
        // tslint:disable-next-line:align
      ) // Fix path order issue with User and Global scope
      .sort((a: PFXMenuItem) => (a.to === '/:slug' ? -1 : 0));

    Array.Recurse(items, 'subItems', (item: PFXMenuItem) => {
      const noSlashDest = item.to.replace(/\/$/, '');

      if (
        (item.redirectPath || !item.hidden) &&
        (path.startsWith(noSlashDest) || slugPath.startsWith(noSlashDest))
      ) {
        currentPath.push(item);
      }
    });

    if (contextForm) {
      currentPath.push({
        title: contextForm.PFX_Title,
        to: '/:slug/:cat/:item', // Not used anywhere yet
        component: 'Form',
      });
    }
  }

  return currentPath;
}

function processMessage(message: AutomationMessage): void {
  // tslint:disable-next-line:no-unused-expression
  new Promise<void>((resolve) => {
    // flood protection (prvent excessive updates on batch editing)
    const currentMessage = { message, stamp: new Date().getTime() };
    const state = window.store.getState();
    const appInstance =
      message.Message && message.Message.appInstance
        ? message.Message.appInstance
        : state.appReducer.appInstance;

    if (
      appInstance === state.appReducer.appInstance &&
      (window.ProjectFlowX.lastMessage
        ? !PFXHelpers.shallowCompare(
            window.ProjectFlowX.lastMessage.message,
            currentMessage.message
          ) ||
          currentMessage.stamp - window.ProjectFlowX.lastMessage.stamp > 2500
        : true)
    ) {
      if (appDebugEnabled) {
        console.debug('processMessage active', message);
      }
      switch (message.ActionName) {
        case 'PFX_Msg_UpdatePage':
          if (message.Message && message.Message.EventWeb) {
            window.store.dispatch(updatePage(message.Message.EventWeb));
          }
          break;

        case 'PFX_Msg_UpdateWeb':
          if (message.Message && message.Message.EventWeb) {
            window.store.dispatch(updateWeb(message.Message.EventWeb));
          }
          break;

        case 'PFX_Msg_UpdateLocalization':
          if (message.Message && message.Message.EventWeb) {
            window.store.dispatch(
              updateLocalization(
                parseInt(message.Message.LCID ?? state.appReducer.lcid, 10)
              )
            );
          }
          break;

        case 'PFX_Msg_DismissActionPanel':
          if (message.Message && message.Message.EventWeb) {
            if (
              state &&
              state.appReducer &&
              state.appReducer.actionPanelItem &&
              state.appReducer.actionPanelItem.componentProps &&
              state.appReducer.actionPanelItem.componentProps.composition
            ) {
              window.ProjectFlowX.dismissActionPanel().then((closed) => {
                if (closed) {
                  if (message.Message.clearFilter) {
                    window.ProjectFlowX.clearGlobalFilter(
                      message.Message.clearFilter
                    );
                  }

                  if (
                    (message.Message.refreshPage as string)?.toLowerCase() ===
                    'true'
                  ) {
                    window.store.dispatch(updatePage(message.Message.EventWeb));
                  }

                  if (message.Message.MessageBar) {
                    const bar = message.Message.MessageBar;
                    window.ProjectFlowX.setMessage(
                      bar.message,
                      bar.LinkText,
                      bar.LinkUrl,
                      bar.Type,
                      bar.clearAfterClick
                    );
                  }
                }
              });
            }
          }
          break;

        case 'PFX_Msg_UpdateWebs':
          window.store.dispatch(updateWebs());
          break;

        case 'PFX_Msg_UpdateNotifications':
          // window.store.dispatch(updateNotifications());
          break;

        case 'PFX_Msg_ConsoleError':
          if (message.Message) {
            console.error('Server Side Error', message.Message);
          }
          break;

        case 'PFX_Msg_Navigate':
          if (message.Message) {
            if (message.Message.UpdateWeb) {
              window.store.dispatch(updateWeb(message.Message.UpdateWeb, true));
            } else {
              window.ProjectFlowX.navigate(message.Message.Path);
            }
          }
          break;

        case 'PFX_Msg_SetMessageBar':
          if (message.Message) {
            window.ProjectFlowX.setMessage(
              message.Message.message,
              message.Message.LinkText,
              message.Message.LinkUrl,
              message.Message.Type,
              message.Message.clearAfterClick
            );
          }
          break;

        case 'PFX_Msg_ConsoleDebug':
          if (message.Message) {
            console.debug('Server Side Debug Message', message.Message);
          }
          break;

        case 'PFX_Msg_Alert':
          if (message.Message) {
            Controls.Dialog.Alert(message.Message.Message);
          }
          break;

        case 'PFX_Msg_UpdateMenusGlobally':
        case 'PFX_Msg_UpdateMenus':
          window.store.dispatch(updateMenus(state.appReducer.contextWeb));
          break;

        case 'PFX_Msg_UpdateAlerts':
          /* TODO: hack to skip front page as it loads data itself, remove when refactored */
          if (state.router.location.pathname.indexOf('/Overview') === -1) {
            if (
              message.Message &&
              message.Message.WebGuid &&
              message.Message.WebGuid === state.appReducer.contextWeb.guid
            ) {
              if (message.Message.hintText) {
                window.store.dispatch(clearAlert());
                window.ProjectFlowX.setMessage(
                  '',
                  message.Message.hintText,
                  message.Message.hintLink
                );
              }

              if (message.Message.itemType === 'navigation') {
                /* Delayed dispatch, give primary load priority */
                setTimeout(() => {
                  window.store.dispatch(
                    updateFormAlerts([state.appReducer.contextWeb])
                  );

                  window.store.dispatch(
                    updateProductStatus(state.appReducer.contextWeb)
                  );
                  // tslint:disable-next-line:align
                }, 1000);
              }
            }
          }
          break;

        default:
          console.debug('processMessage', 'Unsupported action type', message);
          break;
      }

      if (message.Message) {
        if (message.Message.ConsoleLog) {
          console.log('Server Side Logging', message.Message.ConsoleLog);
        }
        if (message.Message.ConsoleDebug) {
          console.debug('Server Side Debugging', message.Message.ConsoleDebug);
        }
        if (message.Message.ConsoleWarn) {
          console.warn('Server Side Warning', message.Message.ConsoleWarn);
        }
        if (message.Message.ConsoleError) {
          console.error('Server Side Error', message.Message.ConsoleError);
        }
      }

      window.ProjectFlowX.lastMessage = currentMessage;
    } else {
      if (appDebugEnabled) {
        console.debug(
          'processMessage skipped (mismatch or flood protection active)',
          message
        );
      }
    }
    resolve();
  });
}

export const PFXMiddleWare =
  (store: MiddlewareAPI) =>
  (next: ThunkDispatch<AppInterface, any, Action>) =>
  async (action: PFXAction) => {
    switch (action.type) {
      case PFXActionTypes.LOCATION_CHANGE:
        if (appDebugEnabled) {
          console.debug(
            'App middleware.LOCATION_CHANGE - BEGIN',
            action.payload.location
          );
        }
        const prevState = (store.getState() as RoutedAppState).appReducer;

        let contextUser = prevState.contextUser;
        if (!contextUser) {
          contextUser = window.auth.getUser();
        }
        action.payload.contextUser = contextUser;

        if (contextUser.userName) {
          // Check for updates / server state
          const sysInfo = await systemApi.getInfo(prevState.contextWeb);

          action.payload.isServerOffline = !(sysInfo && true);

          if (!action.payload.isServerOffline) {
            if (prevState.isInitializing) {
              // "fire" onAppLoad
              eventApi.onAppLoad();
            }

            action.payload.isUpdateRequired = prevState.systemInfo
              ? !(
                  sysInfo.PFXVersion === prevState.systemInfo.PFXVersion &&
                  sysInfo.CFVersion === prevState.systemInfo.CFVersion
                )
              : false;

            if (action.payload.isUpdateRequired) {
              if (!JSON.parse(localStorage.getItem(PFXStorage.FORCED_RELOAD))) {
                // Reload app (and check we did not end here due to a faulty loop)
                action.payload.isForcedReload = true;
                localStorage.setItem(
                  PFXStorage.FORCED_RELOAD,
                  JSON.stringify(action.payload.isForcedReload)
                );
                console.warn('Update is required, we need to refresh');
                location.reload();
              }
            } else {
              action.payload.isForcedReload = false;
              localStorage.removeItem(PFXStorage.FORCED_RELOAD);
            }
          }

          if (action.payload.isUpdateRequired || !prevState.systemInfo) {
            action.payload.systemInfo = sysInfo;

            if (sysInfo.GlobalCSS) {
              PFXHelpers.setCustomStyleSheet(sysInfo.GlobalCSS);
            }

            if (sysInfo.GlobalJS) {
              PFXHelpers.setCustomJavascript(sysInfo.GlobalJS);
            }
          }

          // CoreFlow is
          if (cfx_web) {
            if (sysInfo) {
              let path = preparePath(action.payload.location.pathname);

              const slug = getSlugFromPathname(path, PFXSlugType.PROJECT);
              const item = getSlugFromPathname(path, PFXSlugType.ITEM);

              /*
              if (
                prevState.forceReload ||
                (prevState.terms && prevState.lcid
                  ? contextWeb.lcid !== prevState.contextWeb.lcid
                  : true)
              ) {
                PFXHelpers.setLanguage(action.payload.lcid);
                action.payload.terms = await localizationApi.fetchData(
                  contextWeb
                );
                

                action.payload.formTypes = await formsApi.fetchFormTypes(
                  contextWeb
                );
              }
              // action.payload.terms = terms;                
              */

              // Fetch form types
              /*
              let formTypes = prevState.formTypes;
              if (!formTypes) {
                formTypes = await formsApi.fetchFormTypes();
                action.payload.formTypes = formTypes;
              }
              */

              // Fetch aaset types
              let assetTypes = prevState.assetTypes;
              if (!assetTypes) {
                assetTypes = await O365Api.fetchAssetTypes();
                action.payload.assetTypes = assetTypes;
              }

              let contextWeb: PFXWeb = null;
              if (!prevState.forceReload) {
                // Skip loading of context web if we're on the same web and reuse state context web
                if (
                  prevState.contextWeb &&
                  prevState.contextWeb.slug === slug
                ) {
                  contextWeb = prevState.contextWeb;
                }

                // Is new context web in state?
                if (!contextWeb && prevState.webs) {
                  if (prevState.webs.length > 0) {
                    contextWeb = {
                      ...prevState.webs.filter((web) => web.slug === slug)[0],
                    };
                  }
                }
              }
              if (!contextWeb) {
                // we didn't find any context web above, go fetch!
                contextWeb = (await websApi.fetchWeb(slug, null)) as PFXWeb;
              }

              if (contextWeb) {
                action.payload.contextWeb = contextWeb;

                const activeLCID = sysInfo.LCID;

                // Set/Update localization terms?
                if (
                  !prevState.terms ||
                  Object.keys(prevState?.terms).length === 0 ||
                  !prevState.lcid ||
                  prevState.lcid !== activeLCID
                ) {
                  // window.store.dispatch(updateLocalization(activeLCID));

                  PFXHelpers.setLanguage(activeLCID);
                  action.payload.terms = await localizationApi.fetchData();
                  action.payload.lcid = activeLCID;

                  action.payload.formTypes =
                    await formsApi.fetchFormTypes(contextWeb);
                }

                // Update menus and loadWeb event
                if (
                  prevState.forceReload ||
                  (prevState.menuItems && prevState.contextWeb
                    ? contextWeb.guid !== prevState.contextWeb.guid ||
                      contextWeb.type !== prevState.contextWeb.type ||
                      contextWeb.archived !== prevState.contextWeb.archived
                    : true)
                ) {
                  eventApi.onWebLoad(contextWeb);
                  action.payload.menuItems =
                    (await navigationApi.fetchMenuItems(
                      contextWeb
                    )) as PFXMenuItem[];
                }

                // Update context form?
                let contextForm = prevState.contextForm;
                if (item && item !== '') {
                  if (
                    prevState.forceReload ||
                    !contextForm ||
                    (contextForm && contextForm.slug !== item)
                  ) {
                    // await next(loadContextForm(item, state.contextWeb));
                    contextForm = (
                      await formsApi.fetchFormData([contextWeb], item)
                    )[0] as PFXForm;
                  }

                  action.payload.contextForm = contextForm;
                } else {
                  action.payload.contextForm = null;
                }

                // set up CF event messaging?
                if (!window.ProjectFlowX.messageSubscription) {
                  cfx_web
                    .getInstance({
                      caasUrl: window.ProjectFlowX.coreflow.caasUrl,
                      lcid: activeLCID,
                      userName: contextUser.userName,
                      acquireToken: AquireToken,
                    })
                    .then((webPlatform) => {
                      window.ProjectFlowX.messageSubscription =
                        webPlatform.messenger(processMessage);
                    });
                }

                // set up CF cookieTokenSetEventSubscription?
                if (
                  !window.ProjectFlowX.cookieTokenSetEventSubscription &&
                  cfx_web.Public
                ) {
                  window.ProjectFlowX.cookieTokenSetEventSubscription =
                    cfx_web.Public.subscribeToCookieTokenSetEvents(
                      onCookieTokenSetEvent
                    );
                }

                // set up CF Filter Subscription?
                if (
                  !window.ProjectFlowX.filterChangeSubscription &&
                  cfx_web.Public
                ) {
                  window.ProjectFlowX.filterChangeSubscription =
                    cfx_web.Public.subscribeToFilter(onFilterChange);
                }

                // set up log mode change subscription?
                if (!window.ProjectFlowX.changeLogMode && cfx_web.Public) {
                  window.ProjectFlowX.changeLogMode =
                    cfx_web.Public.subscribeToLogMode((logMode: boolean) => {
                      window.store.dispatch({
                        type: PFXActionTypes.HELP_VISIBILITY_CHANGE,
                        payload: { logMode: logMode ?? false },
                      });
                    });
                }
              } else {
                console.error('Insufficient CoreFlow Permissions');
                action.payload.appStatus =
                  'You do not have access to this ressource. Please check permissions or license settings.';
                action.payload.isUnauthorized = true;
              }

              // Update current path
              action.payload.currentPath = buildCurrentPath(
                path,
                action.payload.contextWeb,
                action.payload.menuItems ?? prevState.menuItems,
                action.payload.contextForm
              );
            }
          } else {
            console.error('CFX Not Found!');
            action.payload.appStatus =
              'Server application could not be started!';
            action.payload.isUnauthorized = true;
          }
        } else {
          console.error('Invalid User');
          action.payload.appStatus =
            'Unknown user account. Please ensure application access.';
          action.payload.isUnauthorized = true;
        }
        action.payload.forceReload = false;

        if (appDebugEnabled) {
          console.debug(
            'App middleware.LOCATION_CHANGE - END',
            action.payload.location
          );
        }
        return next(action);

      default:
        // console.log(action);
        return next(action);
    }
  };
