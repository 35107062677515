import * as React from 'react';

import NotificationDrawerWrapper from 'patternfly-react/dist/js/components/Notification/Wrappers/NotificationDrawerWrapper';
// import NotificationDrawerToggle from 'patternfly-react/dist/js/components/Notification/NotificationDrawer/NotificationDrawerToggle';

import notificationsApi, {
  PFXNotification,
  PFXNotificationPanel,
  PFXNotificationActionLink,
  PFXNotificationData,
  PFX_NotificationState
} from '../../api/notificationsApi';

import { loadPath } from 'src/redux/actionCreators';
import { PFXLocalizedTerms, PFXNotifications } from 'src/api/localizationApi';
import { PFXUser } from 'src/models';
import { Icon } from '@fluentui/react/lib/Icon';
import { NavItem } from 'react-bootstrap';


interface Props {
  contextUser: PFXUser;
  isExpandable?: boolean;
  terms: PFXLocalizedTerms;
  onBtnClick?: (drawerOpen: boolean) => void;
  /*
  enable when mark as read is handled elsewhere
  notificationPanels: PFXNotificationPanel[];
  */
}

interface State {
  panels: PFXNotificationPanel[];
  isDrawerOpen: boolean;
  hasUnreadMessages: boolean;
  isExpanded: boolean;
  expandedPanel: string;
}

export class Notifications extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      panels: [],
      isDrawerOpen: false,
      hasUnreadMessages: false,
      isExpanded: false,
      expandedPanel: null
    };
  }

  componentDidMount() {
    notificationsApi
      .fetchNotifications()
      .then((notifications: PFXNotificationPanel[]) => {
        this.setState({ panels: notifications });
        this.updateUnreadCount();
      });
  }

  render() {
    const terms = this.props.terms;
    return /* this.state.panels.length > 0 && #9294 */(
      <>
        <NavItem key="Notifications" onClick={this.toggleDrawer}>
          <Icon
            iconName={this.state.hasUnreadMessages ? 'RingerSolid' : 'Ringer'}
            title={terms[PFXNotifications.PANELTITLE]}
            ariaLabel={terms[PFXNotifications.PANELTITLE]}
            className="nav-item-iconic btn btn-link"
            style={{
              fontSize: '16px',
              height: 'unset',
              width: 'unset'
            }}
          />
        </NavItem>
        {this.state.isDrawerOpen && this.state.panels && (
          <NotificationDrawerWrapper
            translations={{
              title: terms[PFXNotifications.PANELTITLE],
              unreadEvent: terms[PFXNotifications.UNREADNOTIFICATION],
              unreadEvents: terms[PFXNotifications.UNREADNOTIFICATIONS],
              emptyState: terms[PFXNotifications.EMPTYSTATE],
              readAll: terms[PFXNotifications.READALL],
              clearAll: terms[PFXNotifications.CLEARALL],
              deleteNotification: terms[PFXNotifications.DELETENOTIFICATION]
            }}
            panels={this.state.panels}
            isDrawerOpen
            togglePanel={this.togglePanel}
            toggleDrawerExpand={this.toggleDrawerExpand}
            isExpanded={this.state.isExpanded}
            isExpandable={false} /* doesn't work properly */
            expandedPanel={this.state.expandedPanel}
            toggleDrawerHide={this.toggleDrawer}
            onNotificationClick={this.onNotificationClick}
            onNotificationAsRead={this.onNotificationAsRead}
            onNotificationHide={this.onNotificationHide}
            onMarkPanelAsClear={this.onMarkPanelAsClear}
            onMarkPanelAsRead={this.onMarkPanelAsRead}
            onClickedLink={this.onClickLink}
          />
        )}
      </>
    );
  }

  private toggleDrawer = () => {
    this.setState(prevState => {
      return {
        isDrawerOpen: !prevState.isDrawerOpen
      };
    });
    if (this.props.onBtnClick) {
      this.props.onBtnClick(this.state.isDrawerOpen);
    }
  };

  private onClickLink = function (link: PFXNotificationActionLink) {
    // TODO: IsDirty dialog compatibility: find a way around missing preventDefault
    link.external
      ? window.open(link.href)
      : window.store.dispatch(loadPath(link.href));
  };

  private setNotificationsState = (
    notificationsToUpdate: PFXNotificationData[],
    state: PFX_NotificationState
  ) => {
    if (notificationsToUpdate) {
      notificationsToUpdate.forEach(notification =>
        notificationsApi.updateNotificationStates(
          this.props.contextUser,
          notification,
          state
        )
      );
    }
  };

  private onMarkPanelAsRead = (key: string) => {
    let notificationsToUpdate: PFXNotificationData[] = [];
    var panels = this.state.panels.map(panel => {
      if (panel.panelkey === key) {
        panel.notifications.map(notification => {
          if (!notification.seen) {
            notification.seen = true;
            notificationsToUpdate.push(notification.data);
          }
          return notification;
        });
      }
      return panel;
    });
    this.setNotificationsState(
      notificationsToUpdate,
      PFX_NotificationState.READ
    );
    this.setState({ panels: panels });
    this.updateUnreadCount();
  };

  private onMarkPanelAsClear = (key: string) => {
    let notificationsToUpdate: PFXNotificationData[] = [];
    var panels = this.state.panels.map(panel => {
      if (panel.panelkey === key) {
        panel.notifications.forEach(notification =>
          notificationsToUpdate.push(notification.data)
        );
        panel.notifications = [];
      }
      return panel;
    });
    this.setNotificationsState(
      notificationsToUpdate,
      PFX_NotificationState.DISMISSED
    );
    this.setState({ panels: panels });
    this.updateUnreadCount();
  };

  private onNotificationAsRead = (panelkey: string, nkey: number) => {
    let notificationsToUpdate: PFXNotificationData[] = [];
    var panels = this.state.panels.map(panel => {
      if (panel.panelkey === panelkey) {
        panel.notifications.map(notification => {
          if (notification.id === nkey && !notification.seen) {
            notification.seen = true;
            notificationsToUpdate.push(notification.data);
          }
          return notification;
        });
      }
      return panel;
    });

    this.setNotificationsState(
      notificationsToUpdate,
      PFX_NotificationState.READ
    );
    this.setState({ panels: panels });
    this.updateUnreadCount();
  };

  private onNotificationClick = (panelkey, nkey) => {
    // TODO: reacts to ellipses menu click too! (consider hide)

    const n: PFXNotification = this.state.panels
      .filter(panel => panel.panelkey === panelkey)[0]
      .notifications.filter(notification => notification.id === nkey)[0];
    if (n) {
      const data = n.data;
      if (data) {
        const l = data.ItemLink;
        if (l) {
          this.onNotificationAsRead(panelkey, nkey);
          window.store.dispatch(loadPath(l));
          this.setState({ isDrawerOpen: false });
        }
      }
    }
  };

  private onNotificationHide = (panelkey, nkey) => {
    var panels = this.state.panels.map(panel => {
      if (panel.panelkey === panelkey) {
        for (var i = 0; i < panel.notifications.length; i++) {
          if (nkey === panel.notifications[i].id) {
            panel.notifications.splice(i, 1);
          }
        }
      }
      return panel;
    });
    this.setState({ panels: panels });
    this.updateUnreadCount();
  };

  private togglePanel = key => {
    if (this.state.expandedPanel === key) {
      this.setState({ expandedPanel: '-1' });
    } else {
      this.setState({ expandedPanel: key });
    }
  };

  private toggleDrawerExpand = () => {
    this.setState(prevState => {
      return {
        isExpanded: !prevState.isExpanded
      };
    });
  };

  private updateUnreadCount = () => {
    var hasunread = false;
    if (this.state.panels) {
      for (var i = 0; i < this.state.panels.length; i++) {
        if (this.state.panels[i].notifications) {
          for (var j = 0; j < this.state.panels[i].notifications.length; j++) {
            if (this.state.panels[i].notifications[j].seen === false) {
              hasunread = true;
            }
          }
        }
      }
    }
    this.setState({ hasUnreadMessages: hasunread });
  };
}

export default Notifications;
