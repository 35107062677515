import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';

import PFXMenuItem from 'src/models/PFXMenuItem';
import { PFXWeb } from './webApi';
import { DataFormChangeType } from 'src/components/CoreFlow/CoreFlow';

// /automation/userevent/{eventname}/run?consumerfilter={consumerfilter}&lcid={lcid}&contextweb={contextweb}

const QUERY_PFX_EVENTS = '/automation/userevent/';

const eventApi = {
  onAppLoad(): Promise<boolean | null> {
    const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_AppLoad/run';

    return jsonRequest(query, null, { mode: 'simple' }, 'GET')
      .then((response) => {
        if (response.Error) {
          console.error('eventApi.onAppLoad ERROR', query, response);
        }
        return true;
      })
      .catch((error) => {
        console.error('eventApi.onAppLoad ERROR', { RequestUrl: query }, error);
        return null;
      });
  },

  onWebLoad(web: PFXWeb): Promise<boolean | null> {
    if (web) {
      let consumerfilters: string[] = [];
      consumerfilters.push('"web":"' + web.guid + '"');

      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_WebLoad/run';

      return jsonRequest(
        query,
        web,
        {
          mode: 'simple',
          consumerfilter,
        },
        'GET'
      )
        .then((response) => {
          if (response.Error) {
            console.error('eventApi.onWebLoad ERROR', query, response);
          }
          return true;
        })
        .catch((error) => {
          console.error('eventApi.onWebLoad ERROR', query, error);
          return null;
        });
    } else {
      return null;
    }
  },

  onMenuClick(contextWeb: PFXWeb, item: PFXMenuItem): Promise<boolean | null> {
    if (item) {
      let consumerfilters: string[] = [];
      consumerfilters.push('"item":"' + encodeURI(JSON.stringify(item)) + '"');

      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_MenuClick/run';

      return jsonRequest(
        query,
        contextWeb,
        {
          mode: 'simple',
          consumerfilter,
        },
        'GET'
      )
        .then((response) => {
          if (response.Error) {
            console.error('eventApi.onMenuClick ERROR', query, response);
          }
          return true;
        })
        .catch((error) => {
          console.error('eventApi.onMenuClick ERROR', query, error);
          return null;
        });
    } else {
      return null;
    }
  },

  onSave(
    contextWeb: PFXWeb,
    appId: string,
    appName: string,
    toolId?: string,
    editRowId?: string,
    changeType?: DataFormChangeType,
    showMessageBar?: boolean,
    parentToolId?: string
  ): Promise<boolean | null> {
    if (appId && appName) {
      let consumerfilters: string[] = [];
      consumerfilters.push('"appId":"' + appId + '"');
      consumerfilters.push('"appName":"' + appName + '"');
      if (toolId) {
        consumerfilters.push('"toolId":"' + toolId + '"');
      }
      if (parentToolId) {
        consumerfilters.push('"parentToolId":"' + parentToolId + '"');
      }
      if (editRowId) {
        consumerfilters.push('"editRowId":"' + editRowId + '"');
      }
      if (changeType !== null) {
        consumerfilters.push('"changeType":"' + changeType + '"');
      }
      if (showMessageBar) {
        consumerfilters.push('"showMessageBar":"' + showMessageBar + '"');
      }
      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_DataSave/run';

      const result = jsonRequest(
        query,
        contextWeb,
        {
          mode: 'simple',
          consumerfilter,
        },
        'GET'
      )
        .then((response) => {
          if (response.Error) {
            console.error('eventApi.onSave ERROR', query, response);
          }
          return true;
        })
        .catch((error) => {
          console.error('eventApi.onSave ERROR', query, error);
          return null;
        });

      return result;
    } else {
      return null;
    }
  },

  onCreateProject(guid: string): Promise<boolean | null> {
    if (guid) {
      let consumerfilters: string[] = [];
      consumerfilters.push('"TheProject":"' + encodeURI(guid) + '"');

      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_CreateProject/run';

      return jsonRequest(
        query,
        null,
        {
          mode: 'simple',
          consumerfilter,
        },
        'GET'
      )
        .then(() => true)
        .catch((error) => {
          console.error('eventApi.onCreateProject ERROR', query, error);
          return null;
        });
    } else {
      return null;
    }
  },

  onCreateForm(guid: string): Promise<boolean | null> {
    if (guid) {
      let consumerfilters: string[] = [];
      consumerfilters.push('"TheForm":"' + encodeURI(guid) + '"');

      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      const query = COREFLOW_API + QUERY_PFX_EVENTS + 'PFX_CreateForm/run';

      return jsonRequest(
        query,
        null,
        {
          mode: 'simple',
          consumerfilter,
        },
        'GET'
      )
        .then(() => true)
        .catch((error) => {
          console.error('eventApi.onCreateForm ERROR', query, error);
          return null;
        });
    } else {
      console.log('no guid');
      return null;
    }
  },
};

export default eventApi;
