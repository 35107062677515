import * as React from 'react';
import { Grid } from 'patternfly-react/dist/js/components/Grid';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { PFX_LOGO, PFX_BRAND } from '../../Root';
import { logIn } from '../../redux/actionCreators';
import { PFXLocalizedTerms, PFXShared } from 'src/api/localizationApi';

interface Props {
  isInitializing: boolean;
  terms: PFXLocalizedTerms;
}

export default class Login extends React.Component<Props> {
  render() {
    const terms = this.props.terms ? this.props.terms : {};
    if (!terms[PFXShared.START]) {
      // language has not been loaded yet. TODO - rely on cookie.
      terms[PFXShared.START] = 'Start';
    }

    return (
      <Grid fluid className="login">
        <Grid.Row>
          <Grid.Col xs={12} className="login-upper-section">
            <div className="login-upper-section-align-bottom">
              <img src={PFX_LOGO} className="navbar-brand-icon" />
              <br />
              <img src={PFX_BRAND} className="navbar-brand-name" />
            </div>
          </Grid.Col>
          <Grid.Col xs={12} className="login-lower-section">
            <PrimaryButton onClick={() => window.store.dispatch(logIn())}>
              {terms[PFXShared.START]}
            </PrimaryButton>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }
}
