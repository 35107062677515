import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXWeb } from './webApi';
import { PFXAssetTypes, PFXO365Asset } from './O365Api';
import * as CFApi from './CoreFlowRestApi';
import { setAlert } from 'src/redux/actionCreators';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { PFXHelpers } from 'src/utils/PFXHelpers';

export const QUERY_PFX_FORMS = '/PFX_Forms/data';

export const QUERY_PFX_FORMTYPES = '/PFX_FormTypes/data';

export interface PFXFormType2 {
  template: string;
  displayName: string;
  slug: string;
  iconClass: string;
  format: string;
}

export interface PFXFormTypes {
  [template: string]: PFXFormType2;
}

/*
export enum PFXFormType {
  PRODUCT = 'PFX_Product',
  MILESTONE = 'PFX_Milestone',
  RISK = 'PFX_Risk',
  TASK = 'PFX_Task',
  RESOURCE = 'PFX_Resource',
  ROLE = 'PFX_Role',
  O365ASSET = 'PFX_O365_Asset',
  PROJECTTYPE = 'PFX_ProjectType',
  STATUSREPORT = 'PFX_StatusReport',
  TIMEACTIVITY = 'PFX_TimeRegActivity',
  VACATION = 'PFX_SpecialDays',
}
*/
export interface PFXForm {
  FormGuid: string;
  WebGuid: string;
  FormType: string;
  _EditRowId?: string;
  AdditionalData?: PFXO365Asset;
  slug?: string;
  Path?: string;
  CreatedBy?: string;
  CreatedDate?: Date;
  ChangedBy?: string;
  ChangedDate?: Date;
  PFX_Title?: string;
  PFX_AssignedTo?: string; // User
  PFX_Customer?: string;
  PFX_Description?: string;
  PFX_DueDate?: Date;
  PFX_FollowUpDate?: Date;
  PFX_HoursEstimate?: Number;
  PFX_HoursLeft?: Number;
  PFX_Impact?: string; // Enum PFX_Impact
  PFX_Probability?: string; // Enum PFX_Probability
  PFX_Responsible?: any; // SelectEnum PFX_ProjectMembers*
  PFX_Roles?: string; // SelectEnum PFX_Roles*
  PFX_StartDate?: Date;
  PFX_ValueDescription?: string;
  PFX_O365_Id?: string;
  PFX_O365_AssetType?: PFXAssetTypes; // Enum PFX_O365_AssetType
}

export const formsApi = {
  deleteForm(form: PFXForm) {
    /* DELETES A FORM */
    // console.log('DELETE FORM NOT IMPLEMENTED!');
    // DELETE coreflowrestapi.svc/{OrgWS1 opsætning}/data/CustomTable/{entity}

    const RequestUrl =
      COREFLOW_API +
      QUERY_PFX_FORMS +
      '/' +
      form.FormType +
      '/' +
      form.FormGuid;

    return jsonRequest(RequestUrl, null, null, 'DELETE')
      .then((json: CFApi.SetDataResult) => {
        if (json) {
          if (json.Errors.length > 0) {
            console.error('Error', json.Errors);
            window.store.dispatch(
              setAlert({
                type: MessageBarType.error,
                message: 'Something went wrong. See console log for details.',
              })
            );
          } else {
            console.debug('Success', json);
            window.store.dispatch(
              setAlert({
                type: MessageBarType.success,
                message: 'Item was deleted.',
              })
            );
          }
          return json;
        } else {
          console.error('formsApi.deleteForm ERROR: Blank Result', RequestUrl);
          return null;
        }
      })
      .catch((error: Error) => {
        console.error('formsApi.deleteForm ERROR: Error', RequestUrl, error);
        return null;
      });
  },

  createForm(newForm: PFXForm): Promise<CFApi.SetDataResult | null> {
    /*
        Use Object Destructuring in conjunction with the 
        Object Rest Spread operator to construct copy of
        newForm into fieldsOnly without mentioned props.
        https://codeburst.io/use-es2015-object-rest-operator-to-omit-properties-38a3ecffe90

        Do NOT remove seemingly unused consts (they are declared so they do not end up in fieldsOnly)
    */
    const {
      FormGuid,
      WebGuid,
      FormType,
      _EditRowId,
      AdditionalData,
      ...fieldsOnly
    } = newForm;

    // Remove null props to please CF endpoints
    Object.keys(fieldsOnly).forEach(
      (key) => fieldsOnly[key] == null && delete fieldsOnly[key]
    );

    var data = {
      Parent: {
        WebId: WebGuid,
      },
      Fields: fieldsOnly,
    };

    const RequestUrl = COREFLOW_API + QUERY_PFX_FORMS + '/' + FormType;

    return jsonRequest(RequestUrl, null, data)
      .then((result: CFApi.SetDataResult) => {
        if (result) {
          return result;
        } else {
          console.error('formsApi.createForm ERROR: Blank Result', RequestUrl);
          return null;
        }
      })
      .catch((error: Error) => {
        console.error('formsApi.createForm ERROR: Error', RequestUrl, error);
        return null;
      });
  },

  fetchFormTypes(contextWeb: PFXWeb): Promise<PFXFormTypes | null> {
    const RequestUrl = COREFLOW_API + QUERY_PFX_FORMTYPES;

    return jsonRequest(RequestUrl, contextWeb, { mode: 'simple' }, 'GET')
      .then((data: PFXFormType2[]) => {
        let result: PFXFormTypes = {};
        data.map((f) => (result[f.template] = f));
        return result;
      })
      .catch((error: Error) => {
        console.error('formsApi.fetchFormType ERROR', RequestUrl, error);
        return null;
      });
  },

  fetchFormData(webs: PFXWeb[], slugOrId: string): Promise<PFXForm[] | null> {
    let consumerfilters: string[] = [];
    let consumerfilter: string = null;

    if (webs && webs.length > 0) {
      consumerfilters.push(
        '"webs":"' + encodeURI(webs.map((web) => web.guid).join(';')) + '"'
      );
    }
    if (slugOrId) {
      consumerfilters.push('"slugOrId":"' + encodeURI(slugOrId) + '"');
    }

    consumerfilter = '{' + consumerfilters.join(',') + '}';

    const RequestUrl = COREFLOW_API + QUERY_PFX_FORMS;

    return jsonRequest(
      RequestUrl,
      null,
      { mode: 'simple', consumerfilter },
      'GET'
    )
      .then((data): PFXForm[] => {
        if (data) {
          const result = data.map((form) => {
            return {
              ...form,
              PFX_Title: form.PFX_Title
                ? PFXHelpers.htmlDecode(form.PFX_Title)
                : form.AdditionalData
                ? form.AdditionalData.displayName
                : null,
              PFX_Description: PFXHelpers.htmlDecode(form.PFX_Description),
              // Convert CoreFlows unix timestamps
              PFX_StartDate: PFXHelpers.ticksToDate(form.PFX_StartDate),
              PFX_DueDate: PFXHelpers.ticksToDate(form.PFX_DueDate),
              CreatedDate: PFXHelpers.ticksToDate(form.CreatedDate),
              ChangedDate: PFXHelpers.ticksToDate(form.ChangedDate),
            };
          });
          return result;
        } else {
          return [];
        }
      })
      .catch((error: Error) => {
        console.error('formsApi.fetchFormData ERROR', RequestUrl, error);
        return null;
      });
  },
};
