import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXWeb } from './webApi';

const QUERY_PFX_O365DATA = '/PFX_Project_Alerts/data';

// https://caas-pfx.flowit.biz/_layouts/15/FlowIT/webservice/CoreFlowRestApi.svc/PFX_Project_Alerts/data?mode=simple&consumerfilter={%22web%22:%227116d451-8b4b-4a35-b5d3-1f0118504d0c%22}

export interface PFXFormAlertSummary {
  FormTypeSlug: string;
  FormType: string;
  ProjectGuid: string;
  ProjectTitle: string;
  ProjectSlug: string;
  ERROR: number;
  WARNING: number;
  REMINDER: number;
  OK: number;
  WorstAlertColor: PFXAlertColor;
  WorstAlertCount: number;
  ProgressColor: string;
  ProgressText: string;
  Hidden: boolean;
}

export enum PFXAlertColor {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  NEUTRAL = 'NEUTRAL',
}

/*
export const PFXAlertIcon = {
  [PFXAlertColor.RED]: 'pficon pficon-error-circle-o',
  [PFXAlertColor.YELLOW]: 'pficon pficon-warning-triangle-o',
  [PFXAlertColor.GREEN]: 'pficon pficon-ok',
  [PFXAlertColor.NEUTRAL]: 'pficon pficon-ok'
};
*/

export const formAlertsApi = {
  fetchData(
    webs: PFXWeb[],
    contextWeb: PFXWeb
  ): Promise<PFXFormAlertSummary[] | null> {
    let consumerfilters: string[] = [];

    if (!webs || webs.length === 0 || !webs[0]) {
      console.warn(
        'Empty webs passed to formAlertsApi.fetchData, assuming context web'
      );
      webs = [contextWeb];
    }

    const filteredWebs = webs
      .map((web) => (web ? web.guid : null))
      .filter((e) => e !== null);

    if (filteredWebs && filteredWebs.length > 0 && filteredWebs[0]) {
      consumerfilters.push(
        '"Webs":"' + encodeURI(filteredWebs.join(';')) + '"'
      );
    }
    let consumerfilter: string = null;
    consumerfilter = '{' + consumerfilters.join(',') + '}';

    return jsonRequest(
      COREFLOW_API + QUERY_PFX_O365DATA,
      contextWeb,
      { mode: 'simple', consumerfilter },
      'GET'
    )
      .then((data): PFXFormAlertSummary[] => {
        if (data) {
          return data;
        } else {
          return [];
        }
      })
      .catch((error: Error) => {
        console.error(
          'formAlertApi.fetchData ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_O365DATA },
          error
        );
        return null;
      });
  },
};
