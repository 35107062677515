import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { PFXLocalizedTerms, PFXShared } from 'src/api/localizationApi';

export interface PFXDialog {
    title: string;
    confirmBtnText: string;
    cancelBtnText: string;
    description?: string;
    onConfirmClick?: () => void;
    onCancelClick?: () => void;
    settings?: PFXDialogSettings;
}

export interface PFXDialogSettings {
    [name: string]: string;
}

interface Props {
    dialog: PFXDialog;
    terms: PFXLocalizedTerms;
}

export const SimpleDialog: React.FunctionComponent<Props> = (props) => {
    return props.dialog && (
        <Dialog
            {...props.dialog.settings}
            onDismiss={props.dialog.onCancelClick}
            hidden={!(props.dialog && true)}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialog.title,
                closeButtonAriaLabel: props.terms[PFXShared.CLOSE],
                subText: props.dialog.description,
            }}
            modalProps={{ isBlocking: true, styles: { main: { maxWidth: 300 } } }}
        >
            <DialogFooter>
                <PrimaryButton onClick={props.dialog.onConfirmClick} text={props.dialog.confirmBtnText} />
                <DefaultButton onClick={props.dialog.onCancelClick} text={props.dialog.cancelBtnText} />
            </DialogFooter>
        </Dialog>
    );
};