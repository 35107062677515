import * as React from 'react';
import { PFXWeb } from 'src/api/webApi';
import * as Layout from '../Misc/Layouts';
import {
  QueryStringModifier,
  AppType,
  ToolType,
  IFilter
} from '../CoreFlow/CoreFlow';
import { PFXForm, PFXFormTypes } from 'src/api/formsApi';
// import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { PFXUser } from 'src/models';

import { updateForm } from 'src/redux/actionCreators';

import {
  PFXLocalizedTerms,
  PFXShared,
  PFXSystem
} from 'src/api/localizationApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';

interface Props {
  contextWeb: PFXWeb;
  contextForm: PFXForm;
  contextUser: PFXUser;
  lcid: number;
  terms: PFXLocalizedTerms;
  formTypes: PFXFormTypes;
  newFormType?: string;
}

interface State {
  queryString?: QueryStringModifier;
  filterObject?: IFilter;
}

export class FormsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    let qs = {};
    /* tslint:disable:no-string-literal */
    if (props.contextForm && props.contextForm.FormGuid) {
      qs['formid'] = props.contextForm.FormGuid;
      qs['flowit:formeditmode'] = 'true';
    }

    if (props.newFormType) {
      qs['context_Forms'] = props.newFormType;
      qs['flowit:formeditmode'] = 'true';
    }
    /* tslint:enable:no-string-literal */

    if (props.contextForm && props.contextForm.FormGuid) {
      this.state = {
        queryString: qs,
        filterObject: { ContextForm: props.contextForm.FormGuid }
      };
    } else {
      this.state = {
        queryString: qs
      };
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.contextForm &&
      this.props.contextForm.FormGuid &&
      this.props.contextForm !== prevProps.contextForm
    ) {
      const qs = { formid: this.props.contextForm.FormGuid };
      qs['flowit:formeditmode'] = 'true';
      this.setState({
        queryString: qs,
        filterObject: { ContextForm: this.props.contextForm.FormGuid }
      });
    }
  }

  onChange() {
    window.store.dispatch(updateForm(this.props.contextForm));
  }

  /*
  shouldComponentUpdate(nextProps: Props): boolean {
    let update = false;
    // Never Delete this lovely timesaver!
    // debug: compare props to figure out what caused change
    for (const key in nextProps) {
      if (nextProps[key] !== this.props[key]) {
        update = true;
        console.debug('prop changed: ' + key);
        console.debug(' - old:', this.props[key]);
        console.debug(' - new:', nextProps[key]);
      }
    }
    return update;
  }
  */

  render() {
    const {
      contextWeb,
      contextForm,
      contextUser,
      lcid,
      terms,
      newFormType
    } = this.props;

    document.body.classList.add('cards-pf');
    let title = '';
    let formType = '';

    if (newFormType) {
      title = terms[PFXShared.NEW] + ' ' + terms[PFXSystem.PFX_STATUSREPORT];
      formType = newFormType;
    } else if (contextForm) {
      formType = contextForm.FormType;
      title = contextForm.PFX_Title;
      // Some types have no title - make sure we show something useful
      title =
        title === 'undefined'
          ? terms[PFXSystem.PFX_SYSTEM + '::' + formType]
          : title;
    }

    return (
      <Layout.OneCard>
        {!(this.state && this.state.queryString) && <Spinner />}
        {(contextForm || newFormType) && (
          <>
            {/*
            <div className="ms-ListBasicExample-itemCell">
              <Icon
                className={this.props.formTypes[formType].iconClass}
                style={{ fontSize: '3em' }}
              />

              <div
                className="ms-ListBasicExample-itemContent"
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div className="ms-ListBasicExample-itemName">{title}</div>
              </div>
            </div>
          */}
            {this.state && this.state.queryString && (
              <CoreFlowAppBase
                appName={AppType.Composition}
                toolName={ToolType.Composition}
                lcid={lcid}
                toolId={'PFX_FormView'}
                filter={this.state.filterObject}
                contextWeb={contextWeb}
                contextUser={contextUser}
                queryStringModifier={this.state.queryString}
                onChange={this.onChange}
                fullPageLayout={true}
              />
            )}
            {/*            
            {this.props.contextForm && (
              <div
                style={{
                  color: '#888',
                  fontStyle: 'italic',
                  fontSize: 'smaller'
                }}
              >
                {this.props.terms['PFX::Shared::Created'] +
                  ': ' +
                  this.props.contextForm.CreatedDate.toLocaleString().replace(/(.*) (\d*)\.(\d*)\.(\d*)/, '$1 $2:$3:$4')}
                <br />
                {this.props.terms['PFX::Shared::CreatedBy'] +
                  ': ' +
                  this.props.contextForm.CreatedBy}
                <br />
                <br />
                {this.props.terms['PFX::Shared::Changed'] +
                  ': ' +
                  this.props.contextForm.ChangedDate.toLocaleString().replace(/(.*) (\d*)\.(\d*)\.(\d*)/, '$1 $2:$3:$4')}{' '}
                <br />
                {this.props.terms['PFX::Shared::ChangedBy'] +
                  ': ' +
                  this.props.contextForm.ChangedBy}
                <br />
              </div>
            )}
*/}
          </>
        )}
      </Layout.OneCard>
    );
  }
}

export default FormsView;
