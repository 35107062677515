import * as React from 'react';
import Select from 'react-select';

import { Dropdown, IDropdownOption, IDropdown } from '@fluentui/react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import {
  DefaultButton,
  PrimaryButton
} from '@fluentui/react/lib/Button';

import { PFXForm, formsApi, PFXFormTypes } from 'src/api/formsApi';
import { PFXWeb } from 'src/api/webApi';
import { updateForm, setAlert, clearAlert } from 'src/redux/actionCreators';
import { PFXAssetTypes, O365Api } from 'src/api/O365Api';
import { SetDataResult } from 'src/api/CoreFlowRestApi';
import {
  PFXLocalizedTerms,
  PFXShared,
  PFXSystem,
  PFXMessages
} from 'src/api/localizationApi';
import eventApi from 'src/api/eventApi';
import { PFXSearchTerm } from '../Nav/ContextSelector';


interface Props {
  contextWeb: PFXWeb;
  formType: string;
  onCancel: () => void;
  onSave: () => void;
  terms: PFXLocalizedTerms;
  formTypes: PFXFormTypes;
  assetTypes: PFXAssetTypes;
}

interface State {
  newForm: PFXForm;
  groupOptions: any[];
  isLoading: boolean;
  created: boolean;
  creating: boolean;
  result?: SetDataResult;
  selected?: SelectOption;
}

interface SelectOption {
  label: string;
  selected: boolean;
  type: PFXAssetTypes;
  value: string;
}

class NewO365Asset extends React.Component<Props, State> {
  private typeDropDown = React.createRef<IDropdown>();

  constructor(props: Props) {
    super(props);

    this.state = {
      newForm: this.emptyForm(),
      groupOptions: [],
      isLoading: false,
      created: false,
      creating: false
    };

    this.assetTypeChange = this.assetTypeChange.bind(this);
  }

  emptyForm = (): PFXForm => {
    return {
      FormGuid: null,
      WebGuid: this.props.contextWeb.guid,
      FormType: this.props.formType,
      _EditRowId: null,
      PFX_O365_AssetType: null,
      PFX_O365_Id: ''
    };
  };

  handleSubmitAsset = (event: Event) => {
    event.preventDefault();

    this.setState({
      creating: true,
      selected: null
    });

    window.store.dispatch(
      setAlert({
        type: MessageBarType.info,
        dialogOnly: true,
        message: this.props.terms[PFXMessages.FORMBEINGCREATED].format(
          this.props.terms[
          PFXSystem.O365 + '::' + this.state.newForm.PFX_O365_AssetType
          ],
          this.state.newForm.PFX_Title
        )
      })
    );

    formsApi
      .createForm(this.state.newForm)
      .then(json => this.createOnSuccess(json))
      .catch(error => this.createOnFailure(error));
  };

  createOnSuccess = (json: SetDataResult) => {
    const terms = this.props.terms ? this.props.terms : {};
    const typeStr =
      terms[PFXSystem.O365 + '::' + this.state.newForm.PFX_O365_AssetType];

    if (json.Errors.length > 0) {
      console.error('Form Creation Error', json.Errors);

      window.store.dispatch(
        setAlert({
          type: MessageBarType.error,
          dialogOnly: true,
          message: terms[PFXMessages.FORMCREATIONERROR].format(
            typeStr,
            this.state.newForm.PFX_Title
          )
        })
      );
    } else {
      // update interface
      this.setState({
        created: true,
        result: json,
        creating: false
      });

      // update form
      window.store.dispatch(
        updateForm({
          FormGuid: json.NewItemInfo.Properties.FormId,
          FormType: this.props.formType,
          WebGuid: json.NewItemInfo.Properties.WebId
        })
      );

      // notify user about successful creation
      window.store.dispatch(
        setAlert({
          type: MessageBarType.success,
          dialogOnly: true,
          message: terms[PFXMessages.FORMCREATED].format(
            typeStr,
            this.state.newForm.PFX_Title
          )
          /* ,
          action: {
            link: json.NewItemInfo.Id, // TODO: Fix Form Link
            label: terms[PFXMessages.FORMGOTO].format(typeStr)
          }
          */
        })
      );

      // trigger "OnCreateForm" CoreFlow Event
      eventApi.onCreateForm(json.NewItemInfo.Properties.FormId);

    }
  };

  createOnFailure = (error: Error) => {
    this.setState({
      created: false,
      creating: false
    });

    window.store.dispatch(
      setAlert({
        type: MessageBarType.error,
        dialogOnly: true,
        message: error.message
      })
    );
  };

  assetTypeChange = (e: any, option: IDropdownOption) => {
    const assetType = option.key as any as PFXAssetTypes;

    const newForm = {
      ...this.state.newForm,
      PFX_O365_AssetType: assetType,
      PFX_O365_Id: null
    };

    this.setState({ isLoading: true, creating: false, created: false, selected: null });

    O365Api.fetchData([assetType], null, null, [
      { guid: newForm.WebGuid }
    ]).then(assets => {
      const filteredItems = assets.filter(asset => {
        return asset.assetType === assetType;
      });

      const groupOptions: SelectOption[] = filteredItems.map(asset => ({
        value: asset.id,
        type: asset.assetType,
        label: asset.displayName,
        selected: false
      }));

      this.setState({ newForm, groupOptions, isLoading: false });
    });

  };

  componentDidMount() {
    this.typeDropDown.current.focus();
  }

  new = () => {
    window.store.dispatch(clearAlert());
    this.setState({
      newForm: this.emptyForm(),
      created: false
    });
  };

  onCancel = () => {
    window.store.dispatch(clearAlert());
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  getCurrentPath = (): string => {
    if (this.props.contextWeb && this.state.result) {
      return (
        '/' +
        this.props.contextWeb.slug +
        '/' +
        this.props.formTypes[this.props.formType].slug +
        '/' +
        this.state.result.NewItemInfo.Properties.Title
      );
    } else {
      return null;
    }
  };

  render() {
    const { terms, formTypes, assetTypes, formType } = this.props;
    return (
      <div style={{ minHeight: 500 }}>
        <Icon
          className={formTypes[formType].iconClass}
          style={{ fontSize: '3em' }}
        />
        {this.state.newForm && assetTypes && !this.state.created && !this.state.creating && (
          <div>
            <div className="form-group">
              <Dropdown
                componentRef={this.typeDropDown}
                placeholder={terms[PFXShared.SELECT] + '...'}
                label={terms[PFXShared.TYPE]}
                id="PFX_O365_AssetType"
                ariaLabel={terms[PFXShared.TYPE]}
                defaultSelectedKey={this.state.newForm.PFX_O365_AssetType as any as string}
                options={Object.keys(assetTypes).map(key => {
                  const assetTitle = terms[
                    PFXSystem.O365 + '::' + key
                  ];
                  return { key: key, text: assetTitle, title: assetTitle };
                })}
                onChange={this.assetTypeChange}
                tabIndex={1}
              />
            </div>
            <div className="form-group">
              {(this.state.newForm.PFX_O365_AssetType || this.state.isLoading) && (
                <Select
                  value={this.state.selected}
                  placeholder={this.state.isLoading ? terms[PFXShared.LOADING] : terms[PFXShared.SELECT] + '...'}
                  id="PFX_O365_Id"
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    spacing: {
                      ...theme.spacing,
                      menuGutter: 0
                    },
                    colors: {
                      ...theme.colors,
                      primary: 'black'
                    }
                  })}
                  isLoading={this.state.isLoading || this.state.creating}
                  isClearable={false}
                  noOptionsMessage={(term: PFXSearchTerm) => this.props.terms[PFXMessages.NOASSETS].format(terms[
                    PFXSystem.O365 + '::' + (this.state.newForm.PFX_O365_AssetType as any as string)
                  ])}
                  isRtl={false}
                  isSearchable={true}
                  tabIndex={2}
                  name="PFX_O365_Id"
                  options={this.state.groupOptions}
                  onChange={(item: SelectOption) => {
                    this.setState({
                      selected: item,
                      newForm: {
                        ...this.state.newForm,
                        PFX_O365_Id: item.value,
                        PFX_Title: item.label
                      }
                    });
                  }}
                />

              )}
            </div>
            <PrimaryButton
              onClick={(e: any) => this.handleSubmitAsset(e)}
              style={{ marginRight: '8px' }}
              tabIndex={3}
              disabled={
                !(
                  this.state.newForm.PFX_O365_AssetType &&
                  this.state.newForm.PFX_O365_Id &&
                  this.state.selected
                )
              }
            >
              {terms[PFXShared.CREATE]}
            </PrimaryButton>
            <DefaultButton onClick={this.onCancel} tabIndex={4}>
              {terms[PFXShared.CANCEL]}
            </DefaultButton>
          </div>
        )}
        {!this.state.created && !this.state.newForm && assetTypes && this.state.selected && (
          <div>
            <PrimaryButton
              onClick={e => window.ProjectFlowX.navigate(this.getCurrentPath(), e as unknown as MouseEvent)}
              style={{ marginRight: '8px' }}
              componentRef={ref => (ref ? ref.focus() : null)}
              tabIndex={1}
            >
              {terms[PFXShared.OPEN]}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => this.setState({ newForm: this.emptyForm() })}
              style={{ marginRight: '8px' }}
              tabIndex={1}
            >
              {terms[PFXShared.CREATEANOTHER]}
            </PrimaryButton>
            <DefaultButton
              onClick={this.onCancel}
              componentRef={ref => (ref ? ref.focus() : null)}
              tabIndex={2}
            >
              {terms[PFXShared.CLOSE]}
            </DefaultButton>
          </div>
        )}

        {this.state.created && assetTypes && (
          <div>
            {/* Disabled for now, consider reintroducing with static asset filters / options
            <PrimaryButton
              onClick={() =>
                window.store.dispatch(
                  loadPath('/' + contextWeb.slug + '/Assets')
                )
              }
              componentRef={ref => (ref ? ref.focus() : null)}
              style={{ marginRight: '8px' }}
              tabIndex={1}
            >
              {terms[PFXShared.OPEN]}
            </PrimaryButton>
            */}
            <PrimaryButton
              onClick={e => window.ProjectFlowX.navigate(this.getCurrentPath(), e as unknown as MouseEvent)}
              style={{ marginRight: '8px' }}
              componentRef={ref => (ref ? ref.focus() : null)}
              tabIndex={1}
            >
              {terms[PFXShared.OPEN]}
            </PrimaryButton>

            <PrimaryButton
              onClick={this.new}
              style={{ marginRight: '8px' }}
              tabIndex={1}
            >
              {terms[PFXShared.NEW]}
            </PrimaryButton>

            <DefaultButton
              onClick={this.onCancel}
              tabIndex={2}
            >
              {terms[PFXShared.CLOSE]}
            </DefaultButton>
          </div>
        )}
      </div>
    );
  }
}

export default NewO365Asset;
