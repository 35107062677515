import { AppInterface, initialState } from '../Root';
import { PFXAction } from './actionTypes';
import * as types from './actionConstants';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

export interface RoutedAppState {
  router: any;
  appReducer: AppInterface;
}

export const routedAppReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    appReducer,
  });

const appReducer = (state: AppInterface, action?: PFXAction): AppInterface => {
  if (!state) {
    return initialState;
  } else {
    if (action && action.type) {
      switch (action.type) {
        case types.INITIALIZING: {
          return {
            ...state,
            isInitializing: true,
            appStatus: 'Initializing',
          };
        }

        case types.INITIALIZING_DONE:
          return {
            ...state,
            isInitializing: false,
            appStatus: 'Initialization completed.',
          };

        case types.CALLOUT_CHANGE:
          return {
            ...state,
            callOut: action.payload.callOut,
          };

        case types.DIALOG_CHANGE:
          return {
            ...state,
            dialog: action.payload.dialog,
          };

        case types.FORCE_RELOAD:
          return {
            ...state,
            forceReload: action.payload.forceReload,
          };

        case types.SET_UNAUTHORIZED:
          return {
            ...state,
            isInitializing: true,
            isUnauthorized: true,
            appStatus: 'Unauthorized Access.',
          };

        case types.SET_CONTEXTWEB:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Context Web Loading...',
          };

        case types.SET_CONTEXTFORM:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Context Form Loading...',
          };

        case types.LAYOUT_CHANGE:
          return {
            ...state,
            layoutMode: action.payload.layoutMode,
            mastheadHidden: false,
          };

        case types.SET_ALERT:
          return {
            ...state,
            alert: action.payload.alert,
          };

        case types.SET_SERVER_STATE:
          return {
            ...state,
            isServerOffline: action.payload.isServerOffline,
            isUpdateRequired: action.payload.isUpdateRequired,
            systemInfo: action.payload.systemInfo,
          };

        case types.MASTHEAD_VISIBILITY_CHANGE:
          return {
            ...state,
            mastheadHidden: action.payload.mastheadHidden,
          };

        case types.HELP_VISIBILITY_CHANGE:
          return {
            ...state,
            helpVisible: action.payload.helpVisible,
            inlinePanelItem: action.payload.helpVisible
              ? state.inlinePanelItem
              : null,
          };

        case types.ALERTS_VISIBILITY_CHANGE:
          return {
            ...state,
            alertsVisible: action.payload.alertsVisible,
          };

        case types.UNSAVED_CHANGE:
          let newAppEditState = {};
          // Store dirty stage seperately for apps
          newAppEditState[action.payload.appEditState.appId] =
            action.payload.appEditState;
          return {
            ...state,
            appEditStates: Object.assign(
              {},
              state.appEditStates,
              newAppEditState
            ),
          };

        case types.UNSAVED_CLEAR:
          return {
            ...state,
            appEditStates: {},
          };

        case types.PROCESSING_CHANGE:
          let newAppProcessingState = {};
          const appProcessingState = action.payload.appProcessingState;
          newAppProcessingState[appProcessingState.appId] = appProcessingState;
          return {
            ...state,
            appProcessingStates: Object.assign(
              {},
              state.appProcessingStates,
              newAppProcessingState
            ),
          };

        case types.PROCESSING_CLEAR:
          return {
            ...state,
            appProcessingStates: {},
          };

        case types.MENU_COLLAPSED_CHANGE:
          return {
            ...state,
            menuCollapsed: action.payload.menuCollapsed,
          };

        case types.TOPMENU_VISIBILITY_CHANGE:
          return {
            ...state,
            topMenuHidden: action.payload.topMenuHidden,
          };

        case types.SET_LOGMODE:
          return {
            ...state,
            logMode: action.payload.logMode,
          };

        case types.SET_CONTEXTUSER:
          return {
            ...state,
            contextUser: action.payload.contextUser,
          };

        case types.SET_CONTEXTWEB_DONE:
          return {
            ...state,
            contextWeb: action.payload.contextWeb,
            contextIsLoading: false,
            forceReload: false,
            appStatus: action.payload.contextWeb
              ? 'Context Web Loaded.'
              : 'ContextWeb Not Loaded',
          };

        case types.SET_CONTEXTFORM_DONE:
          return {
            ...state,
            contextForm: action.payload.contextForm,
            contextIsLoading: false,
            forceReload: false,
            appStatus: action.payload.contextForm
              ? 'Context Form Loaded.'
              : 'Context Form Not Loaded.',
          };

        // --------- WEBS ----------------------------------------------------

        case types.LOAD_WEBS:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Loading Webs...',
          };

        case types.LOAD_WEBS_DONE: {
          return {
            ...state,
            webs: action.payload.webs,
            forceReload: false,
            contextIsLoading: false,
            appStatus: 'Webs Loaded.',
          };
        }

        case types.LOAD_WEB:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Loading Web.',
          };

        case types.LOAD_WEB_DONE: {
          if (action.payload.web) {
            let updated = false;

            const newWebs = state.webs.map((stateWeb) => {
              if (stateWeb.guid === action.payload.web.guid) {
                updated = true;
                return action.payload.web;
              } else {
                return stateWeb;
              }
            });

            if (!updated) {
              newWebs.push(action.payload.web);
            }

            return {
              ...state,
              webs: newWebs,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Web Loaded.',
            };
          } else {
            return {
              ...state,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'No new web loaded.',
            };
          }
        }

        // --------- FORMS ----------------------------------------------------

        case types.LOAD_FORMS:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Loading Forms Data...',
          };

        case types.LOAD_FORMS_DONE:
          return {
            ...state,
            forms: action.payload.forms,
            contextIsLoading: false,
            forceReload: false,
            appStatus: 'Forms Data Loaded.',
            dataTick: state.dataTick + 1,
          };

        case types.UPDATE_PAGE:
          return {
            ...state,
            appStatus: state.appStatus ?? 'Page Reloaded.',
            dataTick: state.dataTick + 1,
            appEditStates: {},
          };

        case types.APPEND_ERROR:
          return {
            ...state,
            errors: state.errors + 1,
          };

        case types.CLEAR_ERRORS:
          return {
            ...state,
            errors: 0,
          };

        case types.LOAD_FORM:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_FORM_DONE: {
          if (action.payload.form && action.payload.form.FormGuid) {
            let updated = false;
            const newForms = state.forms.map((stateForm) => {
              if (stateForm.FormGuid === action.payload.form.FormGuid) {
                updated = true;
                return action.payload.form;
              } else {
                return stateForm;
              }
            });

            if (!updated) {
              newForms.push(action.payload.form);
            }
            return {
              ...state,
              forms: newForms,
              forceReload: false,
              contextForm: state.contextForm
                ? state.contextForm.FormGuid === action.payload.form.FormGuid
                  ? action.payload.form
                  : state.contextForm
                : null,
              contextIsLoading: false,
              appStatus: 'Form Updated',
              dataTick: state.dataTick + 1,
            };
          } else {
            return { ...state };
          }
        }

        case types.DELETE_FORM:
          return {
            ...state,
            contextIsLoading: true,
            appStatus: 'Deleting Form...',
          };

        case types.DELETE_FORM_DONE: {
          if (action.payload.form && action.payload.form.FormGuid) {
            return {
              ...state,
              forms: state.forms.filter(
                (stateForm) =>
                  stateForm.FormGuid !== action.payload.form.FormGuid
              ),
              contextIsLoading: false,
              appStatus: 'Form Deleted',
            };
          } else {
            return { ...state };
          }
        }

        // ----- FORM ALERTS ------------------------------------------------

        case types.LOAD_FORM_ALERTS:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_FORM_ALERTS_DONE: {
          if (action.payload.formAlerts) {
            const updatedFormAlerts = state.formAlerts.map(
              (stateFormAlert) =>
                action.payload.formAlerts.find(
                  (alert) =>
                    stateFormAlert.ProjectGuid === alert.ProjectGuid &&
                    stateFormAlert.FormType === alert.FormType
                ) || stateFormAlert
            );

            const newFormAlerts = action.payload.formAlerts.filter(
              (payloadAlert) =>
                !state.formAlerts.find(
                  (alert) =>
                    payloadAlert.ProjectGuid === alert.ProjectGuid &&
                    payloadAlert.FormType === alert.FormType
                )
            );

            return {
              ...state,
              formAlerts: newFormAlerts.concat(updatedFormAlerts),
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Form Alerts Updated',
            };
          } else {
            return { ...state };
          }
        }

        // ----- PRODUCT_STATUS ------------------------------------------------

        case types.LOAD_PRODUCT_STATUS:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_PRODUCT_STATUS_DONE: {
          if (action.payload.productStatus) {
            const updatedItems = state.productStatus.map(
              (stateItem) =>
                action.payload.productStatus.find(
                  (payloadItem) => stateItem.FormId === payloadItem.FormId
                ) || stateItem
            );

            const newItems = action.payload.productStatus.map(
              (payloadItem) =>
                !state.productStatus.find(
                  (stateItem) => payloadItem.FormId === stateItem.FormId
                ) && payloadItem
            );

            return {
              ...state,
              productStatus: newItems.concat(updatedItems),
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Product Status Updated',
            };
          } else {
            return { ...state };
          }
        }

        // ----- COREFLOW ----------------------------------------------------

        case types.SET_FILTER:
          return {
            ...state,
            filter: action.payload.filter,
          };

        // ----- LOCALIZATION ------------------------------------------------

        case types.LOAD_LOCALIZATION:
          return {
            ...state,
            lcid: action.payload.lcid,
            contextIsLoading: true,
          };

        case types.LOAD_LOCALIZATION_DONE: {
          if (action.payload.terms) {
            return {
              ...state,
              terms: action.payload.terms,
              lcid: action.payload.lcid,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Localization Terminology Updated',
            };
          } else {
            return { ...state };
          }
        }

        // ----- Form and Asset Types ------------------------------------------------

        case types.LOAD_FORMTYPES:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_FORMTYPES_DONE: {
          if (action.payload.formTypes) {
            return {
              ...state,
              formTypes: action.payload.formTypes,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Form Types Updated',
            };
          } else {
            return { ...state };
          }
        }

        case types.LOAD_ASSETTYPES:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_ASSETTYPES_DONE: {
          if (action.payload.assetTypes) {
            return {
              ...state,
              assetTypes: action.payload.assetTypes,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Asset Types Updated',
            };
          } else {
            return { ...state };
          }
        }

        // ----- NOTIFICATION ------------------------------------------------

        case types.LOAD_NOTIFICATIONS:
          return {
            ...state,
            contextIsLoading: true,
          };

        case types.LOAD_NOTIFICATIONS_DONE: {
          if (action.payload.notificationPanels) {
            return {
              ...state,
              notificationPanels: action.payload.notificationPanels,
              forceReload: false,
              contextIsLoading: false,
              appStatus: 'Notifications Updated',
            };
          } else {
            return { ...state };
          }
        }

        // --------- ALERT ----------------------------------------------------

        case types.SET_ALERT:
          return {
            ...state,
            alert: action.payload.alert,
          };

        // --------- MENUS ----------------------------------------------------

        case types.LOAD_MENUS:
          return {
            ...state,
            menusAreLoading: true,
            appStatus: 'Updating Navigation...',
          };

        case types.LOAD_MENUS_DONE:
          return {
            ...state,
            menuItems: action.payload.menuItems,
            menusAreLoading: false,
            forceReload: false,
            appStatus: 'Navigation Updated.',
          };

        case types.OpenPanelActionType: {
          return {
            ...state,
            actionPanelItem: action.payload.actionPanelItem,
            restoreFocusToElement: action.payload.restoreFocusToElementWithId,
            alert: null,
          };
        }

        case types.ClosePanelActionType: {
          return {
            ...state,
            actionPanelItem: null,
            restoreFocusToElement: null,
            alert: null,
          };
        }

        case types.SET_INLINE_PANEL_ITEM:
          return {
            ...state,
            inlinePanelItem: action.payload.inlinePanelItem,
            helpVisible: action.payload.inlinePanelItem !== null,
            alert: null,
          };

        case types.LOCATION_CHANGE:
          if (
            state.location &&
            state.location.href === action.payload.location.href
          ) {
            // this happens when only state object is updated (see onFilterChange.ts)
            return {
              ...state,
              ...action.payload,
            };
          } else {
            // new "destination" - ensure panels are cleared
            return {
              ...state,
              ...action.payload,

              actionPanelItem: null,
              inlinePanelItem: null,
              contextIsLoading: false,
              /* contextForm: null, */
              isInitializing: false,
            };
          }

        /*
        case types.UPDATEPATH:
          return {
            ...state,
            // Causes double invalid path update if enabled, causes invalid menus with hard reloads if disabled  
            currentPath: buildCurrentPath(
              action.payload.path,
              action.payload.contextWeb
                ? action.payload.contextWeb
                : state.contextWeb,
              action.payload.menuItems
                ? action.payload.menuItems
                : state.menuItems,
              action.payload.contextForm
                ? action.payload.contextForm
                : state.contextForm
            )
          };
  */
        default:
          return state;
      }
    } else {
      return state;
    }
  }
};

export default appReducer;
