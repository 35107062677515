import * as React from 'react';
import { logOut } from '../../redux/actionCreators';

interface Props {}

export default class Logout extends React.Component<Props> {
  render() {
    window.store.dispatch(logOut());
    return null;
  }
}
