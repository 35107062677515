import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import ReactHtmlParser from 'react-html-parser';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

interface Props {
  title: string;
  description?: string;
  iconClass?: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

export const EmptyState: React.FunctionComponent<Props> = props => {
  return (
    <div style={{ opacity: 0, animation: 'fadeIn 1s forwards', animationDelay: '1s' }}>
      <Spinner size={SpinnerSize.large} style={{ opacity: 1, animation: 'fadeOut 2s forwards', animationDelay: '2s' }} />
      <div className="pfx-emptystate" style={{ opacity: 0, animation: 'fadeIn 2s forwards', animationDelay: '4s' }}>
        <Icon className={props.iconClass} style={{ fontSize: '3em' }} />
        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
          <h1>{props.title}</h1><br />
          <div style={{ textAlign: 'left' }}>{ReactHtmlParser(props.description)}</div>
        </div>

        {props.onButtonClick && props.buttonTitle && (
          <PrimaryButton onClick={props.onButtonClick}>
            {props.buttonTitle}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default EmptyState;