import * as React from 'react';

export interface Phase {
    Label: string;
    Highlight: boolean;
    Ripple: boolean | string;
}

interface Props {
    Stops: Phase[];
    OnClick?: (stop: Phase) => void;
    OnMouseOver?: (stop: Phase) => void;
    OnMouseOut?: (stop: Phase) => void;
    LabelRotation: number;
    Width: string;
    MinWidth: string;
    MaxWidth: string;
    Center: boolean;
    Layout: 'Default' | 'Slim';
}

export const PhaseMap: React.FunctionComponent<Props> = props => {
    const percentOffset = 100 / (props.Stops.length - 1);
    return (
        <div
            className={'PhaseMap PhaseMapLayout ' + props.Layout || 'Default'}
            style={{
                width: props.Width || '',
                minWidth: props.MinWidth || '',
                maxWidth: props.MaxWidth || '',
                display: props.Center && 'block' || '',
                margin: props.Center && '0 auto' || ''
            }}
        >
            <div className="phaseMapInner">{
                props.Stops.map((value: Phase, index: number, array: Phase[]) => {
                    return (
                        <div
                            key={index}
                            className="phaseStop"
                            data-highlight={value.Highlight ? 'true' : 'false'}
                            data-ripple={value.Ripple ? 'true' : 'false'}
                            style={{ left: (index * percentOffset) + '%' }}
                        >
                            <label style={{ transform: props.LabelRotation && 'rotate(' + props.LabelRotation + 'deg)' || '' }}>
                                value.Label
                            </label>
                            <div
                                className="phaseCircle"
                                onClick={() => { return props.OnClick && props.OnClick(value); }}
                                onMouseOver={() => { return props.OnMouseOver && props.OnMouseOver(value); }}
                                onMouseOut={() => { return props.OnMouseOut && props.OnMouseOut(value); }}
                            >
                                {index + 1}
                            </div>
                        </div>
                    );
                })
            }</div>
        </div>
    );
};

export default PhaseMap;
