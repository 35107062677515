import { Http } from 'fit-ui';
import { PFXWeb } from './webApi';
import { setUnauthorized } from 'src/redux/actionCreators';
import { PFXState } from 'src/Root';
import { AuthError } from 'src/authentication/Auth';

/*
export function statusHelper(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(new Error(response.statusText));
  }
}
*/

export interface PFXData {
  [name: string]: any;
}

export default async function jsonRequest(
  uri: string,
  contextWeb: PFXWeb,
  data?: PFXData,
  method?: 'GET' | 'POST' | 'HEAD' | 'PUT' | 'DELETE' | 'OPTIONS',
  noLCID?: boolean
): Promise<any> {
  const promise = new Promise<any>((resolve, reject) => {
    const state = PFXState();
    const ctxWeb = contextWeb || state.contextWeb;
    if (ctxWeb) {
      data = {
        ...data,
        ContextWeb: ctxWeb.guid,
      };
    }

    if (data.lcid ?? state.lcid) {
      // LCID may not be present under app start
      data = {
        ...data,
        lcid: data.lcid ?? state.lcid,
      };
    }

    // Append Context Info to consumerfilter collection if possible ""
    if (data && ctxWeb) {
      let d: PFXData = {};
      if (data.consumerfilter) {
        d = JSON.parse(data.consumerfilter) as PFXData;
      }
      if (!d.ContextWeb) {
        d.ContextWeb = ctxWeb.guid;
      }

      if (!d || !d.ContextWeb) {
        console.warn('Insufficient ContextWeb info in jsonRequest', uri, data);
      }

      data.consumerfilter = JSON.stringify({
        ...d,
        appInstance: state.appInstance,
      });
    }

    if (uri.includes('PFX_System') || (noLCID && data.lcid)) {
      delete data.lcid;
    }

    if (method === 'GET' && data) {
      const dataEncoded = data
        ? Object.keys(data)
            .reduce(function (a: any, k: any) {
              a.push(k + '=' + encodeURIComponent(data[k]));
              return a;
              // tslint:disable-next-line:align
            }, [])
            .join('&')
        : '';

      uri += '?' + dataEncoded;
    }

    window.auth
      .acquireToken(window.ProjectFlowX.coreflow.resourceId)
      .then((token) => {
        const bearer = 'Bearer ' + token;

        const req = new Http.JsonRequest(uri);
        req.AddHeader('Accept', 'application/json');
        req.AddHeader('Content-Type', 'application/json; charset=utf-8');
        req.AddHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
        req.AddHeader('Authorization', bearer);

        if (state.logMode) {
          /* #6984, #7127 */
          req.AddHeader('Server-Timings', 'Enabled');
        }

        if (method) {
          req.Method(method);
        }

        if (data) {
          req.SetData(data);
        }

        req.OnSuccess((r: Http.Request) => {
          try {
            const result = r.GetResponseJson();
            if (result.Errors && result.Errors.length > 0) {
              reject(
                'Request failed. Response from server: ' + r.GetResponseText()
              );
            }
            resolve(result);
          } catch (e) {
            reject(
              new Error(
                'Request failed with exception ' + (e as Error).message
              ) +
                '. Response from server: ' +
                r.GetResponseText()
            );
          }
        });

        req.OnFailure((r: Http.Request) => {
          if (r.GetHttpStatus() === 401) {
            // window.location.reload();
            const msg = 'Unauthorized CoreFlow Service Call (401)';
            reject(msg);
            window.store.dispatch(setUnauthorized(msg));
          } else {
            reject(
              new Error(
                'Request failed with HTTP status: ' +
                  r.GetHttpStatus() +
                  '. Response from server: ' +
                  r.GetResponseText()
              )
            );
          }
        });

        req.Start();
      })
      .catch((error: AuthError) => {
        if (
          error.errorCode === 'login_required' ||
          error.errorCode === 'interaction_required'
        ) {
          const msg = 'Authentication Error';
          console.error(msg + ': ', error);
          window.store.dispatch(
            setUnauthorized(msg + ', ' + error + ': ' + JSON.stringify(error))
          );
        } else {
          console.error(error, window.ProjectFlowX.coreflow.resourceId);
        }
      });
  });

  return promise;
}
