import * as React from 'react';
import { PFXWeb, PFXWebType } from 'src/api/webApi';
import { PFXLocalizedTerms, PFXShared, PFXMessages } from 'src/api/localizationApi';

import { StylesConfig } from 'react-select';
import Select from 'react-select';
import { PFXHelpers } from 'src/utils/PFXHelpers';

interface Props {
  webs: PFXWeb[];
  contextWeb: PFXWeb;
  contextIsLoading: boolean;
  onChange?: (e: any) => void;
  onFocus?: () => void;
  terms: PFXLocalizedTerms;
}

interface PFXContextWebOption {
  slug?: string;
  type?: string;
  label?: string;
  keywords?: string;
  selected?: boolean;
  options?: PFXContextWebOption;
  value?: string;
}

interface PFXContextWebGroup {
  label: string;
  options: PFXContextWebOption;
}

export interface PFXSearchTerm {
  inputValue: string;
}

interface State {
  isFocused: boolean;
  groups: PFXContextWebGroup[];
  customStyles: Partial<StylesConfig<PFXContextWebGroup, false>>;
}

export class ContextSelector extends React.Component<Props, State> {

  private _select: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.focus = this.focus.bind(this);
    this.state = {
      isFocused: false,
      groups: this.getOptions(),
      customStyles: this.getStyles()
    };
  }

  getOptions = (): PFXContextWebGroup[] => {
    const result = [];
    if (this.props.webs) {
      const options = this.props.webs
        .filter(item => item.archived !== true)
        .map(item => ({
          value: item.guid,
          slug: item.slug,
          type: item.type,
          keywords: PFXHelpers.htmlDecode(item.keywords),
          label: item.title,
          selected: this.props.contextWeb
            ? this.props.contextWeb.guid === item.guid
            : false
        }));

      result.push({
        label: this.props.terms[PFXShared.PORTAL],
        options: options.filter(web => web.type === PFXWebType.PORTAL)
      });

      result.push({
        label: this.props.terms[PFXShared.PROJECTMANAGEMENTOFFICE],
        options: options.filter(web => web.type === PFXWebType.PMO)
      });

      result.push({
        label: this.props.terms[PFXShared.PROJECTS],
        options: options.filter(web => web.type === PFXWebType.PROJECT)
      });
    }
    return result;
  }

  getStyles = (): Partial<StylesConfig<PFXContextWebGroup, false>> => {
    // reference: https://react-select.com/styles 
    const style = { w: 250, h: 58 };
    return {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted grey',
        padding: 10
      }),

      singleValue: (provided, state) =>
        this.props.contextWeb && this.props.contextWeb.archived
          ? {
            ...provided,
            fontStyle: 'italic'
          }
          : { ...provided },

      menu: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        marginTop: 0
      }),

      control: (provided, state) => ({
        ...provided,
        minWidth: style.w,
        height: style.h,
        boxShadow: 'none'
      }),

      input: (provided, state) => ({
        ...provided,
        color: 'var(--pfx-theme-beige)'
      }),

      container: (provided, state) => ({
        ...provided,
        minWidth: style.w,
        height: style.h,
        backgroundColor: 'var(--pfx-theme-darkgray-focus)'
      })
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.webs !== prevProps.webs || this.props.contextWeb !== prevProps.contextWeb) {
      this.setState({
        groups: this.getOptions(),
        customStyles: this.getStyles()
      });
    }
  }

  focus() {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  searchFilter = (
    option: { label: string, value: string, data: PFXContextWebOption },
    rawInput: string
  ): boolean => {
    if (rawInput && option && option.label && option.data && option.data.slug) {
      const input = rawInput.toLowerCase();
      return (option.data.keywords).toLowerCase().indexOf(input) !== -1 /* || input.trim() === option.data.slug.toLowerCase() */;
    } else {
      return true;
    }
  }

  setSelectRef = element => {
    console.log('select', element);
    this._select = element;
  }

  render() {
    const { contextWeb, contextIsLoading, onChange } = this.props;

    return (this.props.webs && this.props.webs.length > 1 &&
      <div className="nav navbar-left contextselector-pf">
        <Select
          styles={this.state.customStyles}
          className="selectpicker"
          filterOption={this.searchFilter}
          classNamePrefix="select"
          noOptionsMessage={(term: PFXSearchTerm) => this.props.terms[PFXMessages.NOHITS].format(term.inputValue)}
          blurInputOnSelect={true}
          /* autoBlur */
          /* ref={ref => this._select = ref} */
          value={
            contextWeb && !this.state.isFocused
              ? {
                label: contextWeb.archived
                  ? contextWeb.title +
                  ' (' +
                  this.props.terms[PFXShared.ARCHIVED] +
                  ')'
                  : contextWeb.title, options: {
                    value: contextWeb.guid,
                    label: contextWeb.archived
                      ? contextWeb.title +
                      ' (' +
                      this.props.terms[PFXShared.ARCHIVED] +
                      ')'
                      : contextWeb.title,
                    slug: contextWeb.slug,
                    type: contextWeb.type,
                    keywords: contextWeb.keywords,
                    selected: true
                  }
              }
              : {
                label: '', options: {
                  value: '',
                  label: '',
                  slug: '',
                  type: '',
                  keywords: '',
                  selected: false
                }
              }
          }
          /* isDisabled={contextIsLoading || null} */
          isLoading={contextIsLoading || null}
          isClearable={false}
          isRtl={false}
          isSearchable
          name="PFX_Navigator"
          options={this.state.groups}
          onChange={onChange}
          onFocus={this.focus}
          onMenuOpen={this.focus}
          onBlur={() => this.setState({ isFocused: false })}
          onMenuClose={() => {
            this.setState({ isFocused: false });
            if (this._select) {
              this._select.blur();
            }
          }}
        />
      </div>
    );
  }
}

export default ContextSelector;
