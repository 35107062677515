import * as React from 'react';

interface Props { }

export const OneCard: React.FunctionComponent<Props> = props => {
  return (
    <div className="container-fluid">
      <div className="row row-cards-pf">
        <div className="pfx-OneCard card-pf card-pf-accented">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default OneCard;
