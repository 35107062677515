import { checkForUpdates } from 'src/redux/actionCreators';
import { cfx_web_ns } from './CoreFlow';

function onCookieTokenSetEvent(event: cfx_web_ns.CookieTokenSetEvent) {
    const { currentCookieTokenSetEventTimestamp } = window.ProjectFlowX;
    console.log(`CookieTokenSetEvent. New timestamp: ${event.timestamp}. Current timestamp: ${currentCookieTokenSetEventTimestamp}.`);

    if (currentCookieTokenSetEventTimestamp === undefined
        || event.timestamp > currentCookieTokenSetEventTimestamp) {
        console.log('CookieTokenSetEvent with newer timestamp received. Will check for updates.');
        window.ProjectFlowX.currentCookieTokenSetEventTimestamp = event.timestamp;
        window.store.dispatch(checkForUpdates());
    }
}

export default onCookieTokenSetEvent;