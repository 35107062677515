import { Core, Date as FitDate, Internationalization } from 'fit-ui';

export const PFXHelpers = {
  ticksToDate(ticks: number): Date {
    if (ticks) {
      // ticks are in nanotime; convert to microtime
      const ticksToMicrotime = ticks / 10000;

      // ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
      const epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));

      // new date is ticks, converted to microtime, minus difference from epoch microtime
      const tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);

      return tickDate;
    } else {
      return null;
    }
  },

  dateToTicks(theDate: Date): number {
    return theDate.getTime() * 10000 + 621355968000000000;
  },

  addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  disableLightDismiss(element: HTMLElement): boolean {
    // avoid accidental dismisses from link / smiley / image dialogs etc used in light dismissable contexts, #6384 
    let _element = element;
    while (_element) {
      if (_element.dataset && _element.dataset.disableLightDismiss === 'True') {
        return true;
      } else {
        _element = _element.parentElement;
      }
    }
    return false;
  },

  async waitUntil(condition: () => boolean, interval: number = 200, numberOfTries: number = 10) {
    return await new Promise<void>(resolve => {
      let tries = 1;
      const instance = setInterval(() => {
        if (condition() || tries > numberOfTries) {
          resolve();
          clearInterval(instance);
        }
        tries++;
        // tslint:disable-next-line:align
      }, interval);
    });
  },


  setCustomStyleSheet(cssContent: string) {
    const id = 'PFX_CUSTOM_STYLES';
    const existingElement = document.getElementById(id);
    if (!existingElement) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.id = id;
      style.innerText = cssContent;
      head.appendChild(style);
    } else {
      /* not supported!
      (existingElement as HTMLStyleElement).innerText = cssContent;
      */
    }
  },

  setCustomJavascript(scriptContent: string) {
    const id = 'PFX_CUSTOM_SCRIPT';
    const existingElement = document.getElementById(id);
    if (!existingElement) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.id = id;
      script.innerText = scriptContent;
      head.appendChild(script);
    } else {
      /* not supported!
      (existingElement as HTMLStyleElement).innerText = scriptContent;
      */
    }
  },

  shallowCompare(obj1: Object, obj2: Object): boolean {
    if (obj1 && obj2) {
      return (
        Object.keys(obj1).length === Object.keys(obj2).length &&
        Object.keys(obj1).every(key => obj1[key] === obj2[key])
      );
    } else {
      return false;
    }
  },

  deepCompare: Core.IsEqual,

  setLanguage(lcid: number) {
    switch (lcid) {
      case 1030: {
        // daDK
        Internationalization.Locale('da');
        document.documentElement.lang = 'da';
        break;
      }
      case 1033: {
        // enUS
        Internationalization.Locale('en');
        document.documentElement.lang = 'en';
        break;
      }
      case 1044: {
        // Norwegian Bokmål (nb-NO)
        Internationalization.Locale('no');
        document.documentElement.lang = 'no';
        break;
      }
      default: {
        // fallback
        Internationalization.Locale('en');
        document.documentElement.lang = 'en';
        break;
      }
    }
  },

  heavySleep(milliseconds: number) {
    // blocking "sleep" method, use with caution
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  },

  isExternalUrl(url: string) {
    const host = window.location.hostname;

    // Initialize as Relative URL
    var linkHost = host;
    if (/^https?:\/\//.test(url)) {
      // Absolute URL.
      // The easy way to parse an URL, is to create <a> element.
      // @see: https://gist.github.com/jlong/2428561
      var parser = document.createElement('a');
      parser.href = url;
      linkHost = parser.hostname;
    }

    return host !== linkHost;
  },

  shortDate(date: Date): string {
    return FitDate.Format(date, 'YYYY-MM-DD');
  },

  htmlDecode(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  },

  escapeHtml(html: string): string {
    var text = document.createTextNode(html);
    var p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
  },

  mapRange(
    valueIn: number,
    minIn: number,
    maxIn: number,
    minOut: number,
    maxOut: number
  ) {
    return ((maxOut - minOut) * (valueIn - minIn)) / (maxIn - minIn) + minOut;
  },

  colors: {
    RED: 'var(--pfx-theme-red)',
    YELLOW: 'var(--pfx-theme-yellow)',
    GREEN: 'var(--pfx-theme-green)',
    NEUTRAL: 'var(--pfx-theme-lightblue)'
  },

  groupBy(xs: any, key: any) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
      // tslint:disable-next-line:align
    }, {});
  },

  getVersion() {
    /*
    const buildDate = new Date(
      parseInt(`${process.env.REACT_APP_BUILD_DATE}`, 10)
    );
    const year = buildDate.getFullYear() % 100;
    const month = buildDate.getMonth() + 1;
    const day = buildDate.getDate();

    // Count 2-minute intervals elapsed since midnight:(HH * 60 + MM) / 2
    const counter = (buildDate.getHours() * 60 + buildDate.getMinutes()) / 2;

    // Format the stamp as YYMMDDCCC
    const buildNumber = sprintf('%02d%02d%02d%03d', year, month, day, counter);
    */
    let version = `${process.env.REACT_APP_VERSION}`;
    if (!version || version === '') {
      version = '0.0.0';
    }

    let revision = `${process.env.REACT_APP_BUILD_ID}`;
    if (!revision || revision === '') {
      revision = '0';
    }

    let release = `${process.env.REACT_APP_RELEASE_TAG}`;
    if (!release || release === '') {
      release = 'master';
    }

    return version + '_Rev' + revision + '_' + release /* buildNumber */;
  },

  stringHash(s: string): number {
    // "overly clever code ahead"
    // https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
    let h: number;
    for (let i = 0; i < s.length; i++) {
      // the "| 0" part forces h to be a 32-bit number, optimizing for speed
      // tslint:disable-next-line:no-bitwise
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    }
    return h;
  },

  delay: (ms: number) => new Promise(res => setTimeout(res, ms)),

  objectHash(obj: object): number {
    return this.stringHash(JSON.stringify(obj));
  },

  getOrigin(url: string): string {
    var parser = document.createElement('a');
    parser.href = url;
    return parser.origin;

    // parser.protocol; // => "http:"
    // parser.host;     // => "example.com:3000"
    // parser.hostname; // => "example.com"
    // parser.port;     // => "3000"
    // parser.pathname; // => "/pathname/"
    // parser.hash;     // => "#hash"
    // parser.search;   // => "?search=test"
    // parser.origin;   // => "http://example.com:3000"
  }
};
