import * as React from 'react';

import {
  PFXLocalizedTerms,
  PFXMessages
} from 'src/api/localizationApi';
import { PFXWeb } from 'src/api/webApi';
import EmptyState from 'src/components/EmptyState/EmptyState';
import PFXMenuItem from 'src/models/PFXMenuItem';
import { PFXUser } from 'src/models/PFXUser';
import { loadPath } from 'src/redux/actionCreators';

interface Props {
  terms: PFXLocalizedTerms;
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  currentPath: PFXMenuItem[];
}

export const NotFoundPage: React.FunctionComponent<Props> = props => {
  return (

    <EmptyState
      title={props.terms[PFXMessages.PAGENOTFOUND]}
      description={props.terms[PFXMessages.PAGENOTFOUNDDETAILS]}
      iconClass={'ms-Icon ms-Icon--UnknownSolid'}
      buttonTitle={props.terms[PFXMessages.PAGENOTFOUNDLINK].format(
        props.contextWeb.title
      )}
      onButtonClick={() => window.store.dispatch(loadPath(props.contextWeb.slug))}
    />

  );
};

export default NotFoundPage;
