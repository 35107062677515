import * as React from 'react';

import PFXMenuItem from '../../models/PFXMenuItem';
import { PFXWeb } from 'src/api/webApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { AppType, ToolType } from '../CoreFlow/CoreFlow';
import { PFXUser } from 'src/models';
import { PFXForm } from 'src/api/formsApi';

interface Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  contextForm: PFXForm;
  lcid: number;
  location: Location;
  currentPath: PFXMenuItem[];
  navigateTo: (item: PFXMenuItem) => void;
}

export const BreadcrumbMenu: React.FunctionComponent<Props> = props => {
  const replaceTitle = (i: PFXMenuItem) => {
    if (i.to === '/:slug') {
      return props.contextWeb.title;
    } else {
      if (i.to.indexOf(':item') !== -1 && props.contextForm) {
        return props.contextForm.slug + ': ' + props.contextForm.PFX_Title;
      } else {
        return i.title;
      }
    }
  };

  return (
    <>
      <div className="pfx-toolbar-app">
        {/* Projektleder: Inge Lyngsie | Startdato: 01-12-2019 | Slutdato: 02-11-2020 */}
        <CoreFlowAppBase
          appName={AppType.Composition}
          toolName={ToolType.Composition}
          toolId={'PFX_TopPanel'}
          contextWeb={props.contextWeb}
          lcid={props.lcid}
          contextUser={props.contextUser}
          filter={{
            ...props.contextForm && { ContextForm: props.contextForm.FormGuid }
          }}
          fullPageLayout={true}
          disableUnsavedCheck={true}
        />
      </div>
      <ol
        role="navigation"
        aria-label="breadcrumbs"
        className="pfx-breadcrumb breadcrumb"
      >
        {props.currentPath.map((item, index, items) => {
          const to = (item.redirectPath && true) ? item.redirectPath.replace(':slug', props.contextWeb ? props.contextWeb.slug : '') : item.to.replace(':slug', props.contextWeb ? props.contextWeb.slug : '');
          return index === items.length - 1 ? (
            <li key={index} className="disabled">
              <h1 className="pfx-breadbrumb-selected">{replaceTitle(item)}</h1>
            </li>
          ) : item.itemType !== 'action' &&
            item.noNavigation &&
            item.to !== '/:slug' ? console.debug('not slug') : (
            <li key={index}>
              {item.noNavigation && item.to !== '/:slug' ? (
                replaceTitle(item)
              ) : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    props.navigateTo({ ...item, to: to, noNavigation: false })
                  }}

                  href={to}
                >
                  {replaceTitle(item)}
                </a>
              )}
            </li>
          )
        })}
      </ol>
    </>
  );
};
