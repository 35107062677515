import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXWeb } from './webApi';
import { PFXHelpers } from 'src/utils/PFXHelpers';

/* obsolete #8983 */
export interface PFXProductStatus {
  ProjectTitle: string;
  FormId: string;
  PFX_Completed: boolean;
  ProductStatus: string;
  PFX_HoursLeft: number;
  PFX_HoursEstimate: number;
  Progress: number;
  ProjectId: string;
  ProductTitle: string;
  ProgressColor: string;
}

export const productStatusApi = {
  fetchData(web: PFXWeb): Promise<PFXProductStatus[] | null> {
    const QUERY = '/PFX_Product_Overview_Status/data';

    let consumerfilters: string[] = [];

    if (web && web.guid && web.guid !== 'undefined') {
      consumerfilters.push('"web":"' + encodeURI(web.guid) + '"');

      let consumerfilter: string = null;
      consumerfilter = '{' + consumerfilters.join(',') + '}';

      return jsonRequest(
        COREFLOW_API + QUERY,
        web,
        { mode: 'simple', consumerfilter },
        'GET'
      )
        .then((data): PFXProductStatus[] => {
          if (data) {
            const result = data.map((formAlert: PFXProductStatus) => {
              return {
                ...formAlert,
                ProjectTitle: PFXHelpers.htmlDecode(formAlert.ProjectTitle),
                ProductTitle: PFXHelpers.htmlDecode(formAlert.ProductTitle),
              };
            });
            return result;
          } else {
            return [];
          }
        })
        .catch((error: Error) => {
          console.error(
            'productStatusApi.fetchData ERROR',
            { RequestUrl: COREFLOW_API + QUERY },
            error
          );
          return null;
        });
    } else {
      console.warn('Invalid web parameter supplied');
      return null;
    }
  },
};
