import { IStyleFunctionOrObject, IPanelStyleProps, IPanelStyles, getTheme } from '@fluentui/react';

const theme = getTheme();

const actionPanelStyles: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> = {
    navigation: {
        borderBottom: '1px solid rgb(237, 235, 233)',
        borderCollapse: 'collapse',
        backgroundColor: theme.palette.neutralLighterAlt,
        marginTop: -18,
        paddingTop: 18,
        paddingBottom: 18
    },
    headerText: {
        backgroundColor: 'transparent',
    },
    header: { backgroundColor: 'transparent' },
    footer: {
        borderTop: '1px solid rgb(237, 235, 233)',
        borderCollapse: 'collapse'
    },
    footerInner: {
        backgroundColor: theme.palette.neutralLighterAlt,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
        paddingBottom: 16
    },
    content: {
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: '100%',
    },
    scrollableContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        flexGrow: 1,
        flexBasis: 'auto',
    },
};

export default actionPanelStyles;
