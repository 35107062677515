import * as React from 'react';
import ProjectHomePage from '../components/Project/ProjectHome';
import NotFound from '../components/Misc/NotFound';

import Login from '../components/User/Login';
import Logout from '../components/User/Logout';
import UnknownComponent from '../components/Misc/UnknownComponent';

import NewO365Asset from 'src/components/Panels/New-O365Asset';
import FormsView from 'src/components/Forms/FormsView';
// import { AppInterface } from 'src/Root';
// import AboutPage from 'src/components/Misc/About';
import PFXMenuItem, { PFXComponentProps, PFXContentPlacement, PFXMenuItemType } from 'src/models/PFXMenuItem';
import { logIn } from 'src/redux/actionCreators';
import CoreFlowAppBase from 'src/components/CoreFlow/CoreFlowAppBase';
import { AppType, ToolType } from 'src/components/CoreFlow/CoreFlow';
import { appDebugEnabled, getSlugFromUrl, PFXCSSVars, PFXRenderZone, PFXSlugType, PFXState } from 'src/Root';
// import { PFXHelpers } from './PFXHelpers';

//Also fixes unused import warning
if (!React) {
  console.debug('React missing');
}

export const PFXComponentFactory = (
  className: string,
  itemProps: PFXComponentProps,
  zone: PFXRenderZone,
  item?: PFXMenuItem
): JSX.Element => {

  const props = PFXState();
  const currentItem = item ?? props.currentPath[props.currentPath.length - 1];

  if (appDebugEnabled) {
    console.debug(
      'PFXComponentFactory',
      className,
      props
    );
  }

  /*
    console.debug('Compare items, is equal:', PFXHelpers.deepCompare(currentItem, (window as any).pfx_lastItem), currentItem, (window as any).pfx_lastItem);
    (window as any).pfx_lastItem = currentItem;
  */

  if (itemProps?.ganttV3Layout) {
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.TOOLBAR_DISPLAY, 'none');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.CONTENT_PADDNG, '5px');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.CARD_PADDNG, '0px');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.SCROLLBAR_WIDTH, '0px');
  } else {
    // Default values also declared in App.scss, yuck!
    // consider implementing singe source of truth solution suggested here:
    // https://itnext.io/sharing-variables-between-js-and-sass-using-webpack-sass-loader-713f51fa7fa0
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.TOOLBAR_DISPLAY, 'unset');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.CONTENT_PADDNG, '20px');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.CARD_PADDNG, '20px');
    window.ProjectFlowX.updateCSSVar(PFXCSSVars.SCROLLBAR_WIDTH, '17px');
  }

  switch (className) {
    case 'ProjectHomePage':
      return (
        <>
          {currentItem.hasCustomContent && currentItem.hasCustomContent.includes(PFXContentPlacement.PAGE_TOP) && currentItem.itemType === PFXMenuItemType.navigation && zone === PFXRenderZone.PAGE && (
            <CoreFlowAppBase
              contextWeb={props.contextWeb}
              contextUser={props.contextUser}
              lcid={props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              fullPageLayout={false}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.PAGE_TOP,
                PFX_Filter_TargetId: currentItem.ID,
                PFX_Filter_Scope: currentItem.scope,
                PFX_Filter_RenderZone: zone
              }}
              disableUnsavedCheck={true}
              dataTick={itemProps && itemProps.reloadOnNavigateToSelf ? Date.now() : props.dataTick}
            />
          )
          }
          <ProjectHomePage
            contextWeb={props.contextWeb}
            contextUser={props.contextUser}
            lcid={props.lcid}
            formAlerts={props.formAlerts}
            // productStatus={props.productStatus}
            terms={props.terms}
            formTypes={props.formTypes}
          />
          {currentItem.hasCustomContent && currentItem.hasCustomContent.includes(PFXContentPlacement.PAGE_BOTTOM) && currentItem.itemType === PFXMenuItemType.navigation && zone === PFXRenderZone.PAGE && (
            <CoreFlowAppBase
              contextWeb={props.contextWeb}
              contextUser={props.contextUser}
              lcid={props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              fullPageLayout={false}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.PAGE_BOTTOM,
                PFX_Filter_TargetId: currentItem.ID,
                PFX_Filter_Scope: currentItem.scope,
                PFX_Filter_RenderZone: zone
              }}
              disableUnsavedCheck={true}
              dataTick={itemProps && itemProps.reloadOnNavigateToSelf ? Date.now() : props.dataTick}
            />
          )
          }
        </>
      );

    case 'Login':
      return (
        <Login isInitializing={props.isInitializing} terms={props.terms} />
      );

    case 'Logout':
      return <Logout />;

    case 'SwitchUser':
      window.store.dispatch(logIn(true));
      return null;

    case 'NewO365Asset':
      return (
        <NewO365Asset
          contextWeb={props.contextWeb}
          terms={props.terms}
          formType={itemProps.formType}
          onCancel={itemProps.onCancel}
          onSave={itemProps.onSave}
          formTypes={props.formTypes}
          assetTypes={props.assetTypes}
        />
      );

    case 'Redirect':
      return <></>;

    case 'NotFound':
      return <NotFound
        terms={props.terms}
        contextWeb={props.contextWeb}
        contextUser={props.contextUser}
        currentPath={props.currentPath}
      />;

    case 'Composition':

      let ItemId = null;
      let DriveId = null;
      let DriveItemId = null;
      if (itemProps.isDriveBrowser) {
        ItemId = getSlugFromUrl(location.href, PFXSlugType.ITEM);
        DriveId = getSlugFromUrl(location.href, PFXSlugType.DRIVE);
        DriveItemId = getSlugFromUrl(location.href, PFXSlugType.DRIVEITEM);
      }

      return (
        <>
          {currentItem.hasCustomContent && currentItem.hasCustomContent.includes(PFXContentPlacement.PAGE_TOP) && currentItem.itemType === PFXMenuItemType.navigation && zone === PFXRenderZone.PAGE && (
            <CoreFlowAppBase
              contextWeb={props.contextWeb}
              contextUser={props.contextUser}
              lcid={props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              fullPageLayout={false}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.PAGE_TOP,
                PFX_Filter_TargetId: currentItem.ID,
                PFX_Filter_Scope: currentItem.scope,
                PFX_Filter_RenderZone: zone
              }}
              disableUnsavedCheck={true}
              dataTick={itemProps.reloadOnNavigateToSelf ? Date.now() : props.dataTick}
            />
          )
          }
          <CoreFlowAppBase
            appName={AppType.Composition}
            toolName={ToolType.Composition}
            lcid={props.lcid}
            toolId={itemProps.composition}
            pageId={item?.ID}
            appEditStates={props.appEditStates}
            contextWeb={props.contextWeb}
            contextForm={props.contextForm}
            contextWebIdOverride={itemProps.contextWebOverride}
            contextUser={props.contextUser}
            dataTick={itemProps.reloadOnNavigateToSelf ? Date.now() : props.dataTick}
            showSpinner={itemProps.showSpinner}
            filter={{
              ...props.filter,
              ...itemProps.filter,
              ...ItemId && { FormSlug: ItemId },
              ...DriveId && { DriveId },
              ...ItemId && { ItemId: DriveItemId },
              ...{ PFX_Filter_RenderZone: zone }
            }}
            reloadOnChange={itemProps.reloadOnChange}
            updateOnChange={itemProps.updateOnChange}
            fullPageLayout={itemProps.fullPageLayout}
            disableUnsavedCheck={itemProps.disableUnsavedCheck}
          />
          {currentItem.hasCustomContent && currentItem.hasCustomContent.includes(PFXContentPlacement.PAGE_BOTTOM) && currentItem.itemType === PFXMenuItemType.navigation && zone === PFXRenderZone.PAGE && (
            <CoreFlowAppBase
              contextWeb={props.contextWeb}
              contextUser={props.contextUser}
              lcid={props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              fullPageLayout={false}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.PAGE_BOTTOM,
                PFX_Filter_TargetId: currentItem.ID,
                PFX_Filter_Scope: currentItem.scope,
                PFX_Filter_RenderZone: zone
              }}
              disableUnsavedCheck={true}
              dataTick={itemProps.reloadOnNavigateToSelf ? Date.now() : props.dataTick}
            />
          )
          }
        </>
      );

    case 'Form':
      return (
        <FormsView
          contextWeb={props.contextWeb}
          contextUser={props.contextUser}
          contextForm={props.contextForm}
          lcid={props.lcid}
          terms={props.terms}
          formTypes={props.formTypes}
          newFormType={itemProps ? itemProps.newFormType : null}
        />
      );
    /*
    case 'Plan':
      return <EmptyPlan terms={props.terms} />;

    case 'About':
      return (
        <AboutPage
          contextWeb={props.contextWeb}
          contextUser={props.contextUser}
          terms={props.terms}
          alert={props.alert}
        />
      );
    */
    default:
      return (
        <UnknownComponent terms={props.terms} currentPath={props.currentPath} />
      );
  }
};
