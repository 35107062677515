import * as React from 'react';
import * as Layout from './Layouts';
import { PFXLocalizedTerms, PFXMessages } from 'src/api/localizationApi';
import EmptyState from 'src/components/EmptyState/EmptyState';
import PFXMenuItem from 'src/models/PFXMenuItem';

interface Props {
  terms: PFXLocalizedTerms;
  currentPath: PFXMenuItem[];
}

export const UnknownComponentPage: React.FunctionComponent<Props> = props => {
  return (
    <Layout.OneCard>
      <EmptyState
        title={props.terms[PFXMessages.COMPNOTFOUND]}
        description={props.terms[PFXMessages.COMPNOTFOUNDDETAILS].format(
          props.currentPath
            ? props.currentPath[props.currentPath.length - 1].component
            : '[unknown component]'
        )}
        iconClass={'ms-Icon ms-Icon--UnknownSolid'}
      />
    </Layout.OneCard>
  );
};

export default UnknownComponentPage;
