import * as React from 'react';
import { clearAlert, loadPath } from 'src/redux/actionCreators';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';

export interface PFXAlert {
  message: string;
  type: MessageBarType;
  dialogOnly?: boolean;
  action?: {
    link: string;
    label: string;
    clearAfterClick?: boolean;
  };
}

interface Props {
  alert: PFXAlert;
}

// export const AlertBar: React.FunctionComponent<Props> = props => {
export class AlertBar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.getLink = this.getLink.bind(this);
  }

  onChange = () => {
    window.store.dispatch(clearAlert());
  };

  getLink(): JSX.Element {
    if (this.props.alert.action) {
      const action = this.props.alert.action;

      return (
        <a
          href={action.link}
          onClick={!action.link.toLowerCase().startsWith('http') ? event => {
            event.preventDefault();
            window.store.dispatch(loadPath(action.link, false, !(action.clearAfterClick ?? false)));
          } : null}>
          {action.label}
        </a>
      );

    } else {
      return null;
    }
  }

  render() {
    const props = this.props;
    if (props.alert) {
      return (
        <MessageBar
          messageBarType={props.alert.type}
          onDismiss={this.onChange}
          styles={props.alert.type === MessageBarType.info ? {
            root: { backgroundColor: 'var(--pfx-theme-smoke)' }
          } : null}
        >
          {props.alert.message}{' '}
          {props.alert.action && props.alert.action.link && this.getLink()}
        </MessageBar>
      );
    } else {
      return null;
    }
  }
}

export default AlertBar;
