export const SET_UNAUTHORIZED = 'UNAUTHORIZED';
export const INITIALIZING = 'INITIALIZING';
// export const INITIALIZING_STATUS = 'INITIALIZING_STATUS';
export const INITIALIZING_DONE = 'INITIALIZING_DONE';

export const FORCE_RELOAD = 'FORCE_RELOAD';
export const SET_SERVER_STATE = 'SET_SERVER_STATE';

export const LOAD_WEBS = 'LOAD_WEBS';
export const LOAD_WEBS_DONE = 'LOAD_WEBS_DONE';

export const SET_LOGMODE = 'SET_LOGMODE';

export const LOAD_WEB = 'LOAD_WEB';
export const LOAD_WEB_DONE = 'LOAD_WEB_DONE';

export const LOAD_FORMS = 'LOAD_FORMS';
export const LOAD_FORMS_DONE = 'LOAD_FORMS_DONE';

export const LOAD_FORM = 'LOAD_FORM';
export const LOAD_FORM_DONE = 'LOAD_FORM_DONE';

export const LOAD_FORM_ALERTS = 'LOAD_FORM_ALERTS';
export const LOAD_FORM_ALERTS_DONE = 'LOAD_FORM_ALERTS_DONE';

export const LOAD_PRODUCT_STATUS = 'LOAD_PRODUCT_STATUS';
export const LOAD_PRODUCT_STATUS_DONE = 'LOAD_PRODUCT_STATUS_DONE';

export const SET_CONTEXTFORM = 'SET_CONTEXTFORM';
export const SET_CONTEXTFORM_DONE = 'SET_CONTEXTFORM_DONE';

export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORM_DONE = 'DELETE_FORM_DONE';

export const LOAD_MENUS = 'LOAD_MENUS';
export const LOAD_MENUS_DONE = 'LOAD_MENUS_DONE';

export const LOAD_LOCALIZATION = 'LOAD_LOCALIZATION';
export const LOAD_LOCALIZATION_DONE = 'LOAD_LOCALIZATION_DONE';

export const LOAD_FORMTYPES = 'LOAD_FORMTYPES';
export const LOAD_FORMTYPES_DONE = 'LOAD_FORMTYPES_DONE';

export const LOAD_ASSETTYPES = 'LOAD_ASSETTYPES';
export const LOAD_ASSETTYPES_DONE = 'LOAD_ASSETTYPES_DONE';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS_DONE = 'LOAD_NOTIFICATIONS_DONE';

export const SET_CONTEXTWEB = 'SET_CONTEXTWEB';
export const SET_CONTEXTWEB_DONE = 'SET_CONTEXTWEB_DONE';

export const SET_CONTEXTUSER = 'SET_CONTEXTUSER';

export const SET_ALERT = 'SET_ALERT';
export const HELP_VISIBILITY_CHANGE = 'HELP_VISIBILITY_CHANGE';
export const ALERTS_VISIBILITY_CHANGE = 'ALERTS_VISIBILITY_CHANGE';

export const LAYOUT_CHANGE = 'LAYOUT_CHANGE';
export const MASTHEAD_VISIBILITY_CHANGE = 'MASTHEAD_VISIBILITY_CHANGE';
export const MENU_COLLAPSED_CHANGE = 'MENU_COLLAPSED_CHANGE';
export const TOPMENU_VISIBILITY_CHANGE = 'TOPMENU_VISIBILITY_CHANGE';
export const CALLOUT_CHANGE = 'CALLOUT_CHANGE';
export const DIALOG_CHANGE = 'DIALOG_CHANGE';

export const UNSAVED_CHANGE = 'UNSAVED_CHANGE';
export const UNSAVED_CLEAR = 'UNSAVED_CLEAR';

export const PROCESSING_CHANGE = 'PROCESSING_CHANGE';
export const PROCESSING_CLEAR = 'PROCESSING_CLEAR';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATEPATH = 'UPDATEPATH';
export const UPDATESYSINFO = 'UPDATESYSINFO';

export const APPEND_ERROR = 'APPEND_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// export const IS_LOGGING_IN = 'IS_LOGGING_IN';
// export const IS_LOGGING_OUT = 'IS_LOGGING_OUT';
// export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
// export { AAD_LOGIN_SUCCESS, AAD_LOGOUT_SUCCESS } from 'react-aad-msal';

export const SET_INLINE_PANEL_ITEM = 'SET_INLINE_PANEL_ITEM';

export const OpenPanelActionType = "OpenPanel"
export const ClosePanelActionType = "ClosePanel"

export const SET_FILTER = 'SET_FILTER';

export { LOCATION_CHANGE } from 'connected-react-router';
