import * as React from 'react';
import { PFXWeb } from 'src/api/webApi';
import FormAlert from 'src/components/Forms/FormAlert';
import {
  updateFormAlerts,
  updateProductStatus
} from 'src/redux/actionCreators';
import { PFXFormAlertSummary } from 'src/api/formAlerts';
import { PFXFormTypes } from 'src/api/formsApi';
// import ProductStatusGraph, {
//   GraphDataSeries
// } from 'src/components/Misc/ProductStatusGraph';
// import { PFXProductStatus } from 'src/api/productStatusApi';

import { PFXUser } from 'src/models';

import {
  PFXLocalizedTerms,
  // PFXMessages,
  PFXShared
} from 'src/api/localizationApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { AppType, ToolType } from '../CoreFlow/CoreFlow';

class Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  lcid: number;
  formAlerts: PFXFormAlertSummary[];
  /* productStatus: PFXProductStatus[]; */
  terms: PFXLocalizedTerms;
  formTypes: PFXFormTypes;
}

interface State {
  Products?: PFXFormAlertSummary;
  Milestones?: PFXFormAlertSummary;
  Risks?: PFXFormAlertSummary;
  ProjectMembers?: PFXFormAlertSummary;
  /* productStatusData?: GraphDataSeries[]; */
  LeftZoneActive?: boolean;
  MiddleZoneActive?: boolean;
  RightZoneActive?: boolean;
}

class ProjectHomePage extends React.Component<Props, State> {
  state: State = {};

  constructor(props: Props) {
    super(props);

    this._fetchItems = this._fetchItems.bind(this);

    this.state = {
      LeftZoneActive: this.props.contextWeb.activeZones.includes(
        'PFX_Project_Home_Left'
      ),
      MiddleZoneActive: this.props.contextWeb.activeZones.includes(
        'PFX_Project_Home_Middle'
      ),
      RightZoneActive: this.props.contextWeb.activeZones.includes(
        'PFX_Project_Home_Right'
      ),

      ...this._prepareAlerts(),
      // ...this._prepareStatus()

    };

  }

  /*
    shouldComponentUpdate(nextProps: Props): boolean {
      let update = false;
      for (const key in nextProps) {
        if (nextProps[key] !== this.props[key]) {
          update = true;
  
          // Easy debugging of "unsolicited" prop changes:
  
          if (update) {
            console.debug(
              'Project Home Update, Prop "' + key + '" Changed (old/new)',
              this.props[key],
              nextProps[key]
            );
          }
  
        }
      }
      return update;
    }
  */

  componentDidMount() {
    this._fetchItems();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.contextWeb !== prevProps.contextWeb) {

      this.setState({
        LeftZoneActive: this.props.contextWeb.activeZones.includes(
          'PFX_Project_Home_Left'
        ),
        MiddleZoneActive: this.props.contextWeb.activeZones.includes(
          'PFX_Project_Home_Middle'
        ),
        RightZoneActive: this.props.contextWeb.activeZones.includes(
          'PFX_Project_Home_Right'
        )
      });

      this._fetchItems();
    }

    if (this.props.formAlerts !== prevProps.formAlerts) {
      this.setState({
        ...this._prepareAlerts()
      });
    }

    /* obsolete #8983 */
    /* 
    if (this.props.productStatus !== prevProps.productStatus) {
      this.setState({
        ...this._prepareStatus()
      });
    }
    */
  }

  render() {
    document.body.classList.add('cards-pf');
    const minHeight = '390px';
    return (
      <>
        {this.props.contextWeb && (
          <div className="container-fluid container-cards-pf secondary-visible-pf">

            <div className="row row-cards-pf" key="row-issues">
              {this.state.Products && (
                <div
                  className="col-xs-12 col-sm-6 col-md-3"
                  key="product-issues"
                >
                  <FormAlert
                    title={this.props.terms[PFXShared.PRODUCTS]}
                    formAlert={this.state.Products}
                    contextUser={this.props.contextUser}
                    contextWeb={this.props.contextWeb}
                    lcid={this.props.lcid}
                    formTypes={this.props.formTypes}
                    terms={this.props.terms}
                  />
                </div>
              )}
              {this.state.Milestones && (
                <div
                  className="col-xs-12 col-sm-6 col-md-3"
                  key="milestone-issues"
                >
                  <FormAlert
                    title={this.props.terms[PFXShared.MILESTONES]}
                    formAlert={this.state.Milestones}
                    contextUser={this.props.contextUser}
                    contextWeb={this.props.contextWeb}
                    lcid={this.props.lcid}
                    formTypes={this.props.formTypes}
                    terms={this.props.terms}
                  />
                </div>
              )}
              {this.state.ProjectMembers && (
                <div
                  className="col-xs-12 col-sm-6 col-md-3"
                  key="resource-issues"
                >
                  <FormAlert
                    title={this.props.terms[PFXShared.RESOURCES]}
                    formAlert={this.state.ProjectMembers}
                    contextUser={this.props.contextUser}
                    contextWeb={this.props.contextWeb}
                    lcid={this.props.lcid}
                    formTypes={this.props.formTypes}
                    terms={this.props.terms}
                  />
                </div>
              )}
              {this.state.Risks && (
                <div className="col-xs-12 col-sm-6 col-md-3" key="risk-issues">
                  <FormAlert
                    title={this.props.terms[PFXShared.RISKS]}
                    formAlert={this.state.Risks}
                    contextUser={this.props.contextUser}
                    contextWeb={this.props.contextWeb}
                    lcid={this.props.lcid}
                    formTypes={this.props.formTypes}
                    terms={this.props.terms}
                  />
                </div>
              )}
            </div>

            {/* obsolete #8983 *//* <div className="row row-cards-pf" key="row-graph1">
              <div className="col-xs-12 col-sm-12">
                {this.state.productStatusData && (
                  <ProductStatusGraph
                    title={this.props.terms[PFXShared.PRODUCTS]}
                    iconClass={
                      this.props.formTypes.PFX_Product.iconClass
                    }
                    data={this.state.productStatusData}
                  />
                )}
              </div>
            </div> */}

            <div className="row row-cards-pf" key="row-info">
              {this.state.LeftZoneActive && (
                <div className="col-md-4">
                  <div
                    className="card-pf card-pf-accented pfx-projecthome-summary"
                    style={{ minHeight }}
                  >
                    <CoreFlowAppBase
                      contextWeb={this.props.contextWeb}
                      contextUser={this.props.contextUser}
                      lcid={this.props.lcid}
                      appName={AppType.Composition}
                      toolName={ToolType.Composition}
                      toolId={'PFX_Project_Home_Left'}
                      fullPageLayout={true}
                      disableUnsavedCheck={true}
                    />
                  </div>
                </div>
              )}
              {this.state.MiddleZoneActive && (
                <div className="col-md-4">
                  <div
                    className="card-pf card-pf-accented pfx-projecthome-summary"
                    style={{ minHeight }}
                  >
                    <CoreFlowAppBase
                      contextWeb={this.props.contextWeb}
                      contextUser={this.props.contextUser}
                      lcid={this.props.lcid}
                      appName={AppType.Composition}
                      toolName={ToolType.Composition}
                      toolId={'PFX_Project_Home_Middle'}
                      fullPageLayout={true}
                      disableUnsavedCheck={true}
                    />
                  </div>
                </div>
              )}
              {this.state.RightZoneActive && (
                <div className="col-md-4">
                  <div
                    className="card-pf card-pf-accented pfx-projecthome-summary"
                    style={{ minHeight }}
                  >
                    <CoreFlowAppBase
                      contextWeb={this.props.contextWeb}
                      contextUser={this.props.contextUser}
                      lcid={this.props.lcid}
                      appName={AppType.Composition}
                      toolName={ToolType.Composition}
                      toolId={'PFX_Project_Home_Right'}
                      fullPageLayout={true}
                      disableUnsavedCheck={true}
                    />
                  </div>
                </div>
              )}
            </div>

          </div>
        )}
      </>
    );
  }

  private async _fetchItems() {
    // Update Data
    if (this.props.contextWeb) {
      await Promise.all([
        window.store.dispatch(updateFormAlerts([this.props.contextWeb])),
        window.store.dispatch(updateProductStatus(this.props.contextWeb))
      ]);
    }
  }

  /* obsolete #8983 */
  // private _prepareStatus = (): State => {
  //   if (this.props.productStatus && this.props.contextWeb) {
  //     const productStatus = this.props.productStatus.filter(
  //       status => status.ProjectId === this.props.contextWeb.guid
  //     );
  //     if (productStatus.length > 0) {
  //       return {
  //         productStatusData: productStatus.map(ps => {
  //           return {
  //             Title: ps.ProductTitle,
  //             Status: ps.ProductStatus,
  //             Min: 0,
  //             Max: 1,
  //             Value: ps.Progress,
  //             ProgressDescription: this.props.terms[
  //               PFXMessages.HOURSLEFTOFEST
  //             ].format(String(ps.PFX_HoursLeft), String(ps.PFX_HoursEstimate)),
  //             Color: ps.ProgressColor
  //           };
  //         })
  //       };
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  private _prepareAlerts = (): State => {
    if (this.props.formAlerts && this.props.contextWeb) {
      const projectAlerts = this.props.formAlerts.filter(
        alert => alert.ProjectGuid === this.props.contextWeb.guid
      );
      if (projectAlerts.length > 0) {
        return {
          Products: projectAlerts.filter(
            alert => alert.FormType === 'PFX_Product'
          )[0],
          Milestones: projectAlerts.filter(
            alert => alert.FormType === 'PFX_Milestone'
          )[0],
          Risks: projectAlerts.filter(
            alert => alert.FormType === 'PFX_Risk'
          )[0],
          ProjectMembers: projectAlerts.filter(
            alert => alert.FormType === 'PFX_Resource'
          )[0]
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}

export default ProjectHomePage;
/*
const mapStateToProps = (state: RoutedAppState) => {
  return { ...state.appReducer };
};

export default connect<any, Props, any>(mapStateToProps)(ProjectHomePage);
*/