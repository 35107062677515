import * as React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { CLEAR_ERRORS } from 'src/redux/actionConstants';

interface Props {
  errors: number;
}

// #7108 
export const BugMonitor: React.FunctionComponent<Props> = props => {

  if ((props.errors ?? 0) === 0) {
    // hide if nothing is wrong
    return null;
  } else {
    const iconElement = (props.errors ?? 0) === 0 ?
      (
        <Icon
          iconName="Bug"
          styles={{ root: { color: 'var(--pfx-theme-green)' } }}
        />
      ) : (
        <Icon
          iconName="BugSolid"
          styles={{ root: { color: 'var(--pfx-theme-red)' } }}
          className={'blink'}
        />
      );

    return (
      <div className="nav navbar-left pfx-bugbar">
        {iconElement}
        <span onClick={() => window.store.dispatch({ type: CLEAR_ERRORS })}>&nbsp;Errors: {props.errors ?? 0}&nbsp;</span>
      </div>
    );
  }
};

export default BugMonitor;
