import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXWeb } from './webApi';
import { PFXHelpers } from 'src/utils/PFXHelpers';

const QUERY_PFX_O365DATA = '/PFX_O365_Assets/data';
export const QUERY_PFX_ASSETTYPES = '/PFX_AssetTypes/data';

// http://flowit-pfx.flowit.net/_layouts/15/FlowIT/webservice/CoreFlowRestApi.svc//PFX_O365Items/data?mode=simple&consumerfilter={%22webs%22:%2266c01f8d-2ec0-4b78-915a-2e3f99b8b921%22,%22types%22:%22PFX_TASK%22}

export interface PFXO365AssetType2 {
  internalValue: string;
  displayName: string;
  iconClass: string;
}

export interface PFXAssetTypes {
  [internalValue: string]: PFXO365AssetType2;
}

// Enum PFX_O365_AssetType
/*
export enum PFXO365AssetType {
  GROUP = 'GROUP',
  SITE = 'SITE',
  PLAN = 'PLAN',
  TEAM = 'TEAM',
  TASKLIST = 'TASKLIST',
  DOCLIST = 'DOCLIST'
}

export const PFXAssetTypeIconClass = {
  [PFXO365AssetType.GROUP]: 'ms-Icon ms-Icon--Group',
  [PFXO365AssetType.SITE]: 'ms-Icon ms-Icon--SharepointLogoInverse',
  [PFXO365AssetType.TEAM]: 'ms-Icon ms-Icon--TeamsLogoInverse',
  [PFXO365AssetType.PLAN]: 'ms-Icon ms-Icon--PlannerLogo',
  [PFXO365AssetType.TASKLIST]: 'ms-Icon ms-Icon--CheckList',
  [PFXO365AssetType.DOCLIST]: 'ms-Icon ms-Icon--DocLibrary' // ms-Icon--OneDriveFolder16
};
*/

export interface PFXO365Asset {
  id: string;
  displayName: string;
  assetType: PFXAssetTypes;
  /* _EditRowId?: string; */
  description?: string;
  webUrl?: string;
  created?: Date;
  modified?: Date;

  WebGuid?: string;
  FormGuid?: string;
  FormType?: string;
}

export const O365Api = {
  fetchAssetTypes(): Promise<PFXAssetTypes | null> {
    const RequestUrl = COREFLOW_API + QUERY_PFX_ASSETTYPES;

    return jsonRequest(RequestUrl, null, { mode: 'simple' }, 'GET')
      .then((data: PFXO365AssetType2[]) => {
        let result: PFXAssetTypes = {};
        data.map((f) => (result[f.internalValue] = f));
        return result;
      })
      .catch((error: Error) => {
        console.error('O365Api.fetchAssetTypes ERROR', RequestUrl, error);
        return null;
      });
  },

  fetchData(
    types: PFXAssetTypes[],
    assetIds?: string[],
    filters?: any[],
    webs?: PFXWeb[]
  ): Promise<PFXO365Asset[] | null> {
    let consumerfilters: string[] = [];
    let consumerfilter: string = null;

    if (filters) {
      const filterKeys = filters.keys;

      filters.forEach((value: any, index: number) => {
        consumerfilters.push(
          '"' + filterKeys[index] + '":"' + encodeURI(value.toString()) + '"'
        );
      });
    }

    if (webs && webs.length > 0) {
      consumerfilters.push(
        '"webs":"' + encodeURI(webs.map((web) => web.guid).join(';')) + '"'
      );
    }

    if (types && types.length > 0) {
      consumerfilters.push(
        '"types":"' + encodeURI(types.map((value) => value).join(';')) + '"'
      );
    }

    if (assetIds && assetIds.length > 0) {
      consumerfilters.push('"assets":"' + encodeURI(assetIds.join(';')) + '"');
    }

    consumerfilter = '{' + consumerfilters.join(',') + '}';

    return jsonRequest(
      COREFLOW_API + QUERY_PFX_O365DATA,
      null,
      { mode: 'simple', consumerfilter },
      'GET'
    )
      .then((data): PFXO365Asset[] => {
        if (data) {
          return data.map((item: PFXO365Asset) => {
            return {
              ...item,
              displayName: PFXHelpers.htmlDecode(item.displayName),
              description: PFXHelpers.htmlDecode(item.description),
            };
          });
        } else {
          return [];
        }
      })
      .catch((error: Error) => {
        console.error(
          'O365Api.fetchData ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_O365DATA },
          error
        );
        return null;
      });
  },
};
