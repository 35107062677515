import * as React from 'react';
import { PFXLocalizedTerms, PFXShared, PFXHelp } from 'src/api/localizationApi';
import ReactHtmlParser from 'react-html-parser';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { devMode, FLOWIT_LOGO } from 'src/Root';
import { PFXUser } from 'src/models';
import { PFXWeb } from 'src/api/webApi';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { setAlert, clearAlert } from 'src/redux/actionCreators';
import AlertBar, { PFXAlert } from './Alert';
import { PFXSystemInfo } from 'src/api/systemApi';

interface Props {
  contextUser: PFXUser;
  contextWeb: PFXWeb;
  terms: PFXLocalizedTerms;
  alert: PFXAlert;
  systemInfo: PFXSystemInfo;
}

export const AboutPage: React.FunctionComponent<Props> = props => {

  const buildDate: Date = new Date(
    parseInt(`${process.env.REACT_APP_BUILD_DATE}`, 10)
  );

  return (
    <div
      style={{
        marginTop: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '8px',
        textAlign: 'center'
      }}
    >
      {props.alert && props.alert.dialogOnly && (
        <AlertBar alert={props.alert} />
      )}
      <h3>
        {props.terms[PFXShared.ABOUT] + ' ' + window.ProjectFlowX.solutionName}
      </h3>
      {ReactHtmlParser(props.terms[PFXHelp.GLOBAL_ABOUT])}
      <hr />
      <p><b>{props.terms[PFXShared.VERSION]}</b><br />{PFXHelpers.getVersion()}</p>
      <p><b>{props.terms[PFXShared.BUILT]}</b><br />{buildDate.toLocaleString()}</p>
      <hr />
      {(devMode || (location.href.indexOf('flowit') !== -1)) && (
        <>
          <h4><b>Server Side Info</b></h4>
          <p><b>PF365 Context Version</b><br />{props.systemInfo.PFXVersion}</p>
          <p><b>CoreFlow Version</b><br />{props.systemInfo.CFVersion}</p>
          <p><b>IIS Process Age</b><br />{props.systemInfo.CFProcessAge} H:M</p>
          <p><b>IIS Process Memory Usage</b><br />{props.systemInfo.CFMem} MB</p>
          <hr />
        </>
      )}
      <p><b>{props.terms[PFXShared.USERNAME]}</b><br />{props.contextUser.userName}</p>
      <p><b>Web ID</b><br />
        <input
          readOnly
          onClick={(e) => {
            const target = e.currentTarget;
            target.select();
            target.setSelectionRange(0, 9999);
            document.execCommand('copy');
            window.store.dispatch(
              setAlert({
                type: MessageBarType.info,
                dialogOnly: true,
                message: props.terms[PFXShared.TEXTCOPIED]
              })
            );
            setTimeout(() => window.store.dispatch(clearAlert()), 3000);
          }}
          value={props.contextWeb.guid}
        /></p>
      <hr />

      <div>
        <a href="https://www.projectflow.dk/" target="_blank">
          <img src={FLOWIT_LOGO} style={{ zoom: '70%' }} />
        </a>
        <p>©{new Date().getFullYear()} - ProjectFlow A/S</p>
      </div>
    </div>
  );
};

export default AboutPage;
