import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
/* import { PFXWeb } from './webApi'; */

interface PFXLocalizationItem {
  name: string;
  value: string;
}

export interface PFXLocalizedTerms {
  [key: string]: string;
}

export const localizationApi = {
  fetchData(/*contextWeb: PFXWeb*/): Promise<PFXLocalizedTerms | null> {
    const QUERY = '/PFX_Localization/data';

    let consumerfilters: string[] = [];

    let consumerfilter: string = null;
    consumerfilter = '{' + consumerfilters.join(',') + '}';

    return jsonRequest(
      COREFLOW_API + QUERY,
      null /*contextWeb*/,
      { mode: 'simple', consumerfilter },
      'GET'
    )
      .then((data: PFXLocalizationItem[]): PFXLocalizedTerms => {
        if (data) {
          let result = {};
          for (const item of data) {
            // result[item.name.replace(/::/g, '_')] = item.value;
            result[item.name] = item.value;
          }
          // slower: data.forEach(item => result[item.name] = item.value);
          return result;
        } else {
          return {};
        }
      })
      .catch((error: Error) => {
        console.error(
          'localizationApi.fetchData ERROR',
          { RequestUrl: COREFLOW_API + QUERY },
          error
        );
        return null;
      });
  },
};

// generated using: systemweb.aspx?tool=CFFunction&cf_id=PFX_TS_Enum_PFXTerms&PageId=3813f83b-a9fa-441a-aa6c-673aa410cbcf

export enum PFXHelp {
  PFX_HELP = 'PFX::Help',
  ADDONTOOLSFROMBASICLEVEL = 'PFX::Help::AddOnToolsFromBasicLevel',
  ADDONTOOLSFROMSIMPLELEVEL = 'PFX::Help::AddOnToolsFromSimpleLevel',
  DEPARTMENTBENEFITS_TITLE = 'PFX::Help::DepartmentBenefits_Title',
  FILTERS = 'PFX::Help::Filters',
  FILTERS_PERIOD = 'PFX::Help::Filters::Period',
  GLOBAL = 'PFX::Help::Global',
  GLOBAL_ABOUT = 'PFX::Help::Global::About',
  GLOBAL_GLOBAL = 'PFX::Help::Global::Global',
  PFX_PLAN_OWERVIEW_PRODUCTS_TITLE = 'PFX::Help::PFX_Plan_Owerview_Products_Title',
  PFX_PLAN_OWERVIEW_RESOURCES_TITLE = 'PFX::Help::PFX_Plan_Owerview_Resources_Title',
  PFX_PMO = 'PFX::Help::PFX_PMO',
  PFX_PMO_ADMINISTRATION = 'PFX::Help::PFX_PMO::Administration',
  PFX_PMO_ALLOCATEDECONOMY = 'PFX::Help::PFX_PMO::AllocatedEconomy',
  PFX_PMO_ALLOCATEDRESSOURCES = 'PFX::Help::PFX_PMO::AllocatedRessources',
  PFX_PMO_ARCHIVEDPROJECTS = 'PFX::Help::PFX_PMO::ArchivedProjects',
  PFX_PMO_DEPARTMENTHOURS = 'PFX::Help::PFX_PMO::DepartmentHours',
  PFX_PMO_DOCUMENTTEMPLATES = 'PFX::Help::PFX_PMO::DocumentTemplates',
  PFX_PMO_EDITPROJECTS = 'PFX::Help::PFX_PMO::EditProjects',
  PFX_PMO_PROJECTPRODUCTS = 'PFX::Help::PFX_PMO::ProjectProducts',
  PFX_PMO_PROJECTS = 'PFX::Help::PFX_PMO::Projects',
  PFX_PMO_PROJECTTYPES = 'PFX::Help::PFX_PMO::ProjectTypes',
  PFX_PMO_ROLES = 'PFX::Help::PFX_PMO::Roles',
  PFX_PMO_USERS = 'PFX::Help::PFX_PMO::Users',
  PFX_PORTAL = 'PFX::Help::PFX_Portal',
  PFX_PORTAL_ALLOCATEDECONOMY = 'PFX::Help::PFX_Portal::AllocatedEconomy',
  PFX_PORTAL_ALLOCATEDRESSOURCES = 'PFX::Help::PFX_Portal::AllocatedRessources',
  PFX_PORTAL_PROJECTALERTS = 'PFX::Help::PFX_Portal::ProjectAlerts',
  PFX_PORTAL_PROJECTPRODUCTS = 'PFX::Help::PFX_Portal::ProjectProducts',
  PFX_PORTAL_PROJECTS = 'PFX::Help::PFX_Portal::Projects',
  PFX_PROJECT = 'PFX::Help::PFX_Project',
  PFX_PROJECT_ADMINISTRATION = 'PFX::Help::PFX_Project::Administration',
  PFX_PROJECT_ASSETS = 'PFX::Help::PFX_Project::Assets',
  PFX_PROJECT_DOCUMENTS = 'PFX::Help::PFX_Project::Documents',
  PFX_PROJECT_MILESTONES = 'PFX::Help::PFX_Project::Milestones',
  PFX_PROJECT_OVERVIEW = 'PFX::Help::PFX_Project::Overview',
  PFX_PROJECT_PLAN = 'PFX::Help::PFX_Project::Plan',
  PFX_PROJECT_PLANBASIC = 'PFX::Help::PFX_Project::PlanBasic',
  PFX_PROJECT_PLANNING = 'PFX::Help::PFX_Project::Planning',
  PFX_PROJECT_PRODUCTS = 'PFX::Help::PFX_Project::Products',
  PFX_PROJECT_PROGRESS = 'PFX::Help::PFX_Project::Progress',
  PFX_PROJECT_RESOURCES = 'PFX::Help::PFX_Project::Resources',
  PFX_PROJECT_RISKS = 'PFX::Help::PFX_Project::Risks',
  PFX_PROJECT_STATUSREPORTS = 'PFX::Help::PFX_Project::StatusReports',
  PFX_PROJECT_TASKS = 'PFX::Help::PFX_Project::Tasks',
  PFX_PROJECT_TASKSGANTT = 'PFX::Help::PFX_Project::TasksGantt',
  PFX_PROJECTPLAN_OVERVIEW_TITLE = 'PFX::Help::PFX_ProjectPlan_Overview_Title',
  PROJECTTYPES_MANDATORYROLES = 'PFX::Help::ProjectTypes_MandatoryRoles',
  PROJECTTYPES_TOLERANCES = 'PFX::Help::ProjectTypes_Tolerances',
  PROJECTTYPES_TOLERANCES_GREENDIFFLIMITLOWER = 'PFX::Help::ProjectTypes_Tolerances::GreenDiffLimitLower',
  PROJECTTYPES_TOLERANCES_GREENDIFFLIMITUPPER = 'PFX::Help::ProjectTypes_Tolerances::GreenDiffLimitUpper',
  PROJECTTYPES_TOLERANCES_YELLOWDIFFLIMITLOWER = 'PFX::Help::ProjectTypes_Tolerances::YellowDiffLimitLower',
  PROJECTTYPES_TOLERANCES_YELLOWDIFFLIMITUPPER = 'PFX::Help::ProjectTypes_Tolerances::YellowDiffLimitUpper',
  PROJECTTYPES_UNIQUEROLES = 'PFX::Help::ProjectTypes_UniqueRoles',
  USEALLMANAGEMENTTOOLSTOOLTIP = 'PFX::Help::UseAllManagementToolsToolTip',
  USER = 'PFX::Help::User',
  USER_PREFERENCES = 'PFX::Help::User::Preferences',
}

export enum PFXMessages {
  PFX_MESSAGES = 'PFX::Messages',
  ADMINARCHIVED = 'PFX::Messages::AdminArchived',
  CLICKTORELOAD = 'PFX::Messages::ClickToReload',
  COMPNOTFOUND = 'PFX::Messages::CompNotFound',
  COMPNOTFOUNDDETAILS = 'PFX::Messages::CompNotFoundDetails',
  FORMBEINGCREATED = 'PFX::Messages::FormBeingCreated',
  FORMCREATED = 'PFX::Messages::FormCreated',
  FORMCREATIONERROR = 'PFX::Messages::FormCreationError',
  FORMGOTO = 'PFX::Messages::FormGoTo',
  HOURSLEFTOFEST = 'PFX::Messages::HoursLeftOfEst',
  NEWVERSION = 'PFX::Messages::NewVersion',
  NEWCONTENT = 'PFX::Messages::NewContent',
  NEWVERSIONSERVER = 'PFX::Messages::NewVersionServer',
  NODOCUMENTATION = 'PFX::Messages::NoDocumentation',
  NOFORMSFOUND = 'PFX::Messages::NoFormsFound',
  PAGENOTFOUND = 'PFX::Messages::PageNotFound',
  PAGENOTFOUNDDETAILS = 'PFX::Messages::PageNotFoundDetails',
  PAGENOTFOUNDLINK = 'PFX::Messages::PageNotFoundLink',
  PLANEMPTY = 'PFX::Messages::PlanEmpty',
  PLANEMPTYDETAILS = 'PFX::Messages::PlanEmptyDetails',
  PMREMINDEREMAIL = 'PFX::Messages::PMReminderEmail',
  PROJECTBEINGCREATED = 'PFX::Messages::ProjectBeingCreated',
  PROJECTCREATED = 'PFX::Messages::ProjectCreated',
  PROJECTCREATIONERROR = 'PFX::Messages::ProjectCreationError',
  PROJECTGOTO = 'PFX::Messages::ProjectGoTo',
  PROJISARCHIVEDNOEDIT = 'PFX::Messages::ProjIsArchivedNoEdit',
  PROJISARCHIVEDNONEW = 'PFX::Messages::ProjIsArchivedNoNew',
  SERVEROFFLINE = 'PFX::Messages::ServerOffline',
  VIEWALL = 'PFX::Messages::ViewAll',
  NOHITS = 'PFX::Messages::NoHits',
  NOASSETS = 'PFX::Messages::NoAssets',
}

export enum PFXNotifications {
  PFX_NOTIFICATIONS = 'PFX::Notifications',
  CLEARALL = 'PFX::Notifications::ClearAll',
  DELETENOTIFICATION = 'PFX::Notifications::DeleteNotification',
  EMPTYSTATE = 'PFX::Notifications::EmptyState',
  PANELTITLE = 'PFX::Notifications::PanelTitle',
  READALL = 'PFX::Notifications::ReadAll',
  UNREADNOTIFICATION = 'PFX::Notifications::UnreadNotification',
  UNREADNOTIFICATIONS = 'PFX::Notifications::UnreadNotifications',
}

export enum PFXShared {
  PFX_SHARED = 'PFX::Shared',
  ABOUT = 'PFX::Shared::About',
  ACCESSMETHOD = 'PFX::Shared::AccessMethod',
  ACTUALBEHINDHOURS = 'PFX::Shared::ActualBehindHours',
  ACTUALCOMPLETION = 'PFX::Shared::ActualCompletion',
  ACTUALDELIVEREDHOURS = 'PFX::Shared::ActualDeliveredHours',
  ACTUALHOURSBEHIND = 'PFX::Shared::ActualHoursBehind',
  ACTUALTILLDATE = 'PFX::Shared::ActualTillDate',
  ACTUALTIMELEFT = 'PFX::Shared::ActualTimeLeft',
  ACTUALTIMELEFTP = 'PFX::Shared::ActualTimeLeftP',
  ADMINISTRATION = 'PFX::Shared::Administration',
  ADMINISTRATORS = 'PFX::Shared::Administrators',
  ALERT = 'PFX::Shared::Alert',
  ALERTERRORS = 'PFX::Shared::AlertErrors',
  ALERTME_DAYSINWEEK = 'PFX::Shared::AlertMe_DaysInWeek',
  ALERTOKS = 'PFX::Shared::AlertOks',
  ALERTREMINDERS = 'PFX::Shared::AlertReminders',
  ALERTS = 'PFX::Shared::Alerts',
  ALERTSETTING_DEADLINE = 'PFX::Shared::AlertSetting_DeadLine',
  ALERTSETTING_MEDAYSBEFORE = 'PFX::Shared::AlertSetting_MeDaysBefore',
  ALERTSETTING_NOTUPDATEDIN = 'PFX::Shared::AlertSetting_NotUpdatedIn',
  ALERTSETTING_NOTUPDATEDINDAYS = 'PFX::Shared::AlertSetting_NotUpdatedInDays',
  ALERTSETTING_OVERDUE = 'PFX::Shared::AlertSetting_OverDue',
  ALERTSETTING_PROGRESSBEHIND = 'PFX::Shared::AlertSetting_ProgressBehind',
  ALERTSETTING_STARTDATE = 'PFX::Shared::AlertSetting_StartDate',
  ALERTWARNINGS = 'PFX::Shared::AlertWarnings',
  ALLOCATED = 'PFX::Shared::Allocated',
  ALLOCATEDECONOMY = 'PFX::Shared::AllocatedEconomy',
  ALLOCATEDHOURS = 'PFX::Shared::AllocatedHours',
  ALLOCATEDRESSOURCES = 'PFX::Shared::AllocatedRessources',
  ALLUSERSREAD = 'PFX::Shared::AllUsersRead',
  APPLIESTO = 'PFX::Shared::AppliesTo',
  ARCHIVED = 'PFX::Shared::Archived',
  ARCHIVEDBY = 'PFX::Shared::ArchivedBy',
  ARCHIVEDDATE = 'PFX::Shared::ArchivedDate',
  ARCHIVEDPROJECTS = 'PFX::Shared::ArchivedProjects',
  ASSETS = 'PFX::Shared::Assets',
  ASSIGNEDTO = 'PFX::Shared::AssignedTo',
  ATTRIBUTES = 'PFX::Shared::Attributes',
  BACK = 'PFX::Shared::Back',
  BENEFIT = 'PFX::Shared::Benefit',
  BUDGET = 'PFX::Shared::Budget',
  BUILD = 'PFX::Shared::Build',
  BUILT = 'PFX::Shared::Built',
  BUILDINHELP = 'PFX::Shared::BuildInHelp',
  CANCEL = 'PFX::Shared::Cancel',
  CHANGED = 'PFX::Shared::Changed',
  CHANGEDBY = 'PFX::Shared::ChangedBy',
  CHANGELOG = 'PFX::Shared::ChangeLog',
  CLASSIFICATION = 'PFX::Shared::Classification',
  CLICKHERE = 'PFX::Shared::ClickHere',
  CLOSE = 'PFX::Shared::Close',
  COMPLETED = 'PFX::Shared::Completed',
  COMPLETEDWORK = 'PFX::Shared::CompletedWork',
  COMPLETIONPERCENT = 'PFX::Shared::CompletionPercent',
  COMPUTEDFIELDS = 'PFX::Shared::ComputedFields',
  TEXTCOPIED = 'PFX::Shared::TextCopied',
  CREATE = 'PFX::Shared::Create',
  CREATEANOTHER = 'PFX::Shared::CreateAnother',
  CREATED = 'PFX::Shared::Created',
  CREATEDBY = 'PFX::Shared::CreatedBy',
  CURRENCY = 'PFX::Shared::Currency',
  CUSTOMER = 'PFX::Shared::Customer',
  CUSTOMHELP = 'PFX::Shared::CustomHelp',
  CUSTOMERVALUE = 'PFX::Shared::CustomerValue',
  DATES = 'PFX::Shared::Dates',
  DAYSSINCESTART = 'PFX::Shared::DaysSinceStart',
  DEFAULTSOLUTIONLANGUAGE = 'PFX::Shared::DefaultSolutionLanguage',
  DELETE = 'PFX::Shared::Delete',
  DELIVERED = 'PFX::Shared::Delivered',
  DELIVEREDHOURS = 'PFX::Shared::DeliveredHours',
  DEPARTMENT = 'PFX::Shared::Department',
  DEPARTMENTHOURS = 'PFX::Shared::DepartmentHours',
  DEPARTMENTOWNSPROJECT = 'PFX::Shared::DepartmentOwnsProject',
  DEPARTMENTS = 'PFX::Shared::Departments',
  DESCRIPTION = 'PFX::Shared::Description',
  DETAILS = 'PFX::Shared::Details',
  DEVELOPMENT = 'PFX::Shared::Development',
  DIRECTACCESS = 'PFX::Shared::DirectAccess',
  DOCUMENTS = 'PFX::Shared::Documents',
  DOCUMENTTEMPLATE = 'PFX::Shared::DocumentTemplate',
  DOCUMENTTEMPLATES = 'PFX::Shared::DocumentTemplates',
  DOCUMENTURL = 'PFX::Shared::DocumentUrl',
  DONE = 'PFX::Shared::Done',
  DRAFT = 'PFX::Shared::Draft',
  DROPPED = 'PFX::Shared::Dropped',
  DUEDATE = 'PFX::Shared::DueDate',
  DUEIN = 'PFX::Shared::DueIn',
  ECONOMY = 'PFX::Shared::Economy',
  ECONOMYCATEGORY = 'PFX::Shared::EconomyCategory ',
  ECONOMYEXPENSE = 'PFX::Shared::EconomyExpense',
  ECONOMYINCOME = 'PFX::Shared::EconomyIncome',
  EDIT = 'PFX::Shared::Edit',
  EDITORS = 'PFX::Shared::Editors',
  EMAIL = 'PFX::Shared::Email',
  ENDDATE = 'PFX::Shared::EndDate',
  ENTITY = 'PFX::Shared::Entity',
  ESTIMATE = 'PFX::Shared::Estimate',
  ESTIMATEDHOURS = 'PFX::Shared::EstimatedHours',
  ESTIMATEHOURS = 'PFX::Shared::EstimateHours',
  FILTER_BUILDIN = 'PFX::Shared::Filter_BUILDIN',
  FILTER_DEFAULT = 'PFX::Shared::Filter_DEFAULT',
  FINANCEONOFF = 'PFX::Shared::FinanceOnOff',
  FINISH = 'PFX::Shared::Finish',
  FOLLOWUP = 'PFX::Shared::FollowUp',
  FREETEXT = 'PFX::Shared::FreeText',
  FROMDATE = 'PFX::Shared::FromDate',
  GANTT = 'PFX::Shared::Gantt',
  GANTTCHART = 'PFX::Shared::GanttChart',
  GENERATEDOCUMENTFROMTEMPLATE = 'PFX::Shared::GenerateDocumentFromTemplate',
  GLOBALHOME = 'PFX::Shared::GlobalHome',
  GLOBALSETTINGS = 'PFX::Shared::GlobalSettings',
  GROUP = 'PFX::Shared::Group',
  HELP = 'PFX::Shared::Help',
  HIDENONINCLUDED = 'PFX::Shared::HideNonIncluded',
  HOURS = 'PFX::Shared::Hours',
  HOURSALLOCATED = 'PFX::Shared::HoursAllocated',
  HOURSDELIVERED = 'PFX::Shared::HoursDelivered',
  HOURSESTIMATE = 'PFX::Shared::HoursEstimate',
  HOURSLEFT = 'PFX::Shared::HoursLeft',
  HOURSNOTYETDELIVERED = 'PFX::Shared::HoursNotYetDelivered',
  ID = 'PFX::Shared::Id',
  IMPACT = 'PFX::Shared::Impact',
  INCLUDE = 'PFX::Shared::Include',
  INCLUDEUSERHOURBUDGET = 'PFX::Shared::IncludeUserHourBudget',
  LANDINGPAGE = 'PFX::Shared::LandingPage',
  LANGUAGE = 'PFX::Shared::Language',
  LATESTSTATUSREPORT = 'PFX::Shared::LatestStatusReport',
  LEFT = 'PFX::Shared::Left',
  LEFTHOURS = 'PFX::Shared::LeftHours',
  LENGTHINDAYS = 'PFX::Shared::LengthInDays',
  LEVEL = 'PFX::Shared::Level',
  LOADING = 'PFX::Shared::Loading',
  LOGIN = 'PFX::Shared::Login',
  LOGOUT = 'PFX::Shared::Logout',
  MANAGER = 'PFX::Shared::Manager',
  MANAGERS = 'PFX::Shared::Managers',
  MANDATORY = 'PFX::Shared::Mandatory',
  MASTERDATA = 'PFX::Shared::MasterData',
  MAXIMUMROWS = 'PFX::Shared::MaximumRows',
  MESSAGE = 'PFX::Shared::Message',
  MILESTONE = 'PFX::Shared::Milestone',
  MILESTONES = 'PFX::Shared::Milestones',
  MINIMUMPMTOOLS = 'PFX::Shared::MinimumPMTools',
  MISSINGFOLLOWUPDATE = 'PFX::Shared::MissingFollowupDate',
  MISSINGPROJECTTYPE = 'PFX::Shared::MissingProjectType',
  MISSINGRESPONSIBLE = 'PFX::Shared::MissingResponsible',
  MISSINGSTARTORFINISHDATE = 'PFX::Shared::MissingStartOrFinishDate',
  MODULEACTIVATION_PM = 'PFX::Shared::ModuleActivation_PM',
  MODULEACTIVATION_PPM = 'PFX::Shared::ModuleActivation_PPM',
  MODULEACTIVATIONTITLE = 'PFX::Shared::ModuleActivationTitle',
  NAME = 'PFX::Shared::Name',
  NEW = 'PFX::Shared::New',
  NEXTSTATUSREPORTBEFORE = 'PFX::Shared::NextStatusReportBefore',
  NOTIFICATIONS = 'PFX::Shared::Notifications',
  NOW = 'PFX::Shared::Now',
  O365ASSETS = 'PFX::Shared::O365Assets',
  O365ASSETTYPE = 'PFX::Shared::O365AssetType',
  O365ID = 'PFX::Shared::O365Id',
  OFFICE365ASSETLINK = 'PFX::Shared::Office365AssetLink',
  OKAY = 'PFX::Shared::Okay',
  OPEN = 'PFX::Shared::Open',
  OPENPROJECT = 'PFX::Shared::OpenProject',
  ORGANIZATION = 'PFX::Shared::Organization',
  ORGANIZATIONALUNIT = 'PFX::Shared::OrganizationalUnit',
  OUTSIDETOLERANCE = 'PFX::Shared::OutsideTolerance',
  OVERDUEMILESTONES = 'PFX::Shared::OverDueMileStones',
  OVERDUEPRODUCTS = 'PFX::Shared::OverDueProducts',
  OTHERCONTENT = 'PFX::Shared::OtherContent',
  PARENTID = 'PFX::Shared::ParentId',
  PARTICIPANT = 'PFX::Shared::Participant',
  PARTICIPANTS = 'PFX::Shared::Participants',
  PERCENTCOMPLETE = 'PFX::Shared::PercentComplete',
  PERCENTDELIVERED = 'PFX::Shared::PercentDelivered',
  PERIOD = 'PFX::Shared::Period',
  PERIODEND = 'PFX::Shared::PeriodEnd',
  PERIODSTART = 'PFX::Shared::PeriodStart',
  PFX = 'PFX::Shared::PFx',
  PLAN = 'PFX::Shared::Plan',
  PLANNEDCOMPLETION = 'PFX::Shared::PlannedCompletion',
  PLANNEDTILLDATE = 'PFX::Shared::PlannedTillDate',
  PLANNEDTIMELEFT = 'PFX::Shared::PlannedTimeLeft',
  PLANNEDTIMELEFTP = 'PFX::Shared::PlannedTimeLeftP',
  PLANNEDTODELIVERHOURS = 'PFX::Shared::PlannedToDeliverHours',
  PLANNEDWORK = 'PFX::Shared::PlannedWork',
  PLANNING = 'PFX::Shared::Planning',
  PLANOVERVIEW = 'PFX::Shared::PlanOverview',
  PLEASEUPDATE = 'PFX::Shared::PleaseUpdate',
  PMOHOME = 'PFX::Shared::PMOHome',
  PMTOOLS = 'PFX::Shared::PMTools',
  PORTAL = 'PFX::Shared::Portal',
  PORTALHOME = 'PFX::Shared::PortalHome',
  PPM = 'PFX::Shared::PPM',
  PREFERENCES = 'PFX::Shared::Preferences',
  PRIORITY = 'PFX::Shared::Priority',
  PROBABILITY = 'PFX::Shared::Probability',
  PRODUCT = 'PFX::Shared::Product',
  PRODUCTS = 'PFX::Shared::Products',
  PROGRESS = 'PFX::Shared::Progress',
  PROJECT = 'PFX::Shared::Project',
  PROJECTEXPENCEBUDGET = 'PFX::Shared::ProjectExpenceBudget',
  PROJECTHOME = 'PFX::Shared::ProjectHome',
  PROJECTHOURSBUDGET = 'PFX::Shared::ProjectHoursBudget',
  PROJECTINCOMEBUDGET = 'PFX::Shared::ProjectIncomeBudget',
  PROJECTLANGUAGE = 'PFX::Shared::ProjectLanguage',
  PROJECTMANAGEMENTOFFICE = 'PFX::Shared::ProjectManagementOffice',
  PROJECTMANAGER = 'PFX::Shared::ProjectManager',
  PROJECTMEMBER = 'PFX::Shared::ProjectMember',
  PROJECTOWNER = 'PFX::Shared::ProjectOwner',
  PROJECTS = 'PFX::Shared::Projects',
  PROJECTTYPE = 'PFX::Shared::ProjectType',
  PROJECTTYPES = 'PFX::Shared::ProjectTypes',
  PUBLISH = 'PFX::Shared::Publish',
  PUBLISHED = 'PFX::Shared::Published',
  PUBLISINGDATE = 'PFX::Shared::PublisingDate',
  READERS = 'PFX::Shared::Readers',
  RECEIVINGORGS = 'PFX::Shared::ReceivingOrgs',
  RELEVANTOFFICE365ASSETLINKS = 'PFX::Shared::RelevantOffice365AssetLinks',
  REMAININGDAYS = 'PFX::Shared::RemainingDays',
  REMINDDAYSBEFORE = 'PFX::Shared::RemindDaysBefore',
  REMINDERS = 'PFX::Shared::Reminders',
  REQUIREDFIELDSMISSING = 'PFX::Shared::RequiredFieldsMissing',
  RESOURCE = 'PFX::Shared::Resource',
  RESOURCES = 'PFX::Shared::Resources',
  RESOURCESALLOCATED = 'PFX::Shared::ResourcesAllocated',
  RESPONSIBILITIESROLES = 'PFX::Shared::ResponsibilitiesRoles',
  RESPONSIBLE = 'PFX::Shared::Responsible',
  RIGHT = 'PFX::Shared::Right',
  RIGHTS = 'PFX::Shared::Rights',
  RIGHTS_CONTRIBUTE = 'PFX::Shared::Rights::Contribute',
  RIGHTS_FULL = 'PFX::Shared::Rights::Full',
  RIGHTS_READ = 'PFX::Shared::Rights::Read',
  RISK = 'PFX::Shared::Risk',
  RISKS = 'PFX::Shared::Risks',
  ROLE = 'PFX::Shared::Role',
  ROLE_S = 'PFX::Shared::Role_s',
  ROLEALERTS = 'PFX::Shared::RoleAlerts',
  ROLEPROJECTMANAGER = 'PFX::Shared::RoleProjectManager',
  ROLEPROJECTOWNER = 'PFX::Shared::RoleProjectOwner',
  ROLES = 'PFX::Shared::Roles',
  SAVE = 'PFX::Shared::Save',
  SELECT = 'PFX::Shared::Select',
  SETTINGS = 'PFX::Shared::Settings',
  SKILLS = 'PFX::Shared::Skills',
  SOURCE = 'PFX::Shared::Source',
  SITEHOME = 'PFX::Shared::SiteHome',
  START = 'PFX::Shared::Start',
  STARTDATE = 'PFX::Shared::StartDate',
  STATE = 'PFX::Shared::State',
  STATUS = 'PFX::Shared::Status',
  STATUSDATE = 'PFX::Shared::StatusDate',
  STATUSINDICATORS = 'PFX::Shared::StatusIndicators',
  STATUSREPORT = 'PFX::Shared::StatusReport',
  STATUSREPORTING = 'PFX::Shared::StatusReporting',
  STATUSREPORTNOTPUBLICED = 'PFX::Shared::StatusReportNotPubliced',
  STATUSREPORTS = 'PFX::Shared::StatusReports',
  STATUSREPORTSLINK = 'PFX::Shared::StatusReportsLink',
  TASK = 'PFX::Shared::Task',
  TASKDUEDATETOLATE = 'PFX::Shared::TaskDueDateToLate',
  TASKNOTASSIGNEDTOPRODUCTORMILESTONE = 'PFX::Shared::TaskNotAssignedToProductOrMilestone',
  TASKPARENT = 'PFX::Shared::TaskParent',
  TASKS = 'PFX::Shared::Tasks',
  TASKSTARTDATETOEARLY = 'PFX::Shared::TaskStartDateToEarly',
  TIMEFRAMEUSEDP = 'PFX::Shared::TimeFrameUsedP',
  TIMELINE = 'PFX::Shared::TimeLine',
  TITLE = 'PFX::Shared::Title',
  TODATE = 'PFX::Shared::ToDate',
  TOFEWRESSOURCES = 'PFX::Shared::ToFewRessources',
  TOLERANCES = 'PFX::Shared::Tolerances',
  TYPE = 'PFX::Shared::Type',
  USEALLMANAGEMENTTOOLS = 'PFX::Shared::UseAllManagementTools',
  USER = 'PFX::Shared::User',
  USERINTERFACELANGUAGE = 'PFX::Shared::UserInterfaceLanguage',
  USERS = 'PFX::Shared::Users',
  USERNAME = 'PFX::Shared::UserName',
  VERSION = 'PFX::Shared::Version',
  WHICHDAYS = 'PFX::Shared::WhichDays',
  WHICHMONTHS = 'PFX::Shared::WhichMonths',
  WHICHWEEKS = 'PFX::Shared::WhichWeeks',
  WORKERCATEGORY = 'PFX::Shared::WorkerCategory',
  WORKITEM = 'PFX::Shared::WorkItem',
  WORKITEMS = 'PFX::Shared::WorkItems',
  WORKITEMSTATUS = 'PFX::Shared::WorkItemStatus',
}

export enum PFXSystem {
  PFX_SYSTEM = 'PFX::System',
  GLOBAL = 'PFX::System::Global',
  O365 = 'PFX::System::O365',
  O365_DOCLIST = 'PFX::System::O365::DOCLIST',
  O365_GROUP = 'PFX::System::O365::GROUP',
  O365_PLAN = 'PFX::System::O365::PLAN',
  O365_SITE = 'PFX::System::O365::SITE',
  O365_TASKLIST = 'PFX::System::O365::TASKLIST',
  O365_TEAM = 'PFX::System::O365::TEAM',
  PFX_MILESTONE = 'PFX::System::PFX_Milestone',
  PFX_O365_ASSET = 'PFX::System::PFX_O365_Asset',
  PFX_PMO = 'PFX::System::PFX_PMO',
  PFX_PORTAL = 'PFX::System::PFX_Portal',
  PFX_PRODUCT = 'PFX::System::PFX_Product',
  PFX_PROJECT = 'PFX::System::PFX_Project',
  PFX_PROJECTMEMBER = 'PFX::System::PFX_ProjectMember',
  PFX_PROJECTTYPE = 'PFX::System::PFX_ProjectType',
  PFX_RESOURCE = 'PFX::System::PFX_Resource',
  PFX_RISK = 'PFX::System::PFX_Risk',
  PFX_ROLE = 'PFX::System::PFX_Role',
  PFX_STATUSREPORT = 'PFX::System::PFX_StatusReport',
  PFX_TASK = 'PFX::System::PFX_Task',
  PFX_WEBTYPE = 'PFX::System::PFX_WebType',
  USER = 'PFX::System::User',
}
