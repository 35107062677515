import MenuItem from 'patternfly-react/dist/js/components/MenuItem/MenuItem';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Split from 'split.js';
import { BreadcrumbMenu } from './components/Nav/Breadcrumb';
import { ContextSelector } from './components/Nav/ContextSelector';
import { Notifications } from './components/Nav/Notifications';
import { TopMenu } from './components/Nav/TopMenu';
// import { FilterMessage } from './components/CoreFlow/CoreFlow';
import {
  VerticalNav,
  VerticalNavBrand,
  VerticalNavItem,
  VerticalNavMasthead,
  VerticalNavSecondaryItem
} from 'patternfly-react/dist/js/components/VerticalNav';
// import { Fabric } from '@fluentui/react/lib/Fabric';
import { Icon } from '@fluentui/react/lib/Icon';
import { Array, Dom, Events } from 'fit-ui';
import {
  AppInterface,
  PFXAlertPanelMode,
  PFXAppLayoutMode,
  PFXBreakPoints,
  PFXCSSVars,
  PFXClearFlags,
  PFXInitialLayoutMode,
  PFXLayoutConstants,
  PFXRenderZone,
  PFXSideMenuMode,
  PFXStorage,
  PFX_BRAND,
  PFX_LOGO,
  appDebugEnabled,
  devMode,
  disableConsoleLogging,
  enableConsoleLogging,
  updateCSSVar
} from './Root';
import eventApi from './api/eventApi';
import { PFXMessages, PFXShared } from './api/localizationApi';
import { PFXWeb, PFXWebType } from './api/webApi';
import CoreFlowCallOut from './components/CoreFlow/CoreFlowCallOut';
import { ActionPanel } from './components/Misc/ActionPanel';
import AlertBar from './components/Misc/Alert';
import { AlertPanel } from './components/Misc/AlertPanel';
import InlinePanel from './components/Misc/InlinePanel';
import Loader from './components/Misc/Loader';
import { SimpleDialog } from './components/Misc/SimpleDialog';
import './css/App.css';
import PFXMenuItem, { PFXMenuItemType } from './models/PFXMenuItem';
import * as actionTypes from './redux/actionConstants';
import {
  checkForUpdates,
  loadPath,
  openPanelAndCaptureAppFocus,
  setAlert,
  // clearAlert,
  updateWebs,
} from './redux/actionCreators';
import { RoutedAppState } from './redux/actionReducers';
import { PFXComponentFactory } from './utils/PFXComponentFactory';
// import { NavItem } from 'react-bootstrap';
import { ThemeProvider } from '@fluentui/react';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { cfx_web_ns } from './components/CoreFlow/CoreFlow';
import BugMonitor from './components/Misc/BugMonitor';
import { PFXHelpers } from './utils/PFXHelpers';
// import { firstUnsavedApp } from './components/CoreFlow/CoreFlow';

/*

TODO:
- Implement Error Boundaries: https://reactjs.org/docs/error-boundaries.html
- Async error handling

*/

declare var cfx_web: typeof cfx_web_ns;

export class App extends React.Component<AppInterface, AppInterface> {
  private routes: JSX.Element;
  private sideMenu: (JSX.Element | JSX.Element[])[];
  private lastScrollY: number;
  private sideMenuDiv: Element;
  private contentDiv: Element;
  private bottomDiv: HTMLElement;
  private topMenu: Element;
  private toggleBtn: HTMLInputElement;
  private helpSplit: Split.Instance;

  private flexBody: Element;
  private hideMenu: boolean;
  private updateInterval: NodeJS.Timeout;

  constructor(props: AppInterface) {

    super(props);


    if (appDebugEnabled) {
      console.debug('App constructor');
    }

    this.lastScrollY = 0;

    this.buildRoutes = this.buildRoutes.bind(this);
    this.buildSideMenu = this.buildSideMenu.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.toggleSideMenu = this.toggleSideMenu.bind(this);
    this.toggleHelpPanel = this.toggleHelpPanel.bind(this);
    this.toggleAlertsPanel = this.toggleAlertsPanel.bind(this);
    this.toggleTopMenu = this.toggleTopMenu.bind(this);
    this.toggleNotificationPanel = this.toggleNotificationPanel.bind(this);
    this.hideMenusInMobileMode = this.hideMenusInMobileMode.bind(this);

    // set up some CSS global variables
    updateCSSVar(
      PFXCSSVars.BREAKPOINT_TABLET,
      PFXBreakPoints[PFXAppLayoutMode.TABLET]
    );

    updateCSSVar(
      PFXCSSVars.BREAKPOINT_DESKTOP,
      PFXBreakPoints[PFXAppLayoutMode.DESKTOP]
    );

    updateCSSVar(
      PFXCSSVars.ALERTPANEL_HEIGHT,
      PFXLayoutConstants.alertPanelHeights[
      props.alertsVisible
        ? PFXAlertPanelMode.EXPANDED
        : PFXAlertPanelMode.COLLAPSED
      ]
    );

    updateCSSVar(
      PFXCSSVars.SIDEMENU_WIDTH,
      PFXLayoutConstants.sideMenuWidths[PFXInitialLayoutMode][
      props.menuCollapsed
        ? PFXSideMenuMode.COLLAPSED
        : PFXSideMenuMode.EXPANDED
      ]
    );

    setInterval(() => this.bottomDiv ? updateCSSVar(
      PFXCSSVars.SCROLLBAR_WIDTH,
      Dom.GetScrollBars(this.bottomDiv).Vertical.Size.toString() + 'px'
      // tslint:disable-next-line:align
    ) : null, 500);

    this.syncCSSLayout(props.layoutMode, null, props.menuCollapsed);

    Events.AddHandler(window, 'focus', true, this.appFocus);
    // Events.AddHandler(window, 'blur', true, this.appBlur);
    Events.AddHandler(document, 'visibilitychange', true, this.appVisibilityChange);

    // check we're running app normally
    if (!props.isForcedReload) {
      // start update/server availability monitoring

      this.updateInterval = setInterval(
        () => window.store.dispatch(checkForUpdates()),
        10000
      );

      // passive update of webs list
      window.store.dispatch(updateWebs());

    }

    // #3169
    window.onpopstate = this.onPopState;

    if (devMode) {
      // #7108 monitor runtime errors
      window.onerror = this.onError;
    }

  }

  onPopState = (e: PopStateEvent) => {
    // #3169 Clear CoreFlow Filter Hack
    if (appDebugEnabled) {
      console.debug('App Pop State', e);
    }
    cfx_web.Public.prepareNavigationWithFilter({ appId: 'PF365', initiator: 'onPopState' }, e.state?.state /* as FilterMessage*/);
  }

  onError = (
    message: string,
    source: string,
    lineno: number,
    colno: number,
    error: Error
  ) => {
    // #7108 monitor runtime errors
    if (message !== 'Uncaught EvalError: Possible side-effect in debug-evaluate') {
      // Bug in recent chrome causes console eval to trigger onError
      // https://stackoverflow.com/questions/72396527/evalerror-possible-side-effect-in-debug-evaluate-in-google-chrome
      window.store.dispatch({ type: actionTypes.APPEND_ERROR });
    }
  }


  appFocus = (e: FocusEvent) => {
    if (!this.updateInterval) {
      this.updateInterval = setInterval(
        () => window.store.dispatch(checkForUpdates()),
        10000
      );
      if (appDebugEnabled) {
        console.debug('App Update Service Started');
      }
    }
  };

  appVisibilityChange = (e: any) => {
    if (document.hidden) {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
        if (appDebugEnabled) {
          console.debug('App Update Service Stopped');
        }
      }
    } else {
      if (!this.updateInterval) {
        this.updateInterval = setInterval(
          () => window.store.dispatch(checkForUpdates()),
          10000
        );
        if (appDebugEnabled) {
          console.debug('App Update Service Started');
        }
      }
    }
  };

  combinedUrl = (location: Location): string => {
    return location.pathname + location.search + location.hash;
  };

  componentDidMount() {
    if (appDebugEnabled) {
      console.debug('App DidMount');
    }

    // remove fallback refresher, see index.html
    clearTimeout(window.ProjectFlowX.fallbackRefresh);
    if (appDebugEnabled) {
      console.debug('App fallbackRefresh removed');
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    if (appDebugEnabled) {
      console.debug('App WillUnmount');
    }
    if (this.flexBody) {
      this.flexBody.removeEventListener('scroll', this.onScroll);
    }

    if (this.toggleBtn) {
      this.toggleBtn.removeEventListener('click', this.toggleSideMenu);
    }

    window.removeEventListener('resize', this.onResize);
  }

  shouldComponentUpdate(nextProps: AppInterface): boolean {
    if (window?.location?.pathname !== nextProps?.location?.pathname) {
      if (appDebugEnabled) {
        console.debug('skipping app update due to location change in progress');
      }
      return false;
    }
    let update = false;
    for (const key in nextProps) {
      if (nextProps[key] !== this.props[key]) {

        if (key === 'match' || key === 'currentPath') {
          /* match needs deep check .. */
          update = !PFXHelpers.deepCompare(nextProps[key], this.props[key]);
        } else {
          update = true;
        }

        // Easy debugging of "unsolicited" prop changes:
        /*
        if (update) {
          console.debug(
            'App Prop "' + key + '" Changed (old/new)',
            this.props[key],
            nextProps[key]
          );
        }
        */

      }
    }

    if (update) {
      if (!nextProps.isInitializing && nextProps.contextUser) {
        if (!this.flexBody) {
          this.flexBody = document.getElementById('flexBody');
        }

        if (
          nextProps.contextWeb &&
          (nextProps.contextWeb !== this.props.contextWeb ||
            nextProps.terms !== this.props.terms)
        ) {
          if (nextProps.contextWeb.archived === true) {
            window.store.dispatch(
              setAlert({
                type: MessageBarType.warning,
                message: nextProps.terms[PFXMessages.PROJISARCHIVEDNOEDIT]
              })
            );
          }
        }

        if (nextProps.mastheadHidden !== this.props.mastheadHidden) {
          if (nextProps.mastheadHidden) {
            updateCSSVar(PFXCSSVars.TOPMENU_OFFSET, '0px');
          } else {
            updateCSSVar(
              PFXCSSVars.TOPMENU_OFFSET,
              PFXLayoutConstants.topMenuHeights[nextProps.layoutMode]
            );
          }
        }

        // Toggle top menu styling in mobile mode
        // TODO: change to pure CSS via scoping by layoutmode class on root div
        if (nextProps.topMenuHidden !== this.props.topMenuHidden) {
          if (nextProps.topMenuHidden && this.topMenu) {
            this.topMenu.classList.remove('showTopMenu');
          } else {
            this.topMenu.classList.add('showTopMenu');
          }
        }

        // build routes and menus if necessary
        if (
          nextProps.location &&
          (nextProps.menuItems !== this.props.menuItems ||
            nextProps.contextWeb !== this.props.contextWeb ||
            nextProps.currentPath !== this.props.currentPath ||
            nextProps.contextForm !== this.props.contextForm ||
            nextProps.formAlerts !== this.props.formAlerts ||
            nextProps.productStatus !== this.props.productStatus ||
            nextProps.dataTick !== this.props.dataTick ||
            !this.routes)
        ) {
          // Update Document (browser tab/window) Title..
          if (
            nextProps.currentPath &&
            nextProps.currentPath !== this.props.currentPath
          ) {
            const item = nextProps.currentPath[nextProps.currentPath.length - 1];
            if (item) {
              const itemTitle = (item.component === 'Form' ? nextProps.formTypes[nextProps.contextForm.FormType].displayName + ': ' + (nextProps.contextForm.PFX_Title ?? nextProps.contextForm.slug ?? (item.title ?? '')) : item.title ?? '');

              if (nextProps.contextWeb && nextProps.contextWeb.title) {
                document.title = itemTitle + ' - ' + nextProps.contextWeb.title + ' - ' + window.ProjectFlowX.solutionName;
              } else {
                if (item.title) {
                  document.title = itemTitle + ' - ' + window.ProjectFlowX.solutionName;
                } else {
                  if (item.component === 'Form') {
                    document.title = itemTitle + ' - ' + window.ProjectFlowX.solutionName;
                  } else {
                    document.title = window.ProjectFlowX.solutionName;
                  }
                }
              }
            }
          }

          let items = [];

          if (nextProps.contextWeb) {
            // Filter root nodes based on scope, assume children to be same scope
            items = nextProps.menuItems.filter(item => ['Global', 'User', nextProps.contextWeb.type].indexOf(item.scope) > -1);
          } else {
            items = nextProps.menuItems;
          }


          this.hideMenu = true;

          if (items.length > 0) {
            this.routes = this.buildRoutes(items, nextProps);

            if (
              nextProps.contextWeb &&
              nextProps.currentPath &&
              nextProps.currentPath.length > 0
            ) {

              this.sideMenu = this.buildSideMenu(
                items,
                nextProps.currentPath,
                nextProps.contextWeb
              );

              if (this.sideMenu) {
                this.hideMenu = this.sideMenu.length === 0;
                if (appDebugEnabled) {
                  console.debug('App No sidemenu items found');
                }
                /*
                this.hideMenu =
                  (this.sideMenuDiv &&
                    this.toggleBtn &&
                    this.contentDiv &&
                    !this.sideMenu) ||
                  this.sideMenu.length === 0;
                */
              }
            }
          } else {
            this.hideMenu = true;
            if (appDebugEnabled) {
              console.debug('App No menu items found');
            }
          }


          if (this.hideMenu) {
            updateCSSVar(PFXCSSVars.MENU_DISPLAY, 'none');

            updateCSSVar(
              PFXCSSVars.SIDEMENU_WIDTH,
              PFXLayoutConstants.sideMenuWidths[PFXSideMenuMode.HIDDEN]
            );
            if (this.toggleBtn && this.contentDiv) {
              this.toggleBtn.classList.add('empty-side-menu');
              this.contentDiv.classList.add('empty-side-menu');
            }
          } else {
            updateCSSVar(PFXCSSVars.MENU_DISPLAY, 'initial');
            const w =
              PFXLayoutConstants.sideMenuWidths[nextProps.layoutMode][
              nextProps.menuCollapsed
                ? PFXSideMenuMode.COLLAPSED
                : PFXSideMenuMode.EXPANDED
              ];
            updateCSSVar(PFXCSSVars.SIDEMENU_WIDTH, w);
            if (this.toggleBtn && this.contentDiv) {
              this.toggleBtn.classList.remove('empty-side-menu');
              this.contentDiv.classList.remove('empty-side-menu');
            }
          }
        }
      }
    }
    return update;
  }

  componentDidUpdate(prevProps: AppInterface) {
    // use for logic that assumes DOM has rendered (post styling etc.)
    if (appDebugEnabled) {
      console.debug('App DidUpdate');
    }
    const props = this.props;

    if (prevProps.logMode !== props.logMode) {
      // switch console logging
      (props.logMode ?? false) ? enableConsoleLogging() : disableConsoleLogging();
    }

    if (!props.isInitializing && props.contextUser) {
      // Bind interactive divs
      if (!this.sideMenuDiv) {
        this.sideMenuDiv = document.getElementsByClassName(
          'nav-pf-vertical'
        )[0];
        if (this.sideMenuDiv) {
          // Main layout is available
          this.toggleBtn = document.getElementsByClassName(
            'navbar-toggle'
          )[0] as HTMLInputElement;

          this.toggleBtn.addEventListener('click', this.toggleSideMenu);

          this.contentDiv = document.getElementsByClassName(
            'container-pf-nav-pf-vertical'
          )[0];

          this.topMenu = document.body.getElementsByClassName(
            'collapse navbar-collapse'
          )[0];
        }
      }

      this.bottomDiv = document.querySelector('.bottom');

      // Help panel logic
      if (
        prevProps.helpVisible !== props.helpVisible ||
        prevProps.inlinePanelItem !== props.inlinePanelItem ||
        ((props.helpVisible || props.inlinePanelItem) && !this.helpSplit)
      ) {

        if (props.helpVisible || props.inlinePanelItem) {
          if (props.layoutMode !== PFXAppLayoutMode.MOBILE) {
            /* Tablet / Desktop Help View */
            if (!this.helpSplit) {
              var sizes = [80, 20]; // default sizes in %
              const storedSizes = localStorage.getItem(
                PFXStorage.HELP_PANEL_SIZES
              );
              if (storedSizes) {
                sizes = JSON.parse(storedSizes) as number[];
              }
              if (
                document.getElementById('left') &&
                document.getElementById('right')
              ) {

                this.helpSplit = Split(['#left', '#right'], {
                  sizes: sizes,
                  minSize: [500, 200],
                  onDragEnd: (endSizes: number[]) =>
                    localStorage.setItem(
                      PFXStorage.HELP_PANEL_SIZES,
                      JSON.stringify(endSizes)
                    )
                });
              }
            }
          } else {
            /* Mobile Help View */
          }
        } else {
          if (props.layoutMode !== PFXAppLayoutMode.MOBILE) {
            if (this.helpSplit) {
              this.helpSplit.destroy();
              this.helpSplit = null;
            }
          }
        }
      }

      // Alerts panel logic
      if (prevProps.alertsVisible !== props.alertsVisible) {
        updateCSSVar(
          PFXCSSVars.ALERTPANEL_HEIGHT,
          PFXLayoutConstants.alertPanelHeights[
          props.alertsVisible
            ? PFXAlertPanelMode.EXPANDED
            : PFXAlertPanelMode.COLLAPSED
          ]
        );
      }

      // Update background color if needed
      // --pfx-theme-background
      if (prevProps.currentPath !== props.currentPath) {
        if (props.currentPath && props.currentPath.length > 0) {
          const item = props.currentPath[props.currentPath.length - 1];
          if (item && item.componentProps && item.componentProps.fullPageLayout) {
            updateCSSVar(PFXCSSVars.BODY_BACKGROUND, '#ffffff');
          } else {
            updateCSSVar(PFXCSSVars.BODY_BACKGROUND, '#f5f5f5');
          }

        }

      }
      // Hide alert panel on web types that does not support it.
      if (prevProps.contextWeb !== props.contextWeb) {
        if (props.contextWeb.type !== PFXWebType.PROJECT) {
          updateCSSVar(PFXCSSVars.ALERTPANEL_HEIGHT, '0px');
        } else {
          updateCSSVar(
            PFXCSSVars.ALERTPANEL_HEIGHT,
            PFXLayoutConstants.alertPanelHeights[
            props.alertsVisible
              ? PFXAlertPanelMode.EXPANDED
              : PFXAlertPanelMode.COLLAPSED
            ]
          );
        }
      }

      // Menu logic
      if (
        prevProps.menuCollapsed !== props.menuCollapsed ||
        prevProps.layoutMode !== props.layoutMode
      ) {
        this.syncCSSLayout(props.layoutMode, prevProps.layoutMode, props.menuCollapsed);
      }

    }


  }

  syncCSSLayout(layoutMode: PFXAppLayoutMode, previousMode: PFXAppLayoutMode, menuCollapsed: boolean) {
    updateCSSVar(
      PFXCSSVars.TOPMENU_HEIGHT,
      PFXLayoutConstants.topMenuHeights[layoutMode]
    );
    updateCSSVar(
      PFXCSSVars.TOPMENU_OFFSET,
      PFXLayoutConstants.topMenuHeights[layoutMode]
    );
    const w = this.hideMenu
      ? PFXLayoutConstants.sideMenuWidths[PFXSideMenuMode.HIDDEN]
      : PFXLayoutConstants.sideMenuWidths[layoutMode][
      menuCollapsed
        ? PFXSideMenuMode.COLLAPSED
        : PFXSideMenuMode.EXPANDED
      ];
    updateCSSVar(PFXCSSVars.SIDEMENU_WIDTH, w);

    const root = document.getElementById('root');
    if (root) {
      if (previousMode) {
        root.classList.remove('pfx-' + previousMode);
      }
      root.classList.add('pfx-' + layoutMode);
    }
  }

  layoutChange(layoutMode: PFXAppLayoutMode) {
    window.store.dispatch({
      type: actionTypes.LAYOUT_CHANGE,
      payload: { layoutMode }
    });
  }

  toggleSideMenu() {
    const newValue = !this.props.menuCollapsed;
    window.store.dispatch({
      type: actionTypes.MENU_COLLAPSED_CHANGE,
      payload: { menuCollapsed: newValue }
    });
    localStorage.setItem(PFXStorage.MENU_COLLAPSED, JSON.stringify(newValue));
  }

  toggleHelpPanel() {
    const newValue = !this.props.helpVisible;
    window.store.dispatch({
      type: actionTypes.HELP_VISIBILITY_CHANGE,
      payload: { helpVisible: newValue }
    });
    localStorage.setItem(PFXStorage.HELP_VISIBLE, JSON.stringify(newValue));

    this.hideMenusInMobileMode();
  }

  toggleAlertsPanel() {
    const newValue = !this.props.alertsVisible;
    window.store.dispatch({
      type: actionTypes.ALERTS_VISIBILITY_CHANGE,
      payload: { alertsVisible: newValue }
    });
    localStorage.setItem(PFXStorage.ALERTS_VISIBLE, JSON.stringify(newValue));
    this.hideMenusInMobileMode();
  }

  toggleTopMenu() {
    window.store.dispatch({
      type: actionTypes.TOPMENU_VISIBILITY_CHANGE,
      payload: { topMenuHidden: !this.props.topMenuHidden }
    });
  }

  hideMenusInMobileMode() {
    if (this.props.layoutMode === PFXAppLayoutMode.MOBILE) {
      // Hide menus
      window.store.dispatch({
        type: actionTypes.TOPMENU_VISIBILITY_CHANGE,
        payload: { topMenuHidden: true }
      });
      // Unlikely, but could happen
      window.store.dispatch({
        type: actionTypes.MENU_COLLAPSED_CHANGE,
        payload: { menuCollapsed: true }
      });
    }
  }

  toggleNotificationPanel(drawerOpen: boolean) {
    if (drawerOpen) {
      this.hideMenusInMobileMode();
      // Hide help
      window.store.dispatch({
        type: actionTypes.HELP_VISIBILITY_CHANGE,
        payload: { helpVisible: false }
      });
      localStorage.setItem(PFXStorage.HELP_VISIBLE, JSON.stringify(false));
    }
  }

  onScroll() {
    if (this.flexBody) {
      const wY = this.flexBody.scrollTop;
      if (
        wY !== this.lastScrollY &&
        Math.abs(wY - this.lastScrollY) >
        5 /* &&
        !((window.innerHeight + wY - 5) >= document.body.offsetHeight) */
      ) {
        const headerHeight = 60;
        const hidden = !(wY < this.lastScrollY || wY < headerHeight);
        if (hidden !== this.props.mastheadHidden) {
          window.store.dispatch({
            type: actionTypes.MASTHEAD_VISIBILITY_CHANGE,
            payload: { mastheadHidden: hidden }
          });
        }
        this.lastScrollY = wY;
      }
    }
  }

  onResize() {
    if (document.body.scrollHeight === document.body.clientHeight) {
      window.store.dispatch({
        type: actionTypes.MASTHEAD_VISIBILITY_CHANGE,
        payload: { mastheadHidden: false }
      });
    }
  }

  replacePath = (to: string, slug?: string) => {
    // replaces ':slug' placeholders with actual slug ids
    return to.replace(':slug', slug ? slug : this.props.contextWeb.slug);
  };

  navigateTo(item: PFXMenuItem) {
    let to = item.to;
    const store = window.store;
    if (item.noNavigation !== true) {
      eventApi.onMenuClick(this.props.contextWeb, item);
      if (item.itemType === PFXMenuItemType.externalLink && item.externalLinkProps) {
        // external Link
        const link = item.externalLinkProps;
        window.open(link.url, link.target, link.features);
      } else if (item.itemType === PFXMenuItemType.navigation) {
        // Perform navigation
        if (item.scope === 'Global' || item.scope === 'User') {
          store.dispatch(loadPath(this.replacePath(to)));
        } else {
          if (this.props.contextWeb) {
            store.dispatch(loadPath(this.replacePath(to)));
          } else {
            console.warn('navigateTo discrepancy - how did we end here?');
          }
        }
      } else if (item.component && item.componentProps && item.componentProps.skipDialog) {
        // Silent component execution, e.g. SwitchUser
        PFXComponentFactory(item.component, item.componentProps, PFXRenderZone.NONE, item);
      } else if (item.itemType === PFXMenuItemType.action) {
        // modal panel
        if (
          this.props.contextWeb.archived === true &&
          item.scope === this.props.contextWeb.type
        ) {
          // no creation of new items in this scope if Archived is true
          window.store.dispatch(
            setAlert({
              type: MessageBarType.error,
              message: this.props.terms[PFXMessages.PROJISARCHIVEDNONEW]
            })
          );
        } else {
          // Show in side panel
          store.dispatch(openPanelAndCaptureAppFocus({
            actionPanelItem: item,
          }))
        }

      } else if (item.itemType === PFXMenuItemType.inline) {
        // inline / context panel 

        store.dispatch({
          type: actionTypes.SET_INLINE_PANEL_ITEM,
          payload: { inlinePanelItem: item }
        });

      }

      // Ensure menu is removed after item click in mobile mode
      if (this.props.layoutMode === PFXAppLayoutMode.MOBILE) {
        if (!this.props.topMenuHidden) {
          window.store.dispatch({
            type: actionTypes.TOPMENU_VISIBILITY_CHANGE,
            payload: { topMenuHidden: true }
          });
        }

        window.store.dispatch({
          type: actionTypes.MENU_COLLAPSED_CHANGE,
          payload: { menuCollapsed: true }
        });
      }
    }
  }

  buildSideMenu = (
    items: PFXMenuItem[],
    currentPath: PFXMenuItem[],
    contextWeb: PFXWeb
  ) => {
    if (items && items.length > 0) {
      if (appDebugEnabled) {
        console.debug('App build side menu');
      }

      const isActive = (item: PFXMenuItem): boolean => {
        const result =
          currentPath.find(
            pItem => pItem.to === item.to && (pItem.redirectPath ?? '') === '' /* && pItem.to !== '/:slug/' */
          ) && true;
        return result;
      };

      const getMenuElements = (item: PFXMenuItem): JSX.Element => {
        return (
          <VerticalNavItem
            key={item.ID}
            href={this.replacePath(item.to, contextWeb.slug)}
            title={item.title}
            iconClass={item.iconClass}
            /* initialActive={isActive(item)} */
            active={isActive(item)}
            preventHref={true}
            onClick={() => this.navigateTo(item)}
            className={item.required === true ? 'pfx-menu-required' : ''}
            hiddenIcon={false}
          >
            {item.subItems &&
              item.subItems
                .filter((subItem: PFXMenuItem) => {
                  return subItem.hidden !== true;
                })
                .map(childItem => (
                  <VerticalNavSecondaryItem
                    key={childItem.ID}
                    /* to={this.replacePath(item.to, contextWeb.slug)} */
                    title={childItem.title}
                    iconClass={childItem.iconClass}
                    /* initialActive={isActive(childItem)} */
                    active={isActive(childItem)}
                    onClick={() => this.navigateTo(childItem)}
                    preventHref={true}
                    href={this.replacePath(childItem.to, contextWeb.slug)}
                    hiddenIcon={false}
                  /* submenu icons gone? see ln 332 in verticalnavitemhelper.js and patternfly hack documentation */
                  /* issue: how to support builds elsewhere? monkey-patchable? */
                  />
                ))}
          </VerticalNavItem>
        );
      };

      const sideMenu = items
        .filter(
          (item: PFXMenuItem) =>
            item.menu === 'Side'
        )
        .map(item => {
          if (item.hidden) {
            // if an item is hidden we render the child items as main items
            // This is to allow e.g. project items to be grouped under a virtual project node
            if (item.subItems) {
              return item.subItems
                .filter((subItem: PFXMenuItem) => {
                  return subItem.hidden !== true;
                })
                .map(subItem => getMenuElements(subItem));
            } else {
              return null;
            }
          } else {
            return [getMenuElements(item)];
          }
        })
        .filter(item => item !== null && item.length !== 0);

      return sideMenu;
    } else {
      return null;
    }
  };

  buildRedirects = (items: PFXMenuItem[], props: AppInterface): Redirect[] => {
    let redirects = [];

    Array.Recurse(items, 'subItems', (item: PFXMenuItem) => {
      if (item.redirectPath && true) {

        const to = this.replacePath(
          item.redirectPath,
          props.contextWeb.slug
        );

        const from = this.replacePath(
          item.to,
          props.contextWeb.slug
        );

        // Debugging aid for unresolved issue with 'Warning: You tried to redirect to the same route you're currently on'. Note this might be due to rerendering..

        // console.log('currentPath', props.currentPath);
        // console.log('location', props.location);
        // console.log('from', '/' + props.contextWeb.slug);
        // console.log('to', to + (location.search || ''));
        // console.log('match', (props as any).match.url);
        // console.log('window.location.pathname', window.location.pathname);

        if (props.contextWeb.type === PFXWebType.PORTAL && item.to === '/:slug') {
          // make sure we cover portal root
          // we should really redirect to /Portal (/:slug)
          // but another redirect awaits there, so make the jump directly!
          redirects.push(
            <Redirect
              key={'REDIRECT: /'}
              exact
              from={'/'}
              to={to + (location.search || '')}
            />
          );
        }

        redirects.push(
          <Redirect
            key={'REDIRECT: ' + to}
            exact
            from={from}  /* '/' + props.contextWeb.slug */
            to={to + (location.search || '')}
          />
        );
      }
    });

    return redirects;
  }

  buildRoutes = (items: PFXMenuItem[], props: AppInterface) => {
    let routes = [];

    if (items && items.length > 0) {

      routes = routes.concat(this.buildRedirects(items, props));

      // Build routes recursively
      Array.Recurse(items, 'subItems', (item: PFXMenuItem) => {
        if (
          item.noNavigation !== true &&
          (item.scope === 'Public' || props.contextUser)
        ) {
          const to = item.to;
          routes.push(
            <Route
              key={'ROUTE: ' + to}
              exact
              path={item.componentProps ? (item.componentProps.isDriveBrowser ? [to, to + '/:item/:drive/:driveitem'] : to) : to}
              render={() => {
                if (appDebugEnabled) {
                  console.debug('route render', item);
                }
                if (window?.location?.pathname === props?.location?.pathname) {
                  return PFXComponentFactory(item.component, item.componentProps, PFXRenderZone.PAGE, item);
                } else {
                  if (appDebugEnabled) {
                    console.debug('skipping route render due to location change in progress');
                  }
                  return '';
                }

              }}
            />
          );
        }
      });

      // Catch all "Not Found" routes
      routes.push(
        <Route
          key="ROUTE: *"
          path="/:slug/*"
          render={() => PFXComponentFactory('NotFound', null, PFXRenderZone.PAGE)}
        />
      );

    } else {
      if (appDebugEnabled) {
        console.debug('App no routes');
      }
    }
    if (appDebugEnabled) {
      console.debug('App build routes');
    }

    return <Switch>{routes}</Switch>;
  };

  render() {

    if (
      this.props.isInitializing !== false ||
      this.props.isUnauthorized ||
      this.props.isForcedReload ||
      (this.props.isServerOffline && !this.props.systemInfo) ||
      !this.props.location ||
      !this.props.contextUser
    ) {
      if (appDebugEnabled) {
        console.debug('App render init');
      }
      return (
        <ThemeProvider applyTo="body">
          <Loader
            isInitializing={this.props.isInitializing}
            isUnauthorized={this.props.isUnauthorized}
            isServerOffline={this.props.isServerOffline}
            message={this.props.appStatus}
          />
          {this.props.alert && !this.props.alert.dialogOnly && (
            <AlertBar alert={this.props.alert} />
          )}
        </ThemeProvider>
      );
    } else {
      if (appDebugEnabled) {
        console.debug('App render normal');
      }
      PFXClearFlags();

      return (
        <ThemeProvider applyTo="body">
          {/*
          <Prompt
            when={firstUnsavedApp(this.props.appEditStates) != null}
            message="Are you sure you want to leave?"
          />          
        */}
          <VerticalNav
            persistentSecondary={false}
            onLayoutChange={this.layoutChange}
            /* do not work in mobile mode, damn you patternfly
              onCollapse={this.toggleMenuListener}
              onExpand={this.toggleMenuListener}            
              */
            navCollapsed={this.props.menuCollapsed}
          >
            <VerticalNavMasthead className={'test'}>
              <VerticalNavBrand title={PFX_BRAND} iconImg={PFX_LOGO}>
                <Link
                  to="/Portal"
                  onClick={e => window.ProjectFlowX.navigate('/Portal', e as unknown as MouseEvent)}
                >
                  <img src={PFX_LOGO} className="navbar-brand-icon" />
                  <img
                    src={
                      this.props.layoutMode === PFXAppLayoutMode.TABLET
                        ? PFX_BRAND /* PFX_BRAND_TABLET */
                        : PFX_BRAND
                    }
                    className="navbar-brand-name"
                  />

                </Link>
              </VerticalNavBrand>

              {this.props.layoutMode === PFXAppLayoutMode.MOBILE && (
                <div
                  className="nav navbar-left"
                  style={{ float: 'right', marginTop: '-58px' }}
                >
                  <button
                    type="button"
                    className="nav-item-iconic btn btn-link"
                    onClick={this.toggleTopMenu}
                  >
                    <span
                      aria-describedby="tooltip"
                      aria-hidden="true"
                      className="fa fa-ellipsis-h"
                    />
                  </button>
                </div>
              )}
              {this.props.topMenuHidden === true && this.props.systemInfo.HideNavigator !== true && (
                <ContextSelector
                  key="ContextSelector"
                  terms={this.props.terms}
                  contextIsLoading={
                    this.props.contextIsLoading || this.props.isInitializing
                  }
                  webs={this.props.webs}
                  contextWeb={this.props.contextWeb}
                  onChange={e => window.store.dispatch(loadPath('/' + e.slug))}
                  onFocus={this.hideMenusInMobileMode}
                />
              )}
              {(this.props.logMode) && (<BugMonitor errors={(window.ProjectFlowX.errorCounter ?? 0) + (this.props.errors ?? 0)} />)}
              <TopMenu
                contextUser={this.props.contextUser}
                contextWeb={this.props.contextWeb}
                key="TopMenu"
                menus={this.props.menuItems}
                location={this.props.location}
                navigateTo={this.navigateTo}
                layoutMode={this.props.layoutMode}
              >
                {/*<NavItem key="Help" onClick={this.toggleHelpPanel}>*/}
                <MenuItem key="Help" onClick={this.toggleHelpPanel}>
                  <Icon
                    iconName="Help"
                    title={this.props.terms[PFXShared.HELP]}
                    ariaLabel={this.props.terms[PFXShared.HELP]}
                    className="nav-item-iconic btn btn-link"
                    style={{
                      fontSize: '16px',
                      height: 'unset',
                      width: 'unset',
                      backgroundColor: this.props.helpVisible
                        ? 'var(--pfx-theme-blue)'
                        : ''
                    }}
                  />
                </MenuItem>
                {/* </NavItem> */}
                <Notifications
                  contextUser={this.props.contextUser}
                  terms={this.props.terms}
                  onBtnClick={this.toggleNotificationPanel}
                />{' '}{/* vis som fuldside komponent i mobile mode? */}
              </TopMenu>
            </VerticalNavMasthead>
            {this.sideMenu}
          </VerticalNav>
          {true && (
            <div className="container-pf-nav-pf-vertical">
              {/* Main router based content */}
              <div className="flex-body" id="flexBody" onScroll={this.onScroll}>
                <div id="left" className="column">
                  <div className="top-left toolbar-pf">
                    {this.props.currentPath.length > 0 && (
                      <BreadcrumbMenu
                        key="Breadcrumb"
                        contextWeb={this.props.contextWeb}
                        contextUser={this.props.contextUser}
                        contextForm={this.props.contextForm}
                        currentPath={this.props.currentPath}
                        lcid={this.props.lcid}
                        location={this.props.location}
                        navigateTo={this.navigateTo}
                      />
                    )}
                  </div>
                  <div className="bottom">
                    {!this.props.actionPanelItem && this.props.alert && !this.props.alert.dialogOnly && (
                      <AlertBar alert={this.props.alert} />
                    )}

                    {
                      /* ----- Main Content From Router ------- */
                      this.routes
                      /* -------------------------------------- */
                    }

                  </div>
                </div>
                {(this.props.helpVisible || this.props.inlinePanelItem) && (
                  <div
                    id="right"
                    className={
                      this.props.layoutMode === PFXAppLayoutMode.MOBILE
                        ? 'column rightMobile'
                        : 'column'
                    }
                    style={{
                      display: this.props.terms['PFX::System::PFX_Portal']
                        ? 'initial'
                        : 'none'
                    }}
                  >

                    <InlinePanel
                      contextWeb={this.props.contextWeb}
                      contextUser={this.props.contextUser}
                      contextForm={this.props.contextForm}
                      currentPath={this.props.currentPath}
                      lcid={this.props.lcid}
                      terms={this.props.terms}
                      onClose={this.toggleHelpPanel}
                      alert={this.props.alert}
                      dynamicTab={this.props.inlinePanelItem}
                      systemInfo={this.props.systemInfo}
                    />

                  </div>
                )}
              </div>
              {/* end */}
              <CoreFlowCallOut
                contextWeb={this.props.contextWeb}
                contextUser={this.props.contextUser}
                lcid={this.props.lcid}
                callOut={this.props.callOut}
                terms={this.props.terms}
                filter={this.props.filter}
              />
              <SimpleDialog
                dialog={this.props.dialog}
                terms={this.props.terms}
              />
              {this.props.contextWeb &&
                this.props.contextWeb.type === PFXWebType.PROJECT && (
                  <AlertPanel
                    contextWeb={this.props.contextWeb}
                    contextUser={this.props.contextUser}
                    lcid={this.props.lcid}
                    onClick={this.toggleAlertsPanel}
                    isVisible={this.props.alertsVisible}
                    formAlerts={this.props.formAlerts}
                    terms={this.props.terms}
                  />
                )}
              {this.props.actionPanelItem && (
                <ActionPanel
                  contextWeb={this.props.contextWeb}
                  contextUser={this.props.contextUser}
                  lcid={this.props.lcid}
                  panelItem={this.props.actionPanelItem}
                  onClose={window.ProjectFlowX.dismissActionPanel}
                  terms={this.props.terms}
                >
                  {this.props.alert && this.props.alert.dialogOnly && (
                    <AlertBar alert={this.props.alert} />
                  )}
                </ActionPanel>
              )}
            </div>
          )}
        </ThemeProvider>
      );
    }
  }
}

const mapStateToProps = (state: RoutedAppState) => {
  return { ...state.appReducer };
};

export default withRouter(
  connect<any, AppInterface, any>(mapStateToProps)(App)
);
